/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
type PleaseCallMe_viewer$ref = any;
export type PleaseCallMeQueryVariables = {};
export type PleaseCallMeQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": PleaseCallMe_viewer$ref;
    };
};
export type PleaseCallMeQuery = {
    readonly response: PleaseCallMeQueryResponse;
    readonly variables: PleaseCallMeQueryVariables;
};



/*
query PleaseCallMeQuery {
  viewer {
    ...PleaseCallMe_viewer
  }
}

fragment PleaseCallMe_viewer on Viewer {
  pleaseCallMeConfig {
    name
    id
  }
}
*/

const node: ConcreteRequest = {
  "kind": "Request",
  "operationKind": "query",
  "name": "PleaseCallMeQuery",
  "id": null,
  "text": "query PleaseCallMeQuery {\n  viewer {\n    ...PleaseCallMe_viewer\n  }\n}\n\nfragment PleaseCallMe_viewer on Viewer {\n  pleaseCallMeConfig {\n    name\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PleaseCallMeQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "viewer",
        "name": "__viewer_viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "PleaseCallMe_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PleaseCallMeQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pleaseCallMeConfig",
            "storageKey": null,
            "args": null,
            "concreteType": "PleaseCallMeConfig",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "viewer",
        "args": null,
        "handle": "viewer",
        "key": "",
        "filters": null
      }
    ]
  }
};
(node as any).hash = '5459a3dae67a28a7ef453545218fe929';
export default node;
