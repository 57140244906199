/* tslint:disable no-any */
import * as React from 'react';
import { DropdownList } from 'react-widgets';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer, fetchQuery } from 'react-relay';

import EntitySelectorContext from '../contexts/EntitySelectorContext';
import MediaQuery from './components/MediaQuery';
import EntityComboboxModal from './components/EntityComboboxModal';
import RelayContext from '../contexts/RelayContext';
import { EntityData } from './components/EntityCombobox';
import { AccountSelector_viewer } from './__generated__/AccountSelector_viewer.graphql';
import { AccountSelectorSpokeSearchQueryResponse } from './__generated__/AccountSelectorSpokeSearchQuery.graphql';
import * as AdminUtils from './Admin/components/AdminUtils';
import debounce from 'lodash/debounce';

interface Props {
  // Hook to allow reacting to the entity changing
  onSelectedEntityChanged: (entityReference: string) => void;
  // TODO: make not optional
  loading?: boolean;
  isMobile?: boolean;
  viewer: AccountSelector_viewer;
}

function ComboItem(data: any) {
  return <span>{data.item.text}</span>;
}

function getText(entityReference: string, accountName: string | null) {
  if (accountName) {
    return `${entityReference} - ${accountName}`;
  }

  return entityReference;
}

function AccountSelectorInternal({
  loading = false,
  onSelectedEntityChanged,
  viewer,
}: Props) {
  const { userEntities, selectedEntityReference } = React.useContext(
    EntitySelectorContext
  );
  const env = React.useContext(RelayContext);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [searchLoading, setSearchLoading] = React.useState(false);

  const [value, setValue] = React.useState<{ name: string; id: string } | null>(
    // @ts-ignore
    selectedEntityReference
      ? `${selectedEntityReference.name} - ${
          selectedEntityReference.accountName
        }`
      : null
  );

  const [entities, setEntities] = React.useState<EntityData[]>(() => {
    // Default to user entities
    return userEntities.edges.map(({ node }: any) => {
      return {
        ...node,
        text: getText(node.name, node.accountName),
      };
    });
  });

  const defaultValue = React.useMemo(() => {
    if (entities && entities.length > 0) {
      if (selectedEntityReference) {
        const entity = entities.find(
          e => e.name === selectedEntityReference.name
        );
        if (entity) {
          return entity;
        }
      }

      return entities[0];
    }

    return null;
  }, [entities, selectedEntityReference]);

  const handleSearch = React.useCallback(
    debounce(async (v: string) => {
      setSearchLoading(true);

      try {
        const {
          viewer: { spokeSearch },
        }: AccountSelectorSpokeSearchQueryResponse = await fetchQuery(
          env,
          spokeSearchQuery,
          { search: v }
        );
        const searchEntities: EntityData[] | null =
          spokeSearch &&
          spokeSearch.edges &&
          spokeSearch.edges.map(
            // @ts-ignore
            ({ node }) => ({
              ...node,
              text: getText(node.account, node.name),
            })
          );

        setEntities(searchEntities || []);
      } finally {
        setSearchLoading(false);
      }
    }, 300),
    [setEntities, setSearchLoading, env]
  );

  const enableSearch = AdminUtils.isAdmin(viewer.user);

  // Load data on initial laod
  React.useEffect(() => {
    if (enableSearch && selectedEntityReference) {
      handleSearch(selectedEntityReference.name);
    }
  }, [enableSearch, selectedEntityReference, handleSearch]);

  function openCloseModal(predicate: boolean) {
    setIsModalOpen(predicate);
  }

  function handleOnChange(textInput: any): void {
    if (entities.find(e => e.name === textInput)) {
      if (typeof (textInput === 'string')) {
        // TODO: check this
        setValue({ name: textInput, id: textInput });
      } else {
        setValue(textInput);
      }
    } else {
      setValue(textInput);

      if (textInput) {
        sessionStorage.setItem('hub', textInput.name);
        sessionStorage.removeItem('spoke');
        onSelectedEntityChanged(
          enableSearch ? textInput.account : textInput.name
        );
      }
    }
  }

  return (
    <div className="EntityController">
      {(enableSearch || userEntities.total > 1) && (
        <div>
          <EntityComboboxModal
            open={isModalOpen}
            loading={loading || searchLoading}
            closeModal={() => openCloseModal(!isModalOpen)}
            entityData={entities}
            handleSearch={enableSearch ? handleSearch : undefined}
            /*
                   Account can either be an account number or
                   account number, hypen, and account name for
                   spoke and hub, respectively.
                */
            handleChange={(account: any) => {
              return onSelectedEntityChanged(account.split('-')[0].trim());
            }}
            isAccountSelector={true}
          />
          <MediaQuery>
            {({ isMobile }: { isMobile: boolean }) => (
              <div
                onClick={() =>
                  !isMobile ? null : openCloseModal(!isModalOpen)
                }
                className="combo-container"
              >
                <DropdownList
                  data={entities}
                  itemComponent={ComboItem}
                  defaultValue={defaultValue}
                  busy={loading}
                  readOnly={loading || isMobile}
                  value={value}
                  valueField="id"
                  textField="text"
                  onChange={handleOnChange}
                  onSearch={enableSearch ? handleSearch : undefined}
                  caseSensitive={false}
                  minLength={3}
                  filter="contains"
                />
              </div>
            )}
          </MediaQuery>
        </div>
      )}
    </div>
  );
}

const spokeSearchQuery = graphql`
  query AccountSelectorSpokeSearchQuery($search: String!) {
    viewer {
      spokeSearch(search: $search) {
        edges {
          node {
            name
            account
            hub {
              name
              account
            }
          }
        }
      }
    }
  }
`;

export default createFragmentContainer(AccountSelectorInternal, {
  viewer: graphql`
    fragment AccountSelector_viewer on Viewer {
      user {
        id
        username
        roles {
          name
        }
      }
    }
  `,
});
