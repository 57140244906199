/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
type EntityController_viewer$ref = any;
export type EntityControllerQueryVariables = {};
export type EntityControllerQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": EntityController_viewer$ref;
    };
};
export type EntityControllerQuery = {
    readonly response: EntityControllerQueryResponse;
    readonly variables: EntityControllerQueryVariables;
};



/*
query EntityControllerQuery {
  viewer {
    ...EntityController_viewer
  }
}

fragment EntityController_viewer on Viewer {
  entities {
    total
    edges {
      node {
        id
        name
        accountName
        accountType
      }
    }
    pageInfo {
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = {
  "kind": "Request",
  "operationKind": "query",
  "name": "EntityControllerQuery",
  "id": null,
  "text": "query EntityControllerQuery {\n  viewer {\n    ...EntityController_viewer\n  }\n}\n\nfragment EntityController_viewer on Viewer {\n  entities {\n    total\n    edges {\n      node {\n        id\n        name\n        accountName\n        accountType\n      }\n    }\n    pageInfo {\n      hasNextPage\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EntityControllerQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "viewer",
        "name": "__viewer_viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "EntityController_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EntityControllerQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "entities",
            "storageKey": null,
            "args": null,
            "concreteType": "EntityConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "total",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "EntityEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Entity",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "id",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "name",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "accountName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "accountType",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "viewer",
        "args": null,
        "handle": "viewer",
        "key": "",
        "filters": null
      }
    ]
  }
};
(node as any).hash = 'be71f2b6afd495bfe11896bba2501027';
export default node;
