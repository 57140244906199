/* tslint:disable no-any */
import * as React from 'react';

import { createFragmentContainer, RelayProp } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { RouteComponentProps } from 'react-router-dom';
import { EntityController_viewer } from './__generated__/EntityController_viewer.graphql';

import NoEntitiesFound from '../components/NoEntitiesFound';
import './EntityController.css';

import ErrorDisplay from '../../components/errorDisplay';

import RelayRenderer from '../RelayRenderer';

import EntitySelectorContext from '../../contexts/EntitySelectorContext';
import DefaultEntityContext from '../../contexts/DefaultEntityContext';

export interface ChildProps {
  selectedEntityReference: string;
}

interface Entity {
  id: string;
  name: string;
}

interface Props extends RouteComponentProps {
  children: React.ReactNode;
  viewer: EntityController_viewer;
  relay: RelayProp;
}

interface State {
  error: Error | null;
  selectedEntityReference: Entity | null;
  defaultEntityReference: Entity | null;
  value: { name: string; id: string } | null;
}
class EntityController extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let selectedEntityReference: Entity | null = null;
    let defEntity;
    if (props.viewer && props.viewer.entities) {
      selectedEntityReference =
        props.viewer.entities.total! > 0
          ? props.viewer.entities.edges![0]!.node
          : null;

      if (sessionStorage.getItem('hub')) {
        // @ts-ignore
        const match = props.viewer.entities.edges.find(
          // @ts-ignore
          i => i.node.name === sessionStorage.getItem('hub')
        );
        if (match) {
          defEntity = match.node;
        }
      } else {
        defEntity = selectedEntityReference;
      }
    }

    this.state = {
      error: null,
      selectedEntityReference,
      defaultEntityReference: defEntity,
      value: null,
    };
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (this.props.viewer === null && nextProps.viewer !== null) {
      let selectedEntityReference: Entity | null = null;

      if (nextProps.viewer && nextProps.viewer.entities) {
        selectedEntityReference =
          nextProps.viewer.entities.total! > 0
            ? nextProps.viewer.entities.edges![0]!.node
            : null;
      }

      this.setState({
        selectedEntityReference,
      });
    }
  }

  // tslint:disable-next-line no-any
  public entityToggler = (selectedEntityReference: any) => {
    this.setState({ selectedEntityReference });
  };

  public render() {
    const { selectedEntityReference, defaultEntityReference } = this.state;

    const { viewer } = this.props;
    if (this.state.error != null) {
      return <ErrorDisplay errorText={this.state.error} retry={() => null} />;
    }

    switch (viewer!.entities.total) {
      case 0:
        return (
          <NoEntitiesFound
            entityReference={
              selectedEntityReference && selectedEntityReference.name
            }
          />
        );
      default: {
        let entityReference = '';

        if (defaultEntityReference) {
          entityReference = defaultEntityReference.name;
        } else if (viewer.entities && viewer.entities.edges) {
          const edge = viewer.entities.edges[0];

          if (edge && edge.node) {
            entityReference = edge.node.name;
          }
        }

        return (
          <EntitySelectorContext.Provider
            value={{
              entityToggler: this.entityToggler,
              selectedEntityReference,
              userEntities: viewer.entities ? viewer.entities : null,
            }}
          >
            <DefaultEntityContext.Provider
              value={{
                entityReference,
              }}
            >
              {this.props.children}
            </DefaultEntityContext.Provider>
          </EntitySelectorContext.Provider>
        );
      }
    }
  }
}

const EntityControllerFragment = createFragmentContainer(EntityController, {
  viewer: graphql`
    fragment EntityController_viewer on Viewer {
      entities {
        total
        edges {
          node {
            id
            name
            accountName
            accountType
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  `,
});

const query = graphql`
  query EntityControllerQuery {
    viewer {
      ...EntityController_viewer
    }
  }
`;

// @ts-ignore
export default props => (
  <RelayRenderer
    {...props}
    query={query}
    container={EntityControllerFragment}
  />
);
