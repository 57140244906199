/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type DashboardSummary_accountSummary$ref = any;
export type DashboardSummary_accountSummary = {
    readonly name: string;
    readonly account: string;
    readonly availableBalance: number;
    readonly lastPaid: ({
        readonly amount: number;
        readonly date: any;
    }) | null;
    readonly " $refType": DashboardSummary_accountSummary$ref;
};



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "DashboardSummary_accountSummary",
  "type": "DashboardAccountSummary",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "account",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "availableBalance",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "lastPaid",
      "storageKey": null,
      "args": null,
      "concreteType": "DashboardLastPaid",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "amount",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "date",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = 'c243fad8dfde4121f722198ce5c09863';
export default node;
