/* tslint:disable no-any */
import * as React from 'react';

import SideBarStyle from './sideBarMenu.module.css';
import { sidebarMenu_viewer } from './__generated__/sidebarMenu_viewer.graphql';

interface Props {
  notificationCount: number;
  viewer: sidebarMenu_viewer | null;
}

export default class Badge extends React.Component<Props> {
  public render() {
    const { notificationCount, viewer } = this.props;
    let isReadonlyAdmin = false;
    if (viewer && viewer.user) {
      const { user } = viewer;
      isReadonlyAdmin = !!user!.roles.find(
        role => role.name === 'admin' || role.name === 'regional_admin'
      );
    }
    return (
      <div
        className={`${SideBarStyle.badge} ${isReadonlyAdmin &&
          SideBarStyle.notificationBadgeCounter}`}
      >
        <p className={SideBarStyle.number}>{notificationCount}</p>
      </div>
    );
  }
}
