/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type PleaseCallMeMutationInput = {
    readonly email: string;
    readonly accountNumber: string;
    readonly name: string;
    readonly companyName: string;
    readonly subjectLine: string;
    readonly contactNumber: string;
    readonly depositIssueFile?: any | null;
    readonly clientMutationId?: string | null;
};
export type PleaseCallMeMutationVariables = {
    readonly input: PleaseCallMeMutationInput;
};
export type PleaseCallMeMutationResponse = {
    readonly pleaseCallMe: ({
        readonly clientMutationId: string | null;
        readonly accepted: ReadonlyArray<string> | null;
        readonly rejected: ReadonlyArray<string> | null;
    }) | null;
};
export type PleaseCallMeMutation = {
    readonly response: PleaseCallMeMutationResponse;
    readonly variables: PleaseCallMeMutationVariables;
};



/*
mutation PleaseCallMeMutation(
  $input: PleaseCallMeMutationInput!
) {
  pleaseCallMe(input: $input) {
    clientMutationId
    accepted
    rejected
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "PleaseCallMeMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "pleaseCallMe",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "PleaseCallMeMutationInput!"
      }
    ],
    "concreteType": "PleaseCallMeMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "accepted",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rejected",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "PleaseCallMeMutation",
  "id": null,
  "text": "mutation PleaseCallMeMutation(\n  $input: PleaseCallMeMutationInput!\n) {\n  pleaseCallMe(input: $input) {\n    clientMutationId\n    accepted\n    rejected\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PleaseCallMeMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "PleaseCallMeMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = 'c03ef4caecaf79782909ed01197e8516';
export default node;
