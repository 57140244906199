/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
type EntityCombobox_viewer$ref = any;
export type SalesCategory = "AIRTIME" | "DATA" | "DSTV" | "ELECTRICITY" | "HOLLYWOOD_BETS" | "LOTTO" | "M2M" | "OTHER" | "RICA_FAILED" | "RICA_SUCCESS" | "RICA_TOTAL" | "TOTAL_SALES" | "UNIPIN" | "%future added value";
export type DashboardViewDataModal_viewer$ref = any;
export type DashboardViewDataModal_viewer = {
    readonly dashboard: ({
        readonly hub: ({
            readonly account: string;
            readonly name: string;
            readonly lastPaid: ({
                readonly amount: number;
                readonly date: any;
            }) | null;
            readonly availableBalance: number;
            readonly categories: ReadonlyArray<{
                readonly category: SalesCategory;
                readonly lastMonth: number;
                readonly currentMonthToDate: number;
                readonly lastMonthMonthToDate: number;
                readonly email: string | null;
                readonly cellphoneNumber: string | null;
            }>;
        }) | null;
        readonly spokes: ReadonlyArray<{
            readonly account: string;
            readonly name: string;
            readonly lastPaid: ({
                readonly amount: number;
                readonly date: any;
            }) | null;
            readonly availableBalance: number;
            readonly categories: ReadonlyArray<{
                readonly category: SalesCategory;
                readonly yesterdaysSalesToDate: number;
                readonly currentMonthToDate: number;
                readonly lastMonth: number;
                readonly lastMonthMonthToDate: number;
                readonly email: string | null;
                readonly cellphoneNumber: string | null;
            }>;
        }>;
    }) | null;
    readonly " $fragmentRefs": EntityCombobox_viewer$ref;
    readonly " $refType": DashboardViewDataModal_viewer$ref;
};



const node: ConcreteFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "account",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "lastPaid",
  "storageKey": null,
  "args": null,
  "concreteType": "DashboardLastPaid",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "amount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "date",
      "args": null,
      "storageKey": null
    }
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "availableBalance",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "category",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastMonth",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currentMonthToDate",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastMonthMonthToDate",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "email",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cellphoneNumber",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "DashboardViewDataModal_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "entityReference",
      "type": "String"
    }
  ],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "EntityCombobox_viewer",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "dashboard",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "entityReference",
          "variableName": "entityReference",
          "type": "String"
        }
      ],
      "concreteType": "Dashboard",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "hub",
          "storageKey": null,
          "args": null,
          "concreteType": "DashboardHubAccount",
          "plural": false,
          "selections": [
            v0,
            v1,
            v2,
            v3,
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "categories",
              "storageKey": null,
              "args": null,
              "concreteType": "DashboardCategorySales",
              "plural": true,
              "selections": [
                v4,
                v5,
                v6,
                v7,
                v8,
                v9
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "spokes",
          "storageKey": null,
          "args": null,
          "concreteType": "DashboardSpokeAccount",
          "plural": true,
          "selections": [
            v0,
            v1,
            v2,
            v3,
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "categories",
              "storageKey": null,
              "args": null,
              "concreteType": "DashboardCategorySales",
              "plural": true,
              "selections": [
                v4,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "yesterdaysSalesToDate",
                  "args": null,
                  "storageKey": null
                },
                v6,
                v5,
                v7,
                v8,
                v9
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = '792d7a95b6a00ebdbbedaa416707829d';
export default node;
