import React, { useState } from 'react';
import styles from './DashboardSpokePerformance.module.css';
import chevronUp from '../../../assets/images/chevron-up.svg';
import chevronDown from '../../../assets/images/chevron-down.svg';

interface Props {
  data?: ReadonlyArray<{
    Spoke: string;
    TotalSales: number;
  } | null> | null;
  handleItemClick: (reference: string) => void;
}

export const DashboardSpokePerformance = ({ data, handleItemClick }: Props) => {
  if (!data) {
    return null;
  }
  const [open, setOpen] = useState(false);
  const clonedData = JSON.parse(JSON.stringify(data));
  const topTen = clonedData
    .slice()
    .sort((a, b) => (a.TotalSales > b.TotalSales ? -1 : 1))
    .slice(0, 10);
  const lowTen = clonedData
    .slice()
    .sort((a, b) => (a.TotalSales > b.TotalSales ? 1 : -1))
    .slice(0, 10);

  return (
    <div
      className={open ? styles.containerOpen : styles.containerClosed}
      role="button"
    >
      <div onClick={() => setOpen(!open)} className={styles.header}>
        Spoke Performance
        <img className={styles.chevron} src={open ? chevronUp : chevronDown} />
      </div>
      {open && (
        <div className={styles.dataWrapper}>
          <div className={styles.spokeBlock}>
            <p className={styles.spokeText}>10 Best Performing Spokes</p>
            <p>10 Spokes with the highest total sales in the last 30 days</p>
            <div className={styles.rankBlock}>
              {topTen.map(spoke => (
                <div
                  className={styles.rankItem}
                  key={spoke.Spoke}
                  onClick={() => {
                    sessionStorage.setItem('spoke', spoke.SpokeAcc);
                    handleItemClick(spoke.SpokeAcc);
                    setOpen(false);
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <p className={styles.rankText}>
                    <span className={styles.boldText}>
                      R {spoke.TotalSales}
                    </span>
                    {`${spoke.SpokeAcc} - ${spoke.Spoke}`}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.worstBlock}>
            <p className={styles.spokeText}>10 Worst Performing Spokes</p>
            <p>10 Spokes with the lowest total sales in the last 30 days</p>
            <div className={styles.rankBlock}>
              {lowTen.map(spoke => (
                <div
                  className={styles.rankItem}
                  key={spoke.Spoke}
                  onClick={() => {
                    sessionStorage.setItem('spoke', spoke.SpokeAcc);
                    handleItemClick(spoke.SpokeAcc);
                    setOpen(false);
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <p className={styles.rankText}>
                    <span className={styles.boldText}>
                      R {spoke.TotalSales}
                    </span>
                    {`${spoke.SpokeAcc} - ${spoke.Spoke}`}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
