import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import Yup from 'yup';
import ResetUserPasswordUpdatePasswordMutation from '../mutations/ResetUserPasswordUpdatePasswordMutation';
import TextField from '../../../components/textField';
import RelayContext from '../../../contexts/RelayContext';
import styles from './ResetUserPassword.module.css';
import bluLogo from '../../../assets/images/bluLogo.svg';
import bluKey from '../../../assets/images/blukey.svg';
import Button from '../../components/Button';
import { Environment } from 'relay-runtime';
import PasswordStrengthMeter from '../../components/PasswordStrengthMeter';

interface State {
  isLoading: boolean;
}

interface Values {
  username: string;
  authToken: string;
  token: string;
  password: string;
}

interface Props {
  environment: Environment;
  nextStep: () => void;
  handleSubmit: (values: Values) => void;
  values: {
    token: string;
    username: string;
  };
}
const Schema = Yup.object().shape({
  password: Yup.string()
    .required('New Password is a required field')
    .min(8, 'New Password is too short - should be 8 characters minimum.'),
  repeatPassword: Yup.string()
    .required('Repeat Password is a required field')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

class ResetUserPasswordNewPassword extends React.Component<Props, State> {
  public static contextType = RelayContext;

  public render() {
    return (
      <div className={styles.loginBody}>
        <img src={bluLogo} alt="" className="body-corner-logo-top" />
        <div className={styles.registerContainer}>
          <div className={styles.image}>
            <img src={bluKey} alt="" className={styles.logo} />
          </div>
          <h3 className={styles.title}>Change Password</h3>

          <p className={styles.text}>
            Please choose a new password below that is at least 8 characters
            <br />
          </p>
          <Formik<Values>
            initialValues={{
              username: this.props.values.username,
              authToken: '',
              token: this.props.values.token,
              password: '',
              repeatPassword: '',
            }}
            validationSchema={Schema}
            onSubmit={async (
              values,
              { setStatus, setSubmitting, setFieldError }
            ) => {
              try {
                setStatus('');
                await ResetUserPasswordUpdatePasswordMutation(
                  this.props.environment,
                  {
                    input: {
                      username: values.username,
                      password: values.password,
                      token: this.props.values.token,
                    },
                  }
                ).then(data => {
                  // @ts-ignore
                  values.authToken =
                    // @ts-ignore
                    data.userResetPasswordUpdatePassword.authToken;
                });
                this.props.nextStep();
                this.props.handleSubmit(values);
              } catch (e) {
                setSubmitting(false);
                setStatus(e.message || 'OTP failed');
              }
            }}
            render={({ handleSubmit, isSubmitting, values }) => {
              return (
                <Form
                  autoComplete="nope"
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      handleSubmit(e);
                    }
                  }}
                >
                  <PasswordStrengthMeter password={values.password} />
                  <div className="input-container">
                    <Field
                      name="password"
                      label="New Password *"
                      type="password"
                      placeholder="Must have at least 8 characters"
                      component={TextField}
                      disabled={isSubmitting}
                    />
                  </div>

                  <div className="input-container">
                    <Field
                      name="repeatPassword"
                      label="Repeat Password *"
                      type="password"
                      component={TextField}
                      disabled={isSubmitting}
                    />
                  </div>
                  <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    text="RESET PASSWORD"
                  />
                </Form>
              );
            }}
          />
          <p className={styles.helpMessage}>
            Need help? Call centre: 0800 014 942
          </p>
        </div>
      </div>
    );
  }
}

export default ResetUserPasswordNewPassword;
