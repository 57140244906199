import React from 'react';
import ReactMoment from 'react-moment';
import moment, { Moment } from 'moment';
import styles from './Timer.module.css';

interface Props {
  expiry: Moment;
  handleExpired(): void;
}

interface State {
  now: Moment;
  expired: boolean;
}

export default class Timer extends React.Component<Props, State> {
  public state: State = {
    now: moment(),
    expired: false,
  };

  private intervalId?: number;

  public componentDidMount() {
    this.intervalId = (setInterval(() => {
      const { expiry } = this.props;

      const now = moment();
      const expired = now.isSameOrAfter(expiry, 'second');

      if (expired) {
        this.props.handleExpired();
      }

      this.setState({ now, expired });
    }, 500) as unknown) as number;
  }

  public componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  public render() {
    const { expiry } = this.props;
    const { now, expired } = this.state;

    return (
      <div className={styles.timer}>
        {expired ? (
          <span className={styles.message}>
            <p>OTP has expired</p>
          </span>
        ) : (
          <>
            <ReactMoment unit="seconds" duration={now}>
              {expiry.toISOString()}
            </ReactMoment>
            <span className={styles.message}>
              <p>Until OTP expires</p>
            </span>
          </>
        )}
      </div>
    );
  }
}
