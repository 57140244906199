/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
type DashboardProfitView_commissionSummary$ref = any;
export type DashboardSummary_commissionSummary$ref = any;
export type DashboardSummary_commissionSummary = ReadonlyArray<{
    readonly " $fragmentRefs": DashboardProfitView_commissionSummary$ref;
    readonly " $refType": DashboardSummary_commissionSummary$ref;
}>;



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "DashboardSummary_commissionSummary",
  "type": "CommissionSummary",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "DashboardProfitView_commissionSummary",
      "args": null
    }
  ]
};
(node as any).hash = '4acd81a76595f06fdc3e2c9763034dce';
export default node;
