import * as React from 'react';

import ResetUserPasswordOtp from './components/ResetUserPasswordOtp';
import ResetUserPasswordStart from './components/ResetUserPasswordStart';
import ResetUserPasswordNewPassword from './components/ResetUserPasswordNewPassword';
import ResetPasswordDetails from './components/ResetUserPasswordDetails';

import RelayContext from '../../contexts/RelayContext';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps {
  handleLogin(opts: { username: string; token: string }): void;
}

interface State extends UserDetails, InitialInfo, UpdatePassword {
  step: number;
}

interface InitialInfo {
  username: string;
  cellNumber: string;
}
interface UserDetails {
  token: string;
}
interface UpdatePassword {
  authToken: string;
}
class ResetUserPassword extends React.Component<Props, State> {
  public static contextType = RelayContext;
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      username: '',
      cellNumber: '',
      token: '',
      authToken: '',
    };
  }

  public nextStep = () => {
    this.setState(prevState => ({
      step: prevState.step + 1,
    }));
  };

  public prevStep = () => {
    this.setState(prevState => ({
      step: prevState.step - 1,
    }));
  };

  public handleSubmit = async () => {
    await this.props.handleLogin({
      username: this.state.username,
      token: this.state.authToken,
    });
    this.props.history.push('/dashboard');
  };

  public render() {
    const { username, cellNumber, token, authToken } = this.state;
    const formValues = {
      username,
      cellNumber,
      token,
      authToken,
    };
    switch (this.state.step) {
      case 1:
        return (
          <ResetUserPasswordStart
            environment={this.context}
            nextStep={this.nextStep}
            handleSubmit={(values: InitialInfo) => {
              this.setState({
                cellNumber: values.cellNumber,
                username: values.username,
              });
            }}
            values={formValues}
          />
        );
      case 2:
        return (
          <ResetUserPasswordOtp
            environment={this.context}
            nextStep={this.nextStep}
            handleSubmit={(values: UserDetails) => {
              this.setState({
                token: values.token,
              });
            }}
            values={formValues}
          />
        );
      case 3:
        return (
          <ResetUserPasswordNewPassword
            environment={this.context}
            nextStep={this.nextStep}
            handleSubmit={(values: UpdatePassword) => {
              this.setState({
                authToken: values.authToken,
              });
            }}
            values={formValues}
          />
        );
      case 4:
        return (
          <ResetPasswordDetails
            username={this.state.username}
            handleSubmit={this.handleSubmit}
          />
        );
        break;
    }
  }
}

export default ResetUserPassword;
