import * as React from 'react';
import Spinner from 'react-spinkit';

import styles from './LoadingMask.module.css';

interface Props {
  classNames?: string[];
}

export default (props: Props) => (
  <div
    className={`${styles.container} ${props.classNames && props.classNames[0]}`}
  >
    <div
      className={`${styles.content} ${props.classNames && props.classNames[1]}`}
    >
      <Spinner
        name="circle"
        color="darkblue"
        fadeIn="quarter"
        style={{ width: 40, height: 40 }}
      />
    </div>
  </div>
);
