/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type ViewProducts_viewer$ref = any;
export type ViewProducts_viewer = {
    readonly user: ({
        readonly roles: ReadonlyArray<{
            readonly name: string;
        }>;
    }) | null;
    readonly products: ReadonlyArray<{
        readonly id: string;
        readonly title: string;
        readonly description: string;
        readonly dateAvailable: any;
        readonly imageUri: string | null;
        readonly image: string | null;
        readonly externalLink: string | null;
        readonly buttonText: string | null;
    }>;
    readonly " $refType": ViewProducts_viewer$ref;
};



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "ViewProducts_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "roles",
          "storageKey": null,
          "args": null,
          "concreteType": "Role",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "products",
      "storageKey": null,
      "args": null,
      "concreteType": "Product",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "title",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "description",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "dateAvailable",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "imageUri",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "image",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "externalLink",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "buttonText",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = '6309bf961a97ce749693439a281b5539';
export default node;
