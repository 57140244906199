import React from 'react';
import styles from './TourNav.module.css';
import Button from '../../../../components/Button';

interface Props {
  onNext: () => void;
  onPrev: () => void;
  selectedItem: number;
  tourLastStep: number;
}

class TourNav extends React.Component<Props> {
  public render() {
    const { onPrev, onNext, selectedItem, tourLastStep } = this.props;
    return (
      <div className={styles.tourNavContainerBoundary}>
        <div className={styles.tourNavContainer}>
          {selectedItem !== 0 && (
            <Button className={styles.previous} onClick={onPrev} text="Back" />
          )}
          <Button
            className={styles.next}
            onClick={onNext}
            text={selectedItem === tourLastStep ? 'Finish' : 'Next'}
          />
        </div>
      </div>
    );
  }
}

export { TourNav };
