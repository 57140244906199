import React, { Component, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { QueryRenderer } from 'react-relay';
import { Environment } from 'relay-runtime';
import graphql from 'babel-plugin-relay/macro';

import RelayContext from './contexts/RelayContext';
// import composeMessage from './modules/notifications/components/composeMessage';
import Dashboard from './modules/Dashboard/Dashboard';
import { RoutesQueryResponse } from './__generated__/RoutesQuery.graphql';
import Error from './modules/components/ErrorView';

// TODO: have to keep this here to main import order :(...
import './App.css';
import styles from './Routes.module.css';

import PrivateRoute from './PrivateRoute';
import SidebarMenu from './components/sidebarMenu';

// Only these modules are eagerly loaded
import LoadingMask from './modules/components/LoadingMask';
import UserLogin from './modules/login';
import SelfRegistration from './modules/SelfRegistration/SelfRegistration';
import ResetUserPassword from './modules/ResetPassword/ResetUserPassword';
import NotificationBar from './modules/NotificationBar/NotificationBar';

const Reports = React.lazy(() =>
  import('./modules/ReportTotalSales/ReportTotalSales')
);
const ReportTotalSalesAllSpokes = React.lazy(() =>
  import('./modules/ReportTotalSalesAllSpokes/ReportTotalSalesAllSpokes')
);
const ReportTotalSalesProducts = React.lazy(() =>
  import('./modules/ReportTotalSalesProducts/ReportTotalSalesProducts')
);
const ReportsContact = React.lazy(() =>
  import('./modules/ReportContact/ReportContact')
);
const ReportsProfit = React.lazy(() =>
  import('./modules/ReportProfit/ReportProfit')
);
const ReportsNegatives = React.lazy(() =>
  import('./modules/ReportNegatives/ReportNegatives')
);

const AdminView = React.lazy(() => import('./modules/Admin/Admin'));
const AdminUserLoginCountView = React.lazy(() =>
  import('./modules/Admin/components/AdminUserLoginTable')
);
const AdminUserRegistrationView = React.lazy(() =>
  import('./modules/Admin/components/AdminUserRegistrationTable')
);

const AdminCreateNewUser = React.lazy(() =>
  import('./modules/Admin/AdminCreateNewUser')
);
const AdminDevTools = React.lazy(() =>
  import('./pages/AdminDevTools/AdminDevTools')
);
const FAQueries = React.lazy(() => import('./modules/faq'));
const ErrorPage = React.lazy(() => import('./modules/error'));
const Profile = React.lazy(() => import('./modules/profile'));
const ComposeBroadcastMessage = React.lazy(() =>
  import('./modules/Broadcasts/ComposeBroadcastMessage')
);
const BroadcastHistory = React.lazy(() =>
  import('./modules/Broadcasts/BroadcastHistory')
);
const Broadcasts = React.lazy(() => import('./modules/Broadcasts/Broadcasts'));
const BroadcastHistoryDetail = React.lazy(() =>
  import('./modules/Broadcasts/BroadcastHistoryDetail')
);
const CreateProduct = React.lazy(() =>
  import('./modules/Products/CreateProduct/CreateProduct')
);
const ViewProducts = React.lazy(() =>
  import('./modules/Products/ViewProducts/ViewProducts')
);
const PleaseCallMeConfig = React.lazy(() =>
  import('./modules/PleaseCallMe/PleaseCallMeConfig')
);
const PleaseCallMeLogs = React.lazy(() =>
  import('./modules/PleaseCallMe/PleaseCallMeLogs')
);

interface Props extends RoutesQueryResponse {
  handleLogout: () => void;
  handleLogin: (opts: { username: string; token: string }) => Promise<void>;
  isLoggedIn: boolean;
  environment: Environment;
}

class Routes extends Component<Props> {
  public render() {
    const {
      handleLogin,
      handleLogout,
      viewer,
      isLoggedIn,
      environment,
    } = this.props;
    return (
      <div>
        {/* Render the sidebar everywhere except login and register */}
        <Switch>
          <Route exact path="/login" />
          <Route exact path="/register" />
          <Route exact path="/reset" />
          {isLoggedIn && (
            <Route
              component={props => (
                <SidebarMenu
                  {...props}
                  handleLogout={() => {
                    sessionStorage.removeItem('hub');
                    sessionStorage.removeItem('spoke');
                    handleLogout();
                  }}
                  viewer={viewer}
                  environment={environment}
                />
              )}
            />
          )}
        </Switch>
        <div>
          <Suspense fallback={<LoadingMask />}>
            <Route
              component={props => (
                <NotificationBar {...props} viewer={this.props.viewer} />
              )}
            />
            <Switch>
              <Route
                exact={true}
                path="/login"
                component={props => (
                  <UserLogin {...props} handleLogin={handleLogin} />
                )}
              />
              <Route
                exact={true}
                path="/register"
                component={props => (
                  <SelfRegistration {...props} handleLogin={handleLogin} />
                )}
              />
              <Route
                exact={true}
                path="/reset"
                component={props => (
                  <ResetUserPassword {...props} handleLogin={handleLogin} />
                )}
              />
              <Redirect exact={true} path="/" to="/dashboard" />
              <Redirect exact={true} path="/faq" to="/help" />
              <PrivateRoute
                exact={true}
                path="/dashboard"
                viewer={viewer}
                component={Dashboard}
              />
              <Redirect path="/reports" exact={true} to="/reports/contacts" />
              <PrivateRoute
                exact={true}
                path="/reports/total-sales"
                component={Reports}
                viewer={viewer}
              />
              <PrivateRoute
                exact={true}
                path="/reports/total-sales-spokes"
                component={ReportTotalSalesAllSpokes}
                viewer={viewer}
              />
              <PrivateRoute
                exact={true}
                path="/reports/contacts"
                viewer={viewer}
                component={ReportsContact}
              />
              <PrivateRoute
                exact={true}
                viewer={viewer}
                path="/reports/profits"
                component={ReportsProfit}
              />
              <PrivateRoute
                exact={true}
                viewer={viewer}
                path="/reports/negatives"
                component={ReportsNegatives}
              />
              <PrivateRoute
                exact={true}
                viewer={viewer}
                path="/reports/:product/performance"
                component={ReportTotalSalesProducts}
              />
              <Redirect path="/admin" exact={true} to="/admin/users" />
              <PrivateRoute
                exact={true}
                viewer={viewer}
                roles={['admin', 'regional_admin']}
                path="/admin/users"
                component={AdminView}
                className={[styles.errorContainer, styles.error]}
              />
              <PrivateRoute
                exact={true}
                viewer={viewer}
                roles={['admin', 'regional_admin']}
                path="/admin/user-logins"
                component={AdminUserLoginCountView}
                className={[styles.errorContainer, styles.error]}
              />
              <PrivateRoute
                exact={true}
                viewer={viewer}
                roles={['admin', 'regional_admin']}
                path="/admin/registrations"
                component={AdminUserRegistrationView}
                className={[styles.errorContainer, styles.error]}
              />
              <PrivateRoute
                exact={true}
                viewer={viewer}
                roles={['admin', 'regional_admin']}
                path="/admin/create_user"
                component={AdminCreateNewUser}
                className={[styles.errorContainer, styles.error]}
              />
              <PrivateRoute
                exact={true}
                viewer={viewer}
                roles={['admin', 'regional_admin']}
                path="/admin/dev-tools"
                component={AdminDevTools}
                className={[styles.errorContainer, styles.error]}
              />
              <PrivateRoute
                exact={true}
                viewer={viewer}
                roles={['admin', 'regional_admin']}
                path="/notifications/compose-new"
                component={ComposeBroadcastMessage}
                className={[styles.errorContainer, styles.error]}
              />
              <PrivateRoute
                exact={true}
                roles={['admin', 'regional_admin']}
                path="/notifications/history"
                component={BroadcastHistory}
                viewer={viewer}
                className={[styles.errorContainer, styles.error]}
              />
              <PrivateRoute
                exact={true}
                roles={['user']}
                path="/notifications"
                component={Broadcasts}
                viewer={viewer}
              />
              <PrivateRoute
                exact={true}
                roles={['admin', 'regional_admin']}
                path="/broadcast/:nodeId"
                component={BroadcastHistoryDetail}
                viewer={viewer}
                className={[styles.errorContainer, styles.error]}
              />
              <PrivateRoute
                exact={true}
                roles={['admin', 'regional_admin']}
                path="/products/create"
                component={CreateProduct}
                viewer={viewer}
                className={[styles.errorContainer, styles.error]}
              />
              <PrivateRoute
                exact={true}
                path="/products/view"
                component={ViewProducts}
                viewer={viewer}
                className={[styles.errorContainer, styles.error]}
              />
              <PrivateRoute
                exact={true}
                path="/please-call-configure"
                component={PleaseCallMeConfig}
                viewer={viewer}
              />
              <PrivateRoute
                exact={true}
                path="/please-call-logs"
                component={PleaseCallMeLogs}
                viewer={viewer}
              />
              <PrivateRoute
                exact={true}
                path="/help"
                component={FAQueries}
                viewer={viewer}
              />
              <PrivateRoute
                exact={true}
                path="/profile"
                component={Profile}
                viewer={viewer}
              />
              <Route component={props => <ErrorPage {...props} />} />
            </Switch>
          </Suspense>
        </div>
      </div>
    );
  }
}

const query = graphql`
  query RoutesQuery {
    viewer {
      ...sidebarMenu_viewer
      ...PrivateRoute_viewer
      ...NotificationBar_viewer
      user {
        roles {
          name
        }
      }
    }
  }
`;

export default moduleProps => {
  // Use a custom QueryRenderer so we can render the
  // sidebar even when the query isn't complete and
  // we don't have the user data to avoid a flicker
  return (
    <RelayContext.Consumer>
      {environment => (
        <QueryRenderer
          environment={environment}
          query={query}
          variables={{}}
          render={({ error, props, retry }) => {
            if (error) {
              return <Error error={error} retry={retry} />;
            } else if (props) {
              return (
                <Routes environment={environment} {...moduleProps} {...props} />
              );
            }

            return <LoadingMask />;
          }}
        />
      )}
    </RelayContext.Consumer>
  );
};
