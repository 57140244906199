import React, { Component } from 'react';
import { createRefetchContainer, RelayRefetchProp } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { History } from 'history';
import RelayRenderer from '../../RelayRenderer';
import styles from './ViewProducts.module.css';
import { ProductTile } from './ProductTile/ProductTile';
import { ViewProducts_viewer } from './__generated__/ViewProducts_viewer.graphql';
import footerLogo from '../../../assets/images/footer2.png';

interface Props {
  relay: RelayRefetchProp;
  viewer: ViewProducts_viewer;
  history: History;
}

class ViewProducts extends Component<Props> {
  public refreshData = () => {
    this.props.relay.refetch({});
  };

  public renderProducts = () => {
    const { products, user } = this.props.viewer;
    return products.map(product => (
      <ProductTile
        environment={this.props.relay.environment}
        id={product.id}
        relay={this.props.relay}
        history={this.props.history}
        key={`product.title-${product.id}`}
        title={product.title}
        dateAvailable={product.dateAvailable}
        description={product.description}
        image={product.image || undefined}
        externalLink={product.externalLink || undefined}
        externalLinkText={product.buttonText || undefined}
        onDelete={this.refreshData}
        isAdmin={
          user
            ? user.roles.findIndex(role => role.name === 'admin') > -1
            : false
        }
      />
    ));
  };

  public render() {
    const { products } = this.props.viewer;
    return (
      <div className={styles.body}>
        <div className={styles.heading}>
          <p className={styles.productHeader}>
            <b>New Products</b>
          </p>
        </div>
        {products && products.length ? (
          this.renderProducts()
        ) : (
          <p className={styles.noContent}>No available products to display</p>
        )}
        <img src={footerLogo} alt="" className={styles.cornerLogo} />
      </div>
    );
  }
}

const query = graphql`
  query ViewProductsQuery {
    viewer {
      ...ViewProducts_viewer
    }
  }
`;

const ViewProductsFragment = createRefetchContainer(
  ViewProducts,
  {
    viewer: graphql`
      fragment ViewProducts_viewer on Viewer {
        user {
          roles {
            name
          }
        }
        products {
          id
          title
          description
          dateAvailable
          imageUri
          image
          externalLink
          buttonText
        }
      }
    `,
  },
  query
);

export default props => (
  <RelayRenderer {...props} container={ViewProductsFragment} query={query} />
);

// export default ViewProducts
