import React, { Component } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { History } from 'history';
import { createFragmentContainer } from 'react-relay';
import { Environment } from 'relay-runtime';

import SideMenuItem from './sideMenuItem';
import Badge from './badge';
import blukey from '../assets/images/blukey.svg';
import dashIcon from '../assets/images/dashboard.svg';
import adminIcon from '../assets/images/admin.svg';
import reportIcon from '../assets/images/reports.svg';
import infoIcon from '../assets/images/info.svg';
import notificationsIcon from '../assets/images/ic_notification.svg';
import notificationHistoryIcon from '../assets/images/ic_notification_history.svg';
import notificationComposeIcon from '../assets/images/ic_notification_compose.svg';
import logoutIcon from '../assets/images/exit.svg';
import menuIcon from '../assets/images/menu.svg';
import userIcon from '../assets/images/user.svg';
import chevronUp from '../assets/images/chevron-up-white.svg';

import SideBarStyle from './sideBarMenu.module.css';
import DesktopOnly from './DesktopOnly';
import PleaseCallMe from '../modules/PleaseCallMe/PleaseCallMe';
import { sidebarMenu_viewer } from './__generated__/sidebarMenu_viewer.graphql';

interface Props {
  environment: Environment;
  history: History;
  handleLogout: () => void;
  viewer: sidebarMenu_viewer | null;
}

interface State {
  open: boolean;
  openReportsSubmenu: boolean;
  notificationsOpen: boolean;
  productSubMenuOpen: boolean;
}

export class SidebarMenu extends Component<Props, State> {
  public state: State = {
    open: false,
    openReportsSubmenu: false,
    notificationsOpen: true,
    productSubMenuOpen: false,
  };

  public async componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  public updateDimensions = () => {
    if (window.innerWidth > 1023) {
      this.setState({ open: false });
    }
  };

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  public userClasses = (username: string) => {
    let classList = 'user-display closed';
    classList =
      username.length <= 6
        ? classList
        : username.length <= 8
        ? (classList += ' smaller')
        : (classList += ' smallest');
    return new Array(classList)[0].split(' ');
  };

  public onClick() {
    this.setState({ open: !this.state.open });
  }

  public getMenuItemClass = () => {
    const width = window.innerWidth;
    return width >= 1024
      ? SideBarStyle['menu-item-closed'] + ` ${SideBarStyle.notifications}`
      : SideBarStyle['menu-item'] + ` ${SideBarStyle.notifications}`;
  };

  public getMenuImageClass = () => {
    const width = window.innerWidth;
    return width >= 1024
      ? SideBarStyle['menu-item-closed-img']
      : SideBarStyle['menu-open-icon'];
  };

  public getDashboard() {
    return (
      <div
        className={this.getMenuItemClass()}
        onClick={() => this.props.history.push('/dashboard')}
        role="button"
        tabIndex={0}
        style={{
          backgroundColor:
            this.props.history.location.pathname === '/dashboard'
              ? '#021640'
              : '',
        }}
      >
        <img
          alt="dashboard"
          src={dashIcon}
          className={this.getMenuImageClass()}
        />
        <p className={SideBarStyle['menu-header']}>Dashboard</p>
      </div>
    );
  }

  public getAdmin = (isAdmin, isReadOnlyAdmin) => {
    return (
      isAdmin &&
      !isReadOnlyAdmin && (
        <div
          className={SideBarStyle['menu-item-closed']}
          onClick={() => this.props.history.push('/admin')}
          role="button"
          tabIndex={0}
          style={
            this.props.history.location.pathname.startsWith('/admin')
              ? { backgroundColor: '#021640' }
              : {}
          }
        >
          <img
            className={SideBarStyle['menu-item-closed-img']}
            alt="admin"
            src={adminIcon}
          />
          <p>Admin</p>
          <div
            className={SideBarStyle.submenu}
            role="button"
            tabIndex={0}
            style={
              this.props.history.location.pathname.startsWith('/admin')
                ? { backgroundColor: '#021640' }
                : {}
            }
          >
            <SideMenuItem
              icon={adminIcon}
              path={'/admin/users'}
              history={this.props.history}
              title={'Users'}
            />
            <SideMenuItem
              icon={adminIcon}
              path={'/admin/create_user'}
              history={this.props.history}
              title={'Create User'}
            />
            <SideMenuItem
              icon={adminIcon}
              path={'/admin/dev-tools'}
              history={this.props.history}
              title={'Dev Tools'}
            />
            <div className={SideBarStyle.nestedSubmenu}>
              <SideMenuItem
                icon={reportIcon}
                path="/admin/user-logins"
                history={this.props.history}
                title="Reports"
              >
                <div className={SideBarStyle.nestedSubmenuItem}>
                  <SideMenuItem
                    icon={reportIcon}
                    path={'/admin/user-logins'}
                    history={this.props.history}
                    title="Logins"
                  />

                  <SideMenuItem
                    icon={reportIcon}
                    path={'/admin/registrations'}
                    history={this.props.history}
                    title="Registrations"
                  />
                </div>
              </SideMenuItem>
            </div>
          </div>
        </div>
      )
    );
  };

  public getFAQ = () => {
    return (
      <div
        className={this.getMenuItemClass()}
        onClick={() => this.props.history.push('/help')}
        role="button"
        tabIndex={0}
        style={{
          backgroundColor:
            this.props.history.location.pathname === '/help' ? '#021640' : '',
        }}
      >
        <img alt="HELP" src={infoIcon} className={this.getMenuImageClass()} />
        <p className={SideBarStyle['menu-header']}>Help</p>
      </div>
    );
  };

  public getProducts = (isAdmin: boolean, isReadOnlyAdmin: boolean) => {
    const showAdmin = isAdmin && !isReadOnlyAdmin;
    return (
      <div
        id="notifications"
        className={this.getMenuItemClass()}
        onClick={() => this.props.history.push('/products/view')}
        role="button"
        tabIndex={0}
        style={{
          backgroundColor:
            this.props.history.location.pathname === '/products/view' ||
            this.props.history.location.pathname === '/products/create'
              ? '#021640'
              : '',
        }}
      >
        <img
          className={this.getMenuImageClass()}
          alt="notifications"
          src={notificationsIcon}
        />
        <p className={SideBarStyle['menu-header']}>Products</p>
        <div
          id={SideBarStyle.notifications}
          className={SideBarStyle.submenu}
          onClick={() => this.props.history.push('/products/view')}
          role="button"
          tabIndex={0}
          style={
            this.props.history.location.pathname.startsWith('/products')
              ? { backgroundColor: '#021640' }
              : {}
          }
        >
          {showAdmin && (
            <SideMenuItem
              icon={notificationsIcon}
              path={'/products/view'}
              history={this.props.history}
              title={'View Products'}
            />
          )}
          {showAdmin && (
            <SideMenuItem
              icon={notificationComposeIcon}
              path={'/products/create'}
              history={this.props.history}
              title={'Create Product'}
            />
          )}
        </div>
      </div>
    );
  };

  public getNotifications = (
    isAdmin,
    unreadBroadcastNotifications,
    isReadOnlyAdmin
  ) => {
    return (
      <div
        id="notifications"
        className={this.getMenuItemClass()}
        onClick={() => this.props.history.push('/notifications')}
        role="button"
        tabIndex={0}
        style={{
          backgroundColor:
            this.props.history.location.pathname === '/notifications'
              ? '#021640'
              : '',
        }}
      >
        <Badge
          notificationCount={unreadBroadcastNotifications}
          viewer={this.props.viewer}
        />
        <img
          className={this.getMenuImageClass()}
          alt="notifications"
          src={notificationsIcon}
        />
        <p className={SideBarStyle['menu-header']}>Notifications</p>
        <div
          id={SideBarStyle.notifications}
          className={SideBarStyle.submenu}
          onClick={() => isAdmin && this.props.history.push('/notifications')}
          role="button"
          tabIndex={0}
          style={
            this.props.history.location.pathname.startsWith('/notifications')
              ? { backgroundColor: '#021640' }
              : {}
          }
        >
          {isAdmin && !isReadOnlyAdmin && (
            <SideMenuItem
              icon={notificationsIcon}
              path={'/notifications'}
              history={this.props.history}
              title={'notifications'}
            />
          )}
          {isAdmin && !isReadOnlyAdmin && (
            <SideMenuItem
              icon={notificationComposeIcon}
              path={'/notifications/compose-new'}
              history={this.props.history}
              title={'Compose Notification'}
            />
          )}
          {isAdmin && !isReadOnlyAdmin && (
            <SideMenuItem
              icon={notificationHistoryIcon}
              path={'/notifications/history'}
              history={this.props.history}
              title={'History'}
            />
          )}
        </div>
      </div>
    );
  };

  public getLogout = () => {
    return (
      <div
        className={this.getMenuItemClass()}
        onClick={() => this.props.handleLogout()}
        role="button"
        tabIndex={0}
      >
        <img
          alt="Log Out"
          src={logoutIcon}
          className={this.getMenuImageClass()}
        />
        <p className={SideBarStyle['menu-header']}>Log Out</p>
      </div>
    );
  };

  public getUserProfile = (classArray, username) => {
    const width = window.innerWidth;

    return (
      <div
        className={
          width >= 1024
            ? `${SideBarStyle[classArray[0]]} ${SideBarStyle[classArray[1]]} ${
                SideBarStyle[classArray[2]]
              }`
            : `${SideBarStyle['menu-item']} ${
                this.state.openReportsSubmenu
                  ? SideBarStyle['user-profile']
                  : null
              }`
        }
        onClick={() => this.props.history.push('/profile')}
        role="button"
        tabIndex={0}
      >
        <img
          className={`${SideBarStyle['font-awesome-icon']} ${
            SideBarStyle['menu-open-icon']
          }`}
          alt="Logged in as: "
          src={userIcon}
        />
        {width >= 1024 ? (
          username
        ) : (
          <p className={SideBarStyle['menu-header']}>{username}</p>
        )}
      </div>
    );
  };

  public render() {
    const { viewer } = this.props;

    const unreadBroadcastNotifications = viewer!.notifications!
      .unreadBroadcastNotifications;
    const user = viewer!.user;

    const username = user!.username;
    const accountNumber =
      user!.entities.length > 0 ? user!.entities[0].accountName : 'admin';

    const { pathname } = this.props.history.location;
    const classArray = this.userClasses(username);

    const isAdmin: boolean = !!user!.roles.find(role => {
      return (
        role.name === 'admin' ||
        role.name === 'regional_admin' ||
        role.name === 'readonly_admin'
      );
    });

    const isReadOnlyAdmin: boolean = !!user!.roles.find(role => {
      return role.name === 'readonly_admin';
    });
    const isAdminOrHub = isAdmin || user!.isHub;

    return (
      <div
        className={`${
          this.state.open
            ? `${SideBarStyle.closed} ${SideBarStyle.open}`
            : SideBarStyle.closed
        }`}
      >
        <div
          className={
            this.state.open
              ? `${SideBarStyle['menu-container']} ${SideBarStyle.open}`
              : `${SideBarStyle['menu-container']} ${SideBarStyle.closed}`
          }
        >
          {/* this.state.open checks if it should be mobile or desktop view */}
          {this.state.open ? (
            <div>
              <div
                className={SideBarStyle['menu-item']}
                style={{ backgroundColor: '#001B54', minHeight: '50px' }}
              >
                <img
                  alt=""
                  src={blukey}
                  className={SideBarStyle['menu-logo-closed']}
                  style={{ padding: '7px' }}
                />
                <img
                  alt="bars"
                  src={menuIcon}
                  className={SideBarStyle['menu-toggle']}
                  onClick={() => this.onClick()}
                />
              </div>
              {this.getDashboard()}
              {/* --------------------- REPORTS MENU --------------------------- */}
              <div
                className={
                  SideBarStyle['menu-item'] + ` ${SideBarStyle.notifications}`
                }
                onClick={() =>
                  this.setState({
                    openReportsSubmenu: !this.state.openReportsSubmenu,
                  })
                }
                role="button"
                tabIndex={0}
                style={{
                  backgroundColor: pathname.startsWith('/reports')
                    ? '#021640'
                    : '',
                }}
              >
                <img
                  alt="reports"
                  src={reportIcon}
                  className={SideBarStyle['menu-open-icon']}
                />
                <p className={SideBarStyle['menu-header']}>
                  Reports
                  <img
                    src={chevronUp}
                    className={`${SideBarStyle['right-chevron']} ${
                      this.state.openReportsSubmenu ? '' : SideBarStyle.spin
                    }`}
                  />
                </p>
              </div>
              <div
                className={`${SideBarStyle['menu-item-parent']} ${
                  this.state.openReportsSubmenu ? '' : SideBarStyle.closed
                }`}
              >
                <SideMenuItem
                  icon={reportIcon}
                  path={'/reports/contacts'}
                  history={this.props.history}
                  title={'Contact Details'}
                  viewType={this.state.open}
                />
                <SideMenuItem
                  icon={reportIcon}
                  path={'/reports/total-sales'}
                  history={this.props.history}
                  title={'Total Sales'}
                  viewType={this.state.open}
                />
                {isAdminOrHub && (
                  <SideMenuItem
                    icon={reportIcon}
                    path={'/reports/total-sales-spokes'}
                    history={this.props.history}
                    title={'Total Sales (All Spokes)'}
                    viewType={this.state.open}
                  />
                )}

                <SideMenuItem
                  icon={reportIcon}
                  path={'/reports/profits'}
                  history={this.props.history}
                  title={'Profit Reports'}
                  viewType={this.state.open}
                />
                {isAdminOrHub && (
                  <SideMenuItem
                    icon={reportIcon}
                    path={'/reports/negatives'}
                    history={this.props.history}
                    title={'Negative Reports'}
                    viewType={this.state.open}
                  />
                )}
              </div>
              {/* --------------------- END OF REPORTS MENU --------------------------- */}
              {this.getNotifications(
                isAdmin,
                unreadBroadcastNotifications,
                isReadOnlyAdmin
              )}

              {isAdmin ? (
                <SideMenuItem
                  icon={reportIcon}
                  path={'/products/view'}
                  history={this.props.history}
                  title={'Products'}
                  viewType={this.state.open}
                />
              ) : (
                this.getProducts(isAdmin, isReadOnlyAdmin)
              )}

              <PleaseCallMe
                isMobile={true}
                environment={this.props.environment}
                accountNumber={username}
                companyName={accountNumber}
                history={this.props.history}
              />

              {this.getFAQ()}
              {this.getLogout()}
              {this.getUserProfile([], username)}
            </div>
          ) : (
            <div>
              <div className={SideBarStyle['menu-top-dark-closed']}>
                <img
                  alt=""
                  src={blukey}
                  className={SideBarStyle['menu-logo-closed']}
                />
                <img
                  alt="bars"
                  src={menuIcon}
                  className={SideBarStyle['menu-toggle']}
                  onClick={() => this.onClick()}
                />
                <DesktopOnly>
                  {this.getDashboard()}
                  <div
                    className={SideBarStyle['menu-item-closed']}
                    onClick={() => this.props.history.push('/reports')}
                    role="button"
                    tabIndex={0}
                    style={
                      pathname.startsWith('/reports')
                        ? { backgroundColor: '#021640' }
                        : {}
                    }
                  >
                    <img
                      className={SideBarStyle['menu-item-closed-img']}
                      alt="reports"
                      src={reportIcon}
                    />
                    <p> Reports</p>
                    <div
                      className={SideBarStyle.submenu}
                      onClick={() => this.props.history.push('/reports')}
                      role="button"
                      tabIndex={0}
                      style={
                        pathname.startsWith('/reports')
                          ? { backgroundColor: '#021640' }
                          : {}
                      }
                    >
                      <SideMenuItem
                        icon={reportIcon}
                        path={'/reports/contacts'}
                        history={this.props.history}
                        title={'Contact Details'}
                        viewType={this.state.open}
                      />
                      <SideMenuItem
                        icon={reportIcon}
                        path={'/reports/total-sales'}
                        history={this.props.history}
                        title={'Total Sales'}
                        viewType={this.state.open}
                      />
                      {isAdminOrHub && (
                        <SideMenuItem
                          icon={reportIcon}
                          path={'/reports/total-sales-spokes'}
                          history={this.props.history}
                          title={'Total Sales (All Spokes)'}
                          viewType={this.state.open}
                        />
                      )}
                      <SideMenuItem
                        icon={reportIcon}
                        path={'/reports/profits'}
                        history={this.props.history}
                        title={'Profit Reports'}
                        viewType={this.state.open}
                      />
                      {isAdminOrHub && (
                        <SideMenuItem
                          icon={reportIcon}
                          path={'/reports/negatives'}
                          history={this.props.history}
                          title={'Negative Reports'}
                          viewType={this.state.open}
                        />
                      )}
                    </div>
                  </div>
                  {this.getAdmin(isAdmin, isReadOnlyAdmin)}
                  {this.getFAQ()}
                  {this.getNotifications(
                    isAdmin,
                    unreadBroadcastNotifications,
                    isReadOnlyAdmin
                  )}
                  {this.getProducts(isAdmin, isReadOnlyAdmin)}

                  <PleaseCallMe
                    environment={this.props.environment}
                    accountNumber={username}
                    companyName={accountNumber}
                    history={this.props.history}
                    isAdmin={
                      !!user!.roles.find(role => {
                        return (
                          role.name === 'admin' ||
                          role.name === 'regional_admin'
                          // role.name === 'readonly_admin'
                        );
                      })
                    }
                  />
                </DesktopOnly>
              </div>
              <DesktopOnly>
                {this.getLogout()}
                {this.getUserProfile(classArray, username)}
              </DesktopOnly>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(SidebarMenu, {
  viewer: graphql`
    fragment sidebarMenu_viewer on Viewer {
      notifications {
        unreadBroadcastNotifications
      }
      user {
        username
        entities {
          accountName
        }
        roles {
          name
        }
        isHub
      }
    }
  `,
});
