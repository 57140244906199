/* tslint:disable no-any */
import * as React from 'react';
import classnames from 'classnames';

interface Props {
  onClick: (event: any) => void;
  disabled?: boolean;
  text: any;
  className?: string;
  type?: 'Primary' | 'Secondary';
  buttonRole?: React.ButtonHTMLAttributes<any>['type'];
}

export default ({
  onClick,
  disabled = false,
  text,
  type = 'Primary',
  buttonRole = 'submit',
  className,
}: Props) => (
  <div
    className={classnames(
      type === 'Secondary' ? 'button-container is-cancel' : 'button-container'
    )}
  >
    <button
      onClick={event => {
        if (!disabled) {
          onClick(event);
        }
      }}
      disabled={disabled}
      className={classnames(
        'base-button',
        disabled && 'base-button-disabled',
        className
      )}
      type={buttonRole}
    >
      {text}
    </button>
  </div>
);
