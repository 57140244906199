/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type AccountSelectorSpokeSearchQueryVariables = {
    readonly search: string;
};
export type AccountSelectorSpokeSearchQueryResponse = {
    readonly viewer: {
        readonly spokeSearch: ({
            readonly edges: ReadonlyArray<({
                readonly node: ({
                    readonly name: string;
                    readonly account: string;
                    readonly hub: {
                        readonly name: string;
                        readonly account: string;
                    };
                }) | null;
            }) | null> | null;
        }) | null;
    };
};
export type AccountSelectorSpokeSearchQuery = {
    readonly response: AccountSelectorSpokeSearchQueryResponse;
    readonly variables: AccountSelectorSpokeSearchQueryVariables;
};



/*
query AccountSelectorSpokeSearchQuery(
  $search: String!
) {
  viewer {
    spokeSearch(search: $search) {
      edges {
        node {
          name
          account
          hub {
            name
            account
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "account",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "spokeSearch",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search",
        "type": "String!"
      }
    ],
    "concreteType": "SearchSpokeConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "edges",
        "storageKey": null,
        "args": null,
        "concreteType": "SearchSpokeEdge",
        "plural": true,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "node",
            "storageKey": null,
            "args": null,
            "concreteType": "SearchSpoke",
            "plural": false,
            "selections": [
              v1,
              v2,
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "hub",
                "storageKey": null,
                "args": null,
                "concreteType": "SearchSpoke",
                "plural": false,
                "selections": [
                  v1,
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AccountSelectorSpokeSearchQuery",
  "id": null,
  "text": "query AccountSelectorSpokeSearchQuery(\n  $search: String!\n) {\n  viewer {\n    spokeSearch(search: $search) {\n      edges {\n        node {\n          name\n          account\n          hub {\n            name\n            account\n          }\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AccountSelectorSpokeSearchQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "viewer",
        "name": "__viewer_viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": v3
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AccountSelectorSpokeSearchQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": v3
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "viewer",
        "args": null,
        "handle": "viewer",
        "key": "",
        "filters": null
      }
    ]
  }
};
})();
(node as any).hash = '278d0e91255b0302fb2cf8825343cfb5';
export default node;
