/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type SalesCategory = "AIRTIME" | "DATA" | "DSTV" | "ELECTRICITY" | "HOLLYWOOD_BETS" | "LOTTO" | "M2M" | "OTHER" | "RICA_FAILED" | "RICA_SUCCESS" | "RICA_TOTAL" | "TOTAL_SALES" | "UNIPIN" | "%future added value";
export type DashboardSearch_account$ref = any;
export type DashboardSearch_account = {
    readonly categories: ReadonlyArray<{
        readonly category: SalesCategory;
        readonly allZero: boolean;
    }>;
    readonly " $refType": DashboardSearch_account$ref;
};



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "DashboardSearch_account",
  "type": "DashboardAccount",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "categories",
      "storageKey": null,
      "args": null,
      "concreteType": "DashboardCategorySales",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "category",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "allZero",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = '0e0cc2e784a2dd29896c402b3a78b345';
export default node;
