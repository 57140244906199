/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type NotificationBar_viewer$ref = any;
export type NotificationBar_viewer = {
    readonly broadcastNotifications: ({
        readonly total: number | null;
        readonly pageInfo: {
            readonly hasNextPage: boolean;
        };
        readonly edges: ReadonlyArray<({
            readonly node: ({
                readonly id: string;
                readonly read: ({
                    readonly createdAt: any;
                }) | null;
                readonly broadcast: {
                    readonly message: string;
                    readonly title: string;
                };
            }) | null;
        }) | null> | null;
    }) | null;
    readonly " $refType": NotificationBar_viewer$ref;
};



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "NotificationBar_viewer",
  "type": "Viewer",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": null,
        "direction": "forward",
        "path": [
          "broadcastNotifications"
        ]
      }
    ]
  },
  "argumentDefinitions": [
    {
      "kind": "LocalArgument",
      "name": "count",
      "type": "Int",
      "defaultValue": 3
    },
    {
      "kind": "LocalArgument",
      "name": "read",
      "type": "Boolean!",
      "defaultValue": false
    },
    {
      "kind": "LocalArgument",
      "name": "cancelled",
      "type": "Boolean!",
      "defaultValue": false
    },
    {
      "kind": "LocalArgument",
      "name": "expired",
      "type": "Boolean!",
      "defaultValue": false
    },
    {
      "kind": "LocalArgument",
      "name": "delivered",
      "type": "Boolean!",
      "defaultValue": true
    },
    {
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "ID",
      "defaultValue": null
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": "broadcastNotifications",
      "name": "__NotificationBar_broadcastNotifications_connection",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "cancelled",
          "variableName": "cancelled",
          "type": "Boolean"
        },
        {
          "kind": "Variable",
          "name": "delivered",
          "variableName": "delivered",
          "type": "Boolean"
        },
        {
          "kind": "Variable",
          "name": "expired",
          "variableName": "expired",
          "type": "Boolean"
        },
        {
          "kind": "Variable",
          "name": "read",
          "variableName": "read",
          "type": "Boolean"
        }
      ],
      "concreteType": "BroadcastNotificationConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "total",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "endCursor",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "BroadcastNotificationEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "BroadcastNotification",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "read",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BroadcastNotificationRead",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "createdAt",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "broadcast",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "Broadcast",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "message",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "title",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "__typename",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cursor",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = '6cc7fe3000eddc1c60739b274025216d';
export default node;
