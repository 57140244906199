import React, { useState } from 'react';
import { History } from 'history';
import { numberFormat } from '../../../services/NumberService';
import styles from './DashboardProductsReport.module.css';
import chevronUp from '../../../assets/images/chevron-up.svg';
import chevronDown from '../../../assets/images/chevron-down.svg';
import chevronYellowRight from '../../../assets/images/chevronYellowRight.png';

interface Props {
  history: History;
  salesData?: ReadonlyArray<{
    AirtimeSales: number;
    Data_Sales: number;
    Electricity_Sales: number;
    HBets_Sales: number;
    Unipin_Sales: number;
    DSTV_Sales: number;
    Lotto_Sales: number;
    M2M_Sales: number;
    Other_Sales: number;
  } | null> | null;
}

export const DashboardProductsReport = ({ salesData, history }: Props) => {
  if (!salesData || !salesData.length) {
    return null;
  }
  const [open, setOpen] = useState(false);

  const renderObj = {
    AirtimeSales: 0,
    Data_Sales: 0,
    Electricity_Sales: 0,
    HBets_Sales: 0,
    Unipin_Sales: 0,
    DSTV_Sales: 0,
    Lotto_Sales: 0,
    M2M_Sales: 0,
    Other_Sales: 0,
  };

  const salesCount = {
    AirtimeSalesCount: 0,
    DataSalesCount: 0,
    ElectricitySalesCount: 0,
    HBetsSalesCount: 0,
    UnipinSalesCount: 0,
    DSTVSalesCount: 0,
    LottoSalesCount: 0,
    M2MSalesCount: 0,
    OtherSalesCount: 0,
  };

  salesData.slice().forEach(item => {
    if (item) {
      Object.keys(renderObj).forEach(amount => {
        renderObj[amount] =
          Math.round((renderObj[amount] + item[amount]) * 100) / 100;
      });
    }
  });

  salesData.slice().forEach(item => {
    if (item) {
      Object.keys(salesCount).forEach(amount => {
        salesCount[amount] =
          Math.round((salesCount[amount] + item[amount]) * 100) / 100;
      });
    }
  });

  const sortObj = Object.assign({}, renderObj);
  const sorted = Object.keys(sortObj).sort((a, b) => sortObj[b] - sortObj[a]);

  const calculateWidth = amount => {
    let largest = 0;
    Object.values(renderObj).forEach(val => {
      if (val > largest) {
        largest = val;
      }
    });
    const percentage = Math.round((amount / largest) * 100);
    return `${percentage}%`;
  };

  const getCount = (item: string) => {
    const itemName =
      item
        .split('_')
        .join('')
        .split('Sales')[0] + 'SalesCount';
    return salesCount[itemName];
  };

  return (
    <div className={open ? styles.root : styles.rootClosed}>
      <div
        className={styles.header}
        role="button"
        tabIndex={0}
        onClick={() => setOpen(!open)}
      >
        Product Performance
        <img className={styles.chevron} src={open ? chevronUp : chevronDown} />
      </div>
      {open && (
        <div className={styles.container}>
          <p className={styles.subHeader}>
            Total product performance per category over the last 30 days for all
            spokes
          </p>
          <div className={styles.dataBlock}>
            {sorted.map(key => (
              <div className={styles.dataRow} key={key}>
                <div
                  className={styles.innerRow}
                  role="button"
                  tabIndex={0}
                  onClick={() =>
                    history.push(
                      `reports/${
                        key.split('Sales')[0].split('_')[0]
                      }/performance`
                    )
                  }
                >
                  <p>{key.split('Sales')[0].split('_')[0]}</p>
                  {/*Update this when build is fixed*/}

                  <p className={styles.amount}>
                    <span className={styles.salesCount}>
                      {getCount(key)} Transactions
                    </span>
                    R {numberFormat(renderObj[key].toFixed(2))}
                  </p>

                  <img
                    className={styles.chevronYellow}
                    src={chevronYellowRight}
                  />
                </div>
                <div
                  className={styles.separator}
                  style={{ width: calculateWidth(renderObj[key]) }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
