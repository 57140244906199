import graphql from 'babel-plugin-relay/macro';
import { createMutation } from '@stackworx/relay';

import { ResetUserPasswordSubmitPinMutation } from './__generated__/ResetUserPasswordSubmitPinMutation.graphql';

const mutation = graphql`
  mutation ResetUserPasswordSubmitPinMutation(
    $input: UserResetPasswordSubmitPinInput!
  ) {
    userResetPasswordSubmitPin(input: $input) {
      clientMutationId
      token
    }
  }
`;

export default createMutation<ResetUserPasswordSubmitPinMutation>(mutation);
