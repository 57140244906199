import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';
import DefaultTheme from 'react-dates/lib/theme/DefaultTheme';

ThemedStyleSheet.registerInterface(aphroditeInterface);
ThemedStyleSheet.registerTheme({
  reactDates: {
    ...DefaultTheme.reactDates,
    border: {
      input: {
        border: 0,
        borderTop: 0,
        borderRight: 0,
        borderBottom: '2px solid transparent',
        borderLeft: 0,
        outlineFocused: 0,
        borderFocused: 0,
        borderTopFocused: 0,
        borderLeftFocused: 0,
        borderBottomFocused: `2px solid `,
        borderRightFocused: 0,
        borderRadius: 0,
      },
      pickerInput: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 2,
      },
    },
    color: {
      ...DefaultTheme.reactDates.color,
      selected: {
        backgroundColor: '#021640',
        backgroundColor_active: '#021640 ',
        backgroundColor_hover: '#021640 ',
        color: '#fff',
        color_active: '#fff',
        color_hover: '#fff',
      },
      selectedSpan: {
        backgroundColor: '#004D92',
        backgroundColor_active: '004D92',
        backgroundColor_hover: '004D92',
        borderColor: '#021640',
        borderColor_active: '#0070B9',
        borderColor_hover: '#0070B9',
        color: '#fff',
        color_active: '#fff',
        color_hover: '#fff',
      },
      hoveredSpan: {
        backgroundColor: '#004D92',
        backgroundColor_active: '004D92',
        backgroundColor_hover: '004D92',
        borderColor: '#021640',
        borderColor_active: '#021640',
        borderColor_hover: '#021640',
        color: '#fff',
        color_active: '#fff',
        color_hover: '#fff',
      },
    },
    sizing: {
      inputWidth: 200,
      inputWidth_small: 97,
      arrowWidth: 24,
    },
  },
});
