/* tslint:disable no-any */
import * as React from 'react';
import { Component } from 'react';
import DataBlockStyles from './DashboardDataBlock.module.css';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { DashboardNegativeSummary_viewer } from './__generated__/DashboardNegativeSummary_viewer.graphql';

interface Props {
  title: string;
  isCurrency?: boolean;
  handleClick: () => void;
  viewer: DashboardNegativeSummary_viewer;
}

const Spacer = () => <span> </span>;

class DashboardNegativeSummary extends Component<Props> {
  public render() {
    const { title, viewer } = this.props;

    const viewerData =
      viewer.report &&
      viewer.report.reportNegativeTradingSummary &&
      viewer.report.reportNegativeTradingSummary[0];

    if (!viewerData) {
      return null;
    }

    const {
      notTradeInLast4Weeks,
      notTradeInLast7Days,
      notTradedInLastDay,
      notTradedSinceInception,
      tradingBalanceLess100,
      tradingWorseThanLastMonth,
      totalSpokes,
    } = viewerData;

    return (
      <div
        onClick={this.props.handleClick}
        className={`${DataBlockStyles['grey-block-container']} ${
          DataBlockStyles['negative-grey-block']
        }`}
        tabIndex={0}
      >
        <div
          className={`${DataBlockStyles['negative-block']} ${
            DataBlockStyles['negative-summary']
          }`}
        >
          <p
            className={`${DataBlockStyles['block-title']} ${
              title.length > 20 ? DataBlockStyles.small : ''
            }`}
            data-tip={title.length > 20 ? title : ''}
          >
            {`${title} - ${totalSpokes} TOTAL SPOKES`}
          </p>
          <div className={DataBlockStyles['block-text-small']}>
            <div>
              <div className="bottomLine">
                <span className={DataBlockStyles.negativeTradingText}>
                  <b>
                    <u>{notTradedInLastDay}</u>
                  </b>{' '}
                  Spokes have not traded in the last day,
                </span>
                <Spacer />
                <span className={DataBlockStyles.negativeTradingText}>
                  <b>
                    <u>{notTradeInLast7Days}</u>
                  </b>{' '}
                  in the last 7 days,
                </span>
                <Spacer />
                <span className={DataBlockStyles.negativeTradingText}>
                  <b>
                    <u>{notTradeInLast4Weeks}</u>
                  </b>{' '}
                  in the last 4 weeks and
                </span>
                <Spacer />
                <span className={DataBlockStyles.negativeTradingText}>
                  <b>
                    <u>{notTradedSinceInception}</u>
                  </b>{' '}
                  since inception.
                </span>
              </div>
              <div>
                <span className={DataBlockStyles.negativeTradingText}>
                  <b>
                    <u>{tradingWorseThanLastMonth}</u>
                  </b>{' '}
                  Spokes are currently trading worse than the same period last
                  month.
                </span>
              </div>
              <div>
                <span className={DataBlockStyles.negativeTradingText}>
                  <b>
                    <u>{tradingBalanceLess100}</u>
                  </b>{' '}
                  Spokes had a trading balance &lt; R100 at the end of
                  Yesterday.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default DashboardNegativeSummary;

export default createFragmentContainer(DashboardNegativeSummary, {
  viewer: graphql`
    fragment DashboardNegativeSummary_viewer on Viewer {
      report {
        reportNegativeTradingSummary(entityReference: $entityReference) {
          notTradedInLastDay
          notTradeInLast7Days
          notTradeInLast4Weeks
          notTradedSinceInception
          tradingWorseThanLastMonth
          tradingBalanceLess100
          totalSpokes
        }
      }
    }
  `,
});
