/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
type DashboardDataViewer_viewer$ref = any;
type DashboardViewDataModal_viewer$ref = any;
type EntityCombobox_viewer$ref = any;
export type Dashboard_viewer$ref = any;
export type Dashboard_viewer = {
    readonly user: ({
        readonly username: string;
        readonly email: string | null;
        readonly userTourComplete: number | null;
        readonly cellphoneNumber: string | null;
        readonly regions: string | null;
        readonly isHub: boolean;
        readonly roles: ReadonlyArray<{
            readonly name: string;
        }>;
        readonly loginCount: ({
            readonly lastLogin: any;
            readonly count: number;
        }) | null;
        readonly lastPrompt: ({
            readonly id: string;
            readonly LastPromptTime: any | null;
        }) | null;
    }) | null;
    readonly report: ({
        readonly contact: ReadonlyArray<{
            readonly cellNumber: string;
            readonly regionName: string | null;
            readonly contactName: string;
            readonly spoke: {
                readonly accountName: string;
            };
        }> | null;
    }) | null;
    readonly users: ({
        readonly edges: ReadonlyArray<({
            readonly node: ({
                readonly username: string;
                readonly regions: string | null;
                readonly cellphoneNumber: string | null;
            }) | null;
        }) | null> | null;
    }) | null;
    readonly " $fragmentRefs": EntityCombobox_viewer$ref & DashboardDataViewer_viewer$ref & DashboardViewDataModal_viewer$ref;
    readonly " $refType": Dashboard_viewer$ref;
};



const node: ConcreteFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "username",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "cellphoneNumber",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "regions",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "Dashboard_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "entityReference",
      "type": "String!"
    }
  ],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "EntityCombobox_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "DashboardDataViewer_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "DashboardViewDataModal_viewer",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "email",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "userTourComplete",
          "args": null,
          "storageKey": null
        },
        v1,
        v2,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isHub",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "roles",
          "storageKey": null,
          "args": null,
          "concreteType": "Role",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "loginCount",
          "storageKey": null,
          "args": null,
          "concreteType": "LoginCount",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastLogin",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "count",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "lastPrompt",
          "storageKey": null,
          "args": null,
          "concreteType": "LastPrompt",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "LastPromptTime",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "report",
      "storageKey": null,
      "args": null,
      "concreteType": "Report",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "contact",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "entityReference",
              "variableName": "entityReference",
              "type": "String!"
            }
          ],
          "concreteType": "ContactReport",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "cellNumber",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "regionName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "contactName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "spoke",
              "storageKey": null,
              "args": null,
              "concreteType": "Account",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "accountName",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "users",
      "storageKey": null,
      "args": null,
      "concreteType": "ViewerUsersConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "ViewerUsersEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "User",
              "plural": false,
              "selections": [
                v0,
                v2,
                v1
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
(node as any).hash = 'ff46a166ed2a11c9d73f885f10f516b8';
export default node;
