/* tslint:disable no-any */
import { ConfigService } from '@stackworx/react';
import { store } from '../store';

const endpointUrl = (path: string) => `${ConfigService.serverUri}/${path}`;

const buildHeader = (token: string | null) => {
  const headers = new Headers();
  headers.set('Accept', 'application/json');
  headers.set('Content-Type', 'application/json');
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }
  return headers;
};

export default class NetworkService {
  public static getToken = async (username: string, password: string) => {
    const headers = new Headers();
    headers.set('Accept', 'application/json');
    headers.set('Content-Type', 'application/json');

    return NetworkService.post(
      'oauth/token/',
      {
        grant_type: 'password',
        password,
        username,
      },
      headers
    );
  };

  public static post = async (path: string, data: any, headers: any = null) => {
    const { token } = store.getState();
    const url = endpointUrl(path);
    const response = await fetch(url, {
      body: JSON.stringify({
        ...data,
      }),
      headers: headers || buildHeader(token),
      method: 'POST',
    });

    if (!response.ok) {
      if (response.status === 401) {
        store.dispatch({ type: 'LOGOUT' });
        throw new Error('Invalid Username or Password ');
      }
      try {
        const error = await response.json();
        throw new Error(error.message || 'An unexpected error occurred.');
      } catch (ex) {
        throw new Error('An unexpected error occurred.');
      }
    }
    return response.json();
  };
}
