/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type ProductDeleteInput = {
    readonly id: string;
    readonly clientMutationId?: string | null;
};
export type ProductDeleteMutationVariables = {
    readonly input: ProductDeleteInput;
};
export type ProductDeleteMutationResponse = {
    readonly productDelete: ({
        readonly clientMutationId: string | null;
    }) | null;
};
export type ProductDeleteMutation = {
    readonly response: ProductDeleteMutationResponse;
    readonly variables: ProductDeleteMutationVariables;
};



/*
mutation ProductDeleteMutation(
  $input: ProductDeleteInput!
) {
  productDelete(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ProductDeleteInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "productDelete",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ProductDeleteInput!"
      }
    ],
    "concreteType": "ProductDeletePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ProductDeleteMutation",
  "id": null,
  "text": "mutation ProductDeleteMutation(\n  $input: ProductDeleteInput!\n) {\n  productDelete(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ProductDeleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ProductDeleteMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = 'cc4173f0c1c4ecb7812e8d2a1a2c167b';
export default node;
