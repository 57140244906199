import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { requestSubscription } from 'react-relay';
import {
  Disposable,
  RecordSourceSelectorProxy,
  ConnectionHandler,
} from 'relay-runtime';
import RelayContext from '../../contexts/RelayContext';

import { UnreadNotificationsSubscriptionSubscription } from './__generated__/UnreadNotificationsSubscriptionSubscription.graphql';

const subscription = graphql`
  subscription UnreadNotificationsSubscriptionSubscription {
    viewerNotifications {
      unreadBroadcastNotifications
    }
  }
`;

export default class UnreadNotificationSubscription extends React.Component {
  public static contextType = RelayContext;

  private disposable?: Disposable;

  public componentDidMount() {
    const subscriptionConfig = {
      subscription,
      variables: {},
      onNext: (_resp: UnreadNotificationsSubscriptionSubscription) => {
        // TODO toast??
      },
      updater: (store: RecordSourceSelectorProxy) => {
        const notificationCount = store.getRootField('viewerNotifications');

        if (!notificationCount) {
          return 0;
        }

        const viewer = store.get('client:root:viewer');

        const notifications = ConnectionHandler.getConnection(
          viewer,
          'Broadcasts_broadcastNotifications'
        );

        if (!notifications) {
          return;
        }

        ConnectionHandler.createEdge(
          store,
          notifications,
          notificationCount,
          '<UnreadNotificationCountEdge>'
        );
      },
      //  onError: error => console.log(`An error occured:`, error),
    };

    this.disposable = requestSubscription(this.context, subscriptionConfig);
  }
  public componentWillUnmount() {
    if (this.disposable) {
      this.disposable.dispose();
    }
  }

  public render() {
    return null;
  }
}
