import OtpInput from './ReactOtpInput';
import * as React from 'react';
import { FieldProps } from 'formik';
import styles from '../modules/SelfRegistration/components/registrationFields.module.css';

interface Props extends FieldProps {
  label: string;
  placeholder: string;
  type?: string;
}

interface State {
  screenWidth: number;
}

const textBoxStyle = {
  width: '45px',
  height: '45px',
  padding: '0px',
  textAlign: 'center',
  color: '#707071',
  border: '1px solid #707071',
  margin: '10px',
  borderRadius: '5px',
  fontSize: '16px',
};

const textBoxStyleSmall = {
  width: '45px',
  height: '45px',
  padding: '0px',
  textAlign: 'center',
  color: '#707071',
  border: '1px solid #707071',
  margin: '2px',
  borderRadius: '5px',
  fontSize: '16px',
};

export default class OtpField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      screenWidth: 0,
    };
  }
  public componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  public updateWindowDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  };

  public render() {
    const {
      // type = 'text',
      field,
      label,
      // placeholder,
      form: { touched, errors, setFieldValue },
    } = this.props;
    const { name } = field;

    const error = touched[name] && errors[name];
    return (
      <div className="add-user-input-container">
        <p className={styles.otpLabel}>{label}</p>
        <OtpInput
          containerStyle={{
            justifyContent: 'center',
          }}
          inputStyle={
            this.state.screenWidth <= 500 ? textBoxStyleSmall : textBoxStyle
          }
          numInputs={5}
          onChange={otp => {
            setFieldValue(field.name, otp);
          }}
        />
        {error && <div className={styles.otpErrorLabel}>{error}</div>}
      </div>
    );
  }
}
