/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type TourCompleteInput = {
    readonly tourCompleted?: boolean | null;
    readonly clientMutationId?: string | null;
};
export type TourCompleteMutationVariables = {
    readonly input: TourCompleteInput;
};
export type TourCompleteMutationResponse = {
    readonly userTourComplete: ({
        readonly clientMutationId: string | null;
        readonly tourCompleted: boolean | null;
    }) | null;
};
export type TourCompleteMutation = {
    readonly response: TourCompleteMutationResponse;
    readonly variables: TourCompleteMutationVariables;
};



/*
mutation TourCompleteMutation(
  $input: TourCompleteInput!
) {
  userTourComplete(input: $input) {
    clientMutationId
    tourCompleted
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "TourCompleteInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "userTourComplete",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "TourCompleteInput!"
      }
    ],
    "concreteType": "TourCompletePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "tourCompleted",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "TourCompleteMutation",
  "id": null,
  "text": "mutation TourCompleteMutation(\n  $input: TourCompleteInput!\n) {\n  userTourComplete(input: $input) {\n    clientMutationId\n    tourCompleted\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "TourCompleteMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "TourCompleteMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = '0d92755fe76af5e4daf243eae6d8426f';
export default node;
