import React, { Component, FormEvent } from 'react';
import { Environment } from 'relay-runtime';
import { RelayProp } from 'react-relay';
import { History } from 'history';
import { ConfigService } from '@stackworx/react';
// import Spinner from 'react-spinkit';
import styles from './CreateProduct.module.css';
import { EnvironmentProp } from '../../RelayRenderer';
import RelayContext from '../../../contexts/RelayContext';

interface Props extends EnvironmentProp {
  /* tslint:disable-next-line */
  viewer?: any;
  environment: Environment;
  relay: RelayProp;
  history: History;
  alternateForm?: boolean;
  productId?: string;
  handleClose?: () => void;
  values?: {
    title: string;
    description: string;
    dateAvailable: string;
    externalLink?: string;
    externalLinkText?: string;
  };
}

interface FormErrors {
  title?: string;
  description?: string;
  dateAvailable?: string;
  buttonText?: string;
  photo?: string;
}

interface State {
  formErrors: FormErrors;
  loading?: boolean;
  values: {
    title: string;
    description: string;
    dateAvailable: string;
    externalLink: string;
    externalLinkText: string;
  };
}

class CreateProduct extends Component<Props, State> {
  public static contextType = RelayContext;
  constructor(props: Props) {
    super(props);

    let initialValues = {
      title: '',
      description: '',
      dateAvailable: '',
      externalLink: '',
      externalLinkText: '',
    };

    if (props.values) {
      initialValues = {
        title: props.values.title,
        description: props.values.description,
        dateAvailable: props.values.dateAvailable,
        externalLink: props.values.externalLink || '',
        externalLinkText: props.values.externalLinkText || '',
      };
    }

    this.state = {
      formErrors: {},
      values: initialValues,
    };
  }

  public handleSubmit = (e: FormEvent) => {
    this.setState({ loading: true });
    e.preventDefault();
    const formErrors: FormErrors = {};
    const productForm = document.forms[`productCreate`];
    let hasError = false;

    if (productForm.title.value === '') {
      formErrors.title = 'Title is required';
      hasError = true;
    }
    if (productForm.description.value === '') {
      formErrors.description = 'Product description is required';
      hasError = true;
    }
    if (productForm.description.length > 255) {
      formErrors.description = 'Please limit description to 255 characters';
      hasError = true;
    }
    if (productForm.dateAvailable.value === '') {
      formErrors.dateAvailable = 'Date is required';
      hasError = true;
    }
    if (
      productForm.photo.files &&
      productForm.photo.files[0] &&
      productForm.photo.files[0].size > 1000000
    ) {
      formErrors.photo = 'Image size limit: 1MB';
      hasError = true;
    }
    if (
      productForm.externalLink.value.length &&
      productForm.buttonText.value === ''
    ) {
      formErrors.buttonText =
        'Button text is required if external link is provided';
      hasError = true;
    }
    if (hasError) {
      this.setState({ formErrors, loading: false });
      return false;
    }
    this.props.history.push('/products/view');
    productForm.submit();

    return true;
  };

  public render() {
    const { formErrors, loading, values } = this.state;

    let action = `${ConfigService.serverUri}/products/upload-image`;

    if (this.props.alternateForm && this.props.productId) {
      action = `${ConfigService.serverUri}/products/${
        atob(this.props.productId).split(':')[1]
      }/update-product`;
    }

    return (
      <div className={styles.body}>
        <div className={styles.heading}>
          <p className={styles.productHeader}>
            <b>PRODUCTS</b> - CREATE A NEW PRODUCT
          </p>
        </div>
        <div className={styles.formContainer}>
          <form
            action={action}
            method="POST"
            encType="multipart/form-data"
            onSubmit={this.handleSubmit}
            name="productCreate"
          >
            <p className={styles.label}>Product Title *</p>
            <input
              type="text"
              name="title"
              className={
                formErrors.title ? styles.errorInput : styles.productInput
              }
              placeholder="Product title"
              maxLength={60}
              value={values.title}
              onChange={e => {
                this.setState({ values: { ...values, title: e.target.value } });
              }}
            />
            {formErrors.title && (
              <p className={styles.errorText}>{formErrors.title}</p>
            )}
            <div className={styles.textAreaContainer}>
              <p className={styles.label}>Product Description *</p>
              <textarea
                name="description"
                className={
                  formErrors.description
                    ? styles.errorDescr
                    : styles.textAreaDescr
                }
                maxLength={255}
                value={values.description}
                onChange={e => {
                  this.setState({
                    values: { ...values, description: e.target.value },
                  });
                }}
              />
              {formErrors.description && (
                <p className={styles.errorText}>{formErrors.description}</p>
              )}
            </div>
            <p className={styles.label}>Product availability date *</p>
            <input
              className={
                formErrors.dateAvailable
                  ? styles.errorInput
                  : styles.productInput
              }
              name="dateAvailable"
              type="date"
              max="2025-12-31"
              placeholder="yyyy/mm/dd"
              value={values.dateAvailable}
              onChange={e => {
                this.setState({
                  values: { ...values, dateAvailable: e.target.value },
                });
              }}
            />
            {formErrors.dateAvailable && (
              <p className={styles.errorText}>{formErrors.dateAvailable}</p>
            )}
            <p className={styles.label}>Image</p>
            <input
              type="file"
              name="photo"
              className={
                formErrors.photo ? styles.errorInput : styles.productInput
              }
            />
            {formErrors.photo && (
              <p className={styles.errorText}>{formErrors.photo}</p>
            )}
            <p className={styles.label}>External Link</p>
            <input
              name="externalLink"
              type="text"
              placeholder="External link to product"
              className={styles.productInput}
              maxLength={255}
              value={values.externalLink}
              onChange={e => {
                this.setState({
                  values: { ...values, externalLink: e.target.value },
                });
              }}
            />
            <p className={styles.label}>Button Text</p>
            <input
              name="buttonText"
              type="text"
              placeholder="Text for external link button"
              className={
                formErrors.buttonText ? styles.errorInput : styles.productInput
              }
              maxLength={60}
              value={values.externalLinkText}
              onChange={e => {
                this.setState({
                  values: { ...values, externalLinkText: e.target.value },
                });
              }}
            />
            {formErrors.buttonText && (
              <p className={styles.errorText}>{formErrors.buttonText}</p>
            )}

            <input
              type="submit"
              value={loading ? 'submitting...' : 'submit'}
              className={styles.submit}
              disabled={loading}
            />
          </form>
          {this.props.alternateForm && (
            <button
              disabled={loading}
              className={styles.submit}
              onClick={this.props.handleClose}
            >
              cancel
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default CreateProduct;
