/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type SelfRegistrationCompleteRegistrationInput = {
    readonly firstname: string;
    readonly lastname: string;
    readonly email?: string | null;
    readonly password: string;
    readonly token: string;
    readonly entitynumber: string;
    readonly cellNumber: string;
    readonly userDefinedRegion: string;
    readonly clientMutationId?: string | null;
};
export type SelfRegistrationCompleteRegistrationMutationVariables = {
    readonly input: SelfRegistrationCompleteRegistrationInput;
};
export type SelfRegistrationCompleteRegistrationMutationResponse = {
    readonly selfRegistrationCompleteRegistration: ({
        readonly clientMutationId: string | null;
        readonly token: string;
        readonly username: string;
    }) | null;
};
export type SelfRegistrationCompleteRegistrationMutation = {
    readonly response: SelfRegistrationCompleteRegistrationMutationResponse;
    readonly variables: SelfRegistrationCompleteRegistrationMutationVariables;
};



/*
mutation SelfRegistrationCompleteRegistrationMutation(
  $input: SelfRegistrationCompleteRegistrationInput!
) {
  selfRegistrationCompleteRegistration(input: $input) {
    clientMutationId
    token
    username
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SelfRegistrationCompleteRegistrationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "selfRegistrationCompleteRegistration",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "SelfRegistrationCompleteRegistrationInput!"
      }
    ],
    "concreteType": "SelfRegistrationCompleteRegistrationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "token",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "username",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SelfRegistrationCompleteRegistrationMutation",
  "id": null,
  "text": "mutation SelfRegistrationCompleteRegistrationMutation(\n  $input: SelfRegistrationCompleteRegistrationInput!\n) {\n  selfRegistrationCompleteRegistration(input: $input) {\n    clientMutationId\n    token\n    username\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SelfRegistrationCompleteRegistrationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "SelfRegistrationCompleteRegistrationMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = 'f1270742ec0dff44805fc6203525ebf9';
export default node;
