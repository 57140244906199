/* tslint:disable no-any max-classes-per-file */
import React, { Component, PureComponent } from 'react';

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;

interface Props {
  numInputs: number;
  onChange: any;
  separator?: any;
  containerStyle?: any;
  inputStyle?: any;
  focusStyle?: any;
  isDisabled?: boolean;
  disabledStyle?: any;
  hasErrored?: boolean;
  errorStyle?: any;
  shouldAutoFocus?: boolean;
  isInputNum?: boolean;
}

interface State {
  activeInput: number;
  otp: string[];
  inputRefs: Array<React.RefObject<any>>;
}

// Doesn't really check if it's a style Object
// Basic implemenetation to check if it's not a string
// of classNames and is an Object
// TODO: Better implementation
const isStyleObject = obj => typeof obj === 'object';

class SingleOtpInput extends PureComponent<any> {
  public input?: HTMLInputElement | null = null;

  // Focus on first render
  // Only when shouldAutoFocus is true
  public componentDidMount() {
    const {
      input,
      props: { focus, shouldAutoFocus },
    } = this;

    if (input && focus && shouldAutoFocus) {
      input.focus();
    }
  }

  public componentDidUpdate(prevProps) {
    const {
      input,
      props: { focus },
    } = this;

    // Check if focusedInput changed
    // Prevent calling function if input already in focus
    if (prevProps.focus !== focus && (input && focus)) {
      input.focus();
      input.select();
    }
  }

  public getClasses = (...classes) =>
    classes.filter(c => !isStyleObject(c) && c !== false).join(' ');

  public render() {
    const {
      separator,
      isLastChild,
      inputStyle,
      focus,
      isDisabled,
      hasErrored,
      errorStyle,
      focusStyle,
      disabledStyle,
      shouldAutoFocus,
      isInputNum,
      value,
      ...rest
    } = this.props;

    const numValueLimits = isInputNum ? { min: 0, max: 9 } : {};

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          style={Object.assign(
            { width: '1em', textAlign: 'center' },
            inputStyle,
            focus && isStyleObject(focusStyle) && focusStyle,
            isDisabled && isStyleObject(disabledStyle) && disabledStyle,
            hasErrored && isStyleObject(errorStyle) && errorStyle
          )}
          className={this.getClasses(
            focus && focusStyle,
            isDisabled && disabledStyle,
            hasErrored && errorStyle
          )}
          type={isInputNum ? 'number' : 'tel'}
          {...numValueLimits}
          maxLength={1}
          ref={input => {
            this.input = input;
          }}
          disabled={isDisabled}
          value={value ? value : ''}
          {...rest}
        />
        {!isLastChild && separator}
      </div>
    );
  }
}

class OtpInput extends Component<Props, State> {
  public static defaultProps = {
    numInputs: 4,
    // tslint:disable-next-line no-console
    onChange: (otp: number): void => console.log(otp),
    isDisabled: false,
    shouldAutoFocus: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      activeInput: 0,
      otp: [],
      inputRefs: Array.from(Array(props.numInputs)).map(() =>
        React.createRef()
      ),
    };
  }

  // Helper to return OTP from input
  public getOtp = () => {
    this.props.onChange(this.state.otp.join(''));
  };

  // Focus on input by index
  public focusInput = (input: number) => {
    const { numInputs } = this.props;
    const activeInput = Math.max(Math.min(numInputs - 1, input), 0);

    this.setState({
      activeInput,
    });
  };

  // Focus on next input
  public focusNextInput = () => {
    const { activeInput } = this.state;
    this.focusInput(activeInput + 1);
  };

  // Focus on previous input
  public focusPrevInput = () => {
    const { activeInput } = this.state;
    this.focusInput(activeInput - 1);
  };

  // Change OTP value at focused input
  public changeCodeAtFocus = (value: string) => {
    const { activeInput, otp } = this.state;
    otp[activeInput] = value;

    this.setState({
      otp,
    });
    this.getOtp();
  };

  // Handle pasted OTP
  public handleOnPaste = (e: any) => {
    e.preventDefault();
    const { numInputs } = this.props;
    const { activeInput, otp } = this.state;

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = e.clipboardData
      .getData('text/plain')
      .slice(0, numInputs - activeInput)
      .split('');

    // Paste data from focused input onwards
    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift();
      }
    }

    this.setState({
      otp,
    });

    this.getOtp();
  };

  public handleOnChange = (e: any) => {
    const { activeInput, inputRefs } = this.state;

    const element = inputRefs[activeInput].current;

    if (element && e.target !== element.input) {
      // Received event for different input
      // Fix for older browsers
      return;
    }

    this.changeCodeAtFocus(e.target.value);
    this.focusNextInput();
  };

  // Handle cases of backspace, delete, left arrow, right arrow
  public handleOnKeyDown = (e: any) => {
    switch (e.keyCode) {
      case BACKSPACE:
        e.preventDefault();
        this.changeCodeAtFocus('');
        this.focusPrevInput();
        break;
      case DELETE:
        e.preventDefault();
        this.changeCodeAtFocus('');
        break;
      case LEFT_ARROW:
        e.preventDefault();
        this.focusPrevInput();
        break;
      case RIGHT_ARROW:
        e.preventDefault();
        this.focusNextInput();
        break;
      default:
        break;
    }
  };

  public renderInputs = () => {
    const { activeInput, otp } = this.state;
    const {
      numInputs,
      inputStyle,
      focusStyle,
      separator,
      isDisabled,
      disabledStyle,
      hasErrored,
      errorStyle,
      shouldAutoFocus,
      isInputNum,
    } = this.props;
    const inputs = [];

    for (let i = 0; i < numInputs; i++) {
      inputs.push(
        // @ts-ignore
        <SingleOtpInput
          key={i}
          focus={activeInput === i}
          value={otp && otp[i]}
          onChange={this.handleOnChange}
          onKeyDown={this.handleOnKeyDown}
          onPaste={this.handleOnPaste}
          onFocus={e => {
            this.setState({
              activeInput: i,
            });
            e.target.select();
          }}
          ref={this.state.inputRefs[i]}
          onBlur={() => this.setState({ activeInput: -1 })}
          separator={separator}
          inputStyle={inputStyle}
          focusStyle={focusStyle}
          isLastChild={i === numInputs - 1}
          isDisabled={isDisabled}
          disabledStyle={disabledStyle}
          hasErrored={hasErrored}
          errorStyle={errorStyle}
          shouldAutoFocus={shouldAutoFocus}
          isInputNum={isInputNum}
        />
      );
    }

    return inputs;
  };

  public render() {
    const { containerStyle } = this.props;

    return (
      <div
        style={Object.assign(
          { display: 'flex' },
          isStyleObject(containerStyle) && containerStyle
        )}
        className={!isStyleObject(containerStyle) ? containerStyle : null}
      >
        {this.renderInputs()}
      </div>
    );
  }
}

export default OtpInput;
