import * as React from 'react';
import classnames from 'classnames';
import { Formik, Form, Field } from 'formik';
import Yup from 'yup';
import { Environment } from 'relay-runtime';
import moment, { Moment } from 'moment';
import Spinner from 'react-spinkit';

import OtpField from '../../../components/otpField';
import ResetUserPasswordSubmitPinMutation from '../mutations/ResetUserPasswordSubmitPinMutation';
import RelayContext from '../../../contexts/RelayContext';
import styles from './ResetUserPassword.module.css';
import bluLogo from '../../../assets/images/bluLogo.svg';
import bluKey from '../../../assets/images/blukey.svg';
import Button from '../../components/Button';
import Timer from '../../components/Timer';
import ResetUserPasswordMutation from '../mutations/ResetUserPasswordMutation';

// 5 minutes from now
const otpTimeout = { seconds: 5 * 60 };

interface Props {
  environment: Environment;
  nextStep: () => void;
  handleSubmit: (values: Values) => void;
  values: {
    cellNumber: string;
    username: string;
  };
}

interface State {
  isLoading: boolean;
  expiry: Moment;
  expired: boolean;
}

interface Values {
  username: string;
  token: string;
  OTP: string;
}

const Schema = Yup.object().shape({
  OTP: Yup.string()
    .min(5, 'OTP should be 5 characters long')
    .max(5)
    .matches(/^\d+$/, {
      message: 'OTP should not contain alphabetic characters',
      excludeEmptyString: false,
    })
    .required('OTP is a required field'),
});

class ResetUserPasswordOtp extends React.Component<Props, State> {
  public static contextType = RelayContext;

  public state: State = {
    expired: false,
    isLoading: false,
    expiry: moment().add(otpTimeout),
  };

  public resendOtp = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      await ResetUserPasswordMutation(this.props.environment, {
        input: {
          username: this.props.values.username,
        },
      });

      this.setState({
        isLoading: false,
        expired: false,
        expiry: moment().add(otpTimeout),
      });
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      alert('Failed to Resend OTP');
    }
  };

  public render() {
    return (
      <div className={styles.loginBody}>
        <img src={bluLogo} alt="" className="body-corner-logo-top" />
        <div className={styles.registerContainer}>
          <div className={styles.image}>
            <img src={bluKey} alt="" className={styles.logo} />
          </div>
          <p className={styles.text}>
            Please enter the ONE TIME PIN that was sent to{' '}
            <strong>
              {this.props.values.cellNumber.replace(
                this.props.values.cellNumber.substr(3, 5),
                '*****'
              )}
            </strong>{' '}
            to verify your account below.
            <br />
            <br />
            If you do not have a cellphone number registered against your
            account or your number has changed please update your details via
            the call centre on 0800 014 942.
          </p>
          <Formik<Values>
            initialValues={{
              username: this.props.values.username,
              token: '',
              OTP: '',
              cellNumber: this.props.values.cellNumber,
            }}
            validationSchema={Schema}
            onSubmit={async (
              values,
              { setStatus, setSubmitting, setFieldError }
            ) => {
              try {
                setStatus('');
                const data = await ResetUserPasswordSubmitPinMutation(
                  this.props.environment,
                  {
                    input: {
                      username: this.props.values.username,
                      cellphoneNumber: this.props.values.cellNumber,
                      pin: values.OTP,
                    },
                  }
                );

                const token = data.userResetPasswordSubmitPin!.token;

                this.props.nextStep();
                this.props.handleSubmit({
                  ...values,
                  token,
                });
              } catch (e) {
                setSubmitting(false);
                setFieldError('OTP', 'Invalid OTP');
                setStatus(e.message || 'OTP failed');
              }
            }}
            render={({ handleSubmit, isSubmitting }) => {
              return (
                <Form
                  autoComplete="nope"
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      handleSubmit(e);
                    }
                  }}
                >
                  <div className="input-container">
                    <Field
                      name="OTP"
                      label="One Time Pin *"
                      placeholder="12345"
                      component={OtpField}
                      disabled={isSubmitting}
                    />
                    <Timer
                      expiry={this.state.expiry}
                      handleExpired={() => this.setState({ expired: true })}
                    />
                  </div>

                  <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting || this.state.expired}
                    text="SUBMIT OTP"
                  />
                </Form>
              );
            }}
          />
          <div
            className={classnames(styles.title, styles.resendOtp)}
            onClick={() => this.resendOtp()}
          >
            {this.state.isLoading ? (
              <Spinner
                name="circle"
                color="blue"
                fadeIn="none"
                className={styles.spinner}
              />
            ) : (
              'Resend One-Time Pin'
            )}
          </div>
          <p className={styles.helpMessage}>
            Need help? Call centre: 0800 014 942
          </p>
        </div>
      </div>
    );
  }
}

export default ResetUserPasswordOtp;
