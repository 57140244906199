/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type UserResetPasswordInput = {
    readonly username: string;
    readonly clientMutationId?: string | null;
};
export type ResetUserPasswordMutationVariables = {
    readonly input: UserResetPasswordInput;
};
export type ResetUserPasswordMutationResponse = {
    readonly userResetPassword: ({
        readonly clientMutationId: string | null;
        readonly cellNumber: string;
    }) | null;
};
export type ResetUserPasswordMutation = {
    readonly response: ResetUserPasswordMutationResponse;
    readonly variables: ResetUserPasswordMutationVariables;
};



/*
mutation ResetUserPasswordMutation(
  $input: UserResetPasswordInput!
) {
  userResetPassword(input: $input) {
    clientMutationId
    cellNumber
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UserResetPasswordInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "userResetPassword",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UserResetPasswordInput!"
      }
    ],
    "concreteType": "UserResetPasswordPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cellNumber",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ResetUserPasswordMutation",
  "id": null,
  "text": "mutation ResetUserPasswordMutation(\n  $input: UserResetPasswordInput!\n) {\n  userResetPassword(input: $input) {\n    clientMutationId\n    cellNumber\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ResetUserPasswordMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ResetUserPasswordMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = 'eb1e0ef46926a5c899319f9095e07af0';
export default node;
