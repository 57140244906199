/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type PleaseCallMe_viewer$ref = any;
export type PleaseCallMe_viewer = {
    readonly pleaseCallMeConfig: ReadonlyArray<{
        readonly name: string;
    }> | null;
    readonly " $refType": PleaseCallMe_viewer$ref;
};



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "PleaseCallMe_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "pleaseCallMeConfig",
      "storageKey": null,
      "args": null,
      "concreteType": "PleaseCallMeConfig",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = '466a4d97f1fa5b46952c0088f78716c2';
export default node;
