/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
type NotificationBar_viewer$ref = any;
type PrivateRoute_viewer$ref = any;
type sidebarMenu_viewer$ref = any;
export type RoutesQueryVariables = {};
export type RoutesQueryResponse = {
    readonly viewer: {
        readonly user: ({
            readonly roles: ReadonlyArray<{
                readonly name: string;
            }>;
        }) | null;
        readonly " $fragmentRefs": sidebarMenu_viewer$ref & PrivateRoute_viewer$ref & NotificationBar_viewer$ref;
    };
};
export type RoutesQuery = {
    readonly response: RoutesQueryResponse;
    readonly variables: RoutesQueryVariables;
};



/*
query RoutesQuery {
  viewer {
    ...sidebarMenu_viewer
    ...PrivateRoute_viewer
    ...NotificationBar_viewer
    user {
      roles {
        name
        id
      }
      id
    }
  }
}

fragment sidebarMenu_viewer on Viewer {
  notifications {
    unreadBroadcastNotifications
    id
  }
  user {
    username
    entities {
      accountName
      id
    }
    roles {
      name
      id
    }
    isHub
    id
  }
}

fragment PrivateRoute_viewer on Viewer {
  user {
    roles {
      name
      id
    }
    id
  }
}

fragment NotificationBar_viewer on Viewer {
  broadcastNotifications(first: 3, read: false, cancelled: false, expired: false, delivered: true) {
    total
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        read {
          createdAt
        }
        broadcast {
          message
          title
          id
        }
        __typename
      }
      cursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "cancelled",
    "value": false,
    "type": "Boolean"
  },
  {
    "kind": "Literal",
    "name": "delivered",
    "value": true,
    "type": "Boolean"
  },
  {
    "kind": "Literal",
    "name": "expired",
    "value": false,
    "type": "Boolean"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 3,
    "type": "Int"
  },
  {
    "kind": "Literal",
    "name": "read",
    "value": false,
    "type": "Boolean"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "RoutesQuery",
  "id": null,
  "text": "query RoutesQuery {\n  viewer {\n    ...sidebarMenu_viewer\n    ...PrivateRoute_viewer\n    ...NotificationBar_viewer\n    user {\n      roles {\n        name\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment sidebarMenu_viewer on Viewer {\n  notifications {\n    unreadBroadcastNotifications\n    id\n  }\n  user {\n    username\n    entities {\n      accountName\n      id\n    }\n    roles {\n      name\n      id\n    }\n    isHub\n    id\n  }\n}\n\nfragment PrivateRoute_viewer on Viewer {\n  user {\n    roles {\n      name\n      id\n    }\n    id\n  }\n}\n\nfragment NotificationBar_viewer on Viewer {\n  broadcastNotifications(first: 3, read: false, cancelled: false, expired: false, delivered: true) {\n    total\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        read {\n          createdAt\n        }\n        broadcast {\n          message\n          title\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "RoutesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "viewer",
        "name": "__viewer_viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "sidebarMenu_viewer",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "PrivateRoute_viewer",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "NotificationBar_viewer",
            "args": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "roles",
                "storageKey": null,
                "args": null,
                "concreteType": "Role",
                "plural": true,
                "selections": [
                  v0
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "RoutesQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "notifications",
            "storageKey": null,
            "args": null,
            "concreteType": "ViewerNotifications",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "unreadBroadcastNotifications",
                "args": null,
                "storageKey": null
              },
              v1
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "username",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "entities",
                "storageKey": null,
                "args": null,
                "concreteType": "Entity",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "accountName",
                    "args": null,
                    "storageKey": null
                  },
                  v1
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "roles",
                "storageKey": null,
                "args": null,
                "concreteType": "Role",
                "plural": true,
                "selections": [
                  v0,
                  v1
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "isHub",
                "args": null,
                "storageKey": null
              },
              v1
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "broadcastNotifications",
            "storageKey": "broadcastNotifications(cancelled:false,delivered:true,expired:false,first:3,read:false)",
            "args": v2,
            "concreteType": "BroadcastNotificationConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "total",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "BroadcastNotificationEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BroadcastNotification",
                    "plural": false,
                    "selections": [
                      v1,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "read",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BroadcastNotificationRead",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "createdAt",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "broadcast",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Broadcast",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "message",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                          },
                          v1
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "broadcastNotifications",
            "args": v2,
            "handle": "connection",
            "key": "NotificationBar_broadcastNotifications",
            "filters": [
              "read",
              "cancelled",
              "expired",
              "delivered"
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "viewer",
        "args": null,
        "handle": "viewer",
        "key": "",
        "filters": null
      }
    ]
  }
};
})();
(node as any).hash = 'ddd5b655ef66f887816cc650b8a05294';
export default node;
