import React from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/images/close.svg';

import styles from './Tour.module.css';
import { TourNav } from './components/TourNav/TourNav';
import { TourItem } from './components/TourItem/TourItem';
import { tourMobileDataStore } from './TourMobile.data';
import { tourDesktopDataStore } from './TourDesktop.data';
import DesktopOnly from '../../../components/DesktopOnly';

interface State {
  selectedItem: number;
}

interface Props {
  userTourComplete: () => void;
  isOpen: boolean;
}

class Tour extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedItem: 0,
    };
  }
  public componentDidMount() {
    const tourContainer = document.getElementsByClassName(
      styles.tourContainer
    )[0];

    if (tourContainer) {
      tourContainer.classList.add(styles.openTour);
    }
  }

  public onPrev = () => {
    this.setState(prevState => ({
      selectedItem: prevState.selectedItem - 1,
    }));
  };

  public onNext = () => {
    this.setState(prevState => ({
      selectedItem: prevState.selectedItem + 1,
    }));
  };

  public closeTour = () => {
    const tourContainer = document.getElementsByClassName(
      styles.tourContainer
    )[0];
    if (tourContainer) {
      tourContainer.classList.add(styles.closeTour);
      tourContainer.classList.remove(styles.openTour);

      setTimeout(() => {
        this.props.userTourComplete();
      }, 360);
    }
  };

  public render() {
    const { selectedItem } = this.state;
    const { isOpen } = this.props;

    return (
      <div style={{ display: isOpen ? 'block' : 'none' }}>
        <div className={styles.tourContainer}>
          <span className={styles.close} onClick={this.closeTour}>
            <CloseIcon />
          </span>
          {selectedItem === 0 ? (
            <>
              <div className={styles.tourItem}>
                <div className={styles.welcome}>
                  <div className={styles.logo}>
                    <img src={require('../../../assets/images/blukey.svg')} />
                    <h3 className={styles.heading}>Welcome</h3>
                    <p>
                      This is a quick walkthrough of the application. Click next
                      to learn more.
                    </p>
                  </div>
                </div>
                <span>
                  <TourNav
                    onNext={this.onNext}
                    onPrev={this.onPrev}
                    selectedItem={selectedItem}
                    tourLastStep={tourMobileDataStore.length}
                  />
                </span>
              </div>
            </>
          ) : selectedItem === 1 ? (
            <div className={styles.tourItemContainer}>
              {window.innerWidth < 1024 &&
                tourMobileDataStore[selectedItem - 1].map(tourData => (
                  <TourItem
                    key={tourData.imageName}
                    heading={tourData.heading}
                    caption={tourData.caption}
                    image={tourData.imageName}
                    explanations={tourData.explanations}
                  />
                ))}
              <DesktopOnly
                children={tourDesktopDataStore[selectedItem - 1].map(
                  tourData => (
                    <TourItem
                      key={tourData.imageName}
                      heading={tourData.heading}
                      caption={tourData.caption}
                      image={tourData.imageName}
                      explanations={tourData.explanations}
                    />
                  )
                )}
              />
              <span>
                <TourNav
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  selectedItem={selectedItem}
                  tourLastStep={tourMobileDataStore.length}
                />
              </span>
            </div>
          ) : selectedItem === 2 ? (
            <div className={styles.tourItemContainer}>
              {window.innerWidth < 1024 &&
                tourMobileDataStore[selectedItem - 1].map((tourData, index) => (
                  <TourItem
                    key={tourData.imageName}
                    heading={tourData.heading}
                    caption={tourData.caption}
                    image={tourData.imageName}
                    explanations={tourData.explanations}
                    index={index}
                  />
                ))}
              <DesktopOnly
                children={tourDesktopDataStore[selectedItem - 1].map(
                  (tourData, index) => (
                    <TourItem
                      key={tourData.imageName}
                      heading={tourData.heading}
                      caption={tourData.caption}
                      image={tourData.imageName}
                      explanations={tourData.explanations}
                      index={index}
                    />
                  )
                )}
              />
              <TourNav
                onNext={this.onNext}
                onPrev={this.onPrev}
                selectedItem={selectedItem}
                tourLastStep={tourMobileDataStore.length}
              />
            </div>
          ) : selectedItem === 3 ? (
            <div className={styles.tourItemContainer}>
              {window.innerWidth < 1024 &&
                tourMobileDataStore[selectedItem - 1].map(tourData => (
                  <TourItem
                    key={tourData.imageName}
                    heading={tourData.heading}
                    caption={tourData.caption}
                    image={tourData.imageName}
                    explanations={tourData.explanations}
                  />
                ))}
              <DesktopOnly
                children={tourDesktopDataStore[selectedItem - 1].map(
                  tourData => (
                    <TourItem
                      key={tourData.imageName}
                      heading={tourData.heading}
                      caption={tourData.caption}
                      image={tourData.imageName}
                      explanations={tourData.explanations}
                    />
                  )
                )}
              />
              <span>
                <TourNav
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  selectedItem={selectedItem}
                  tourLastStep={tourMobileDataStore.length}
                />
              </span>
            </div>
          ) : selectedItem === 5 ? (
            <div className={styles.tourItemContainer}>
              {window.innerWidth < 1024 &&
                tourMobileDataStore[selectedItem - 1].map(tourData => (
                  <TourItem
                    key={tourData.imageName}
                    heading={tourData.heading}
                    caption={tourData.caption}
                    image={tourData.imageName}
                    explanations={tourData.explanations}
                  />
                ))}
              <DesktopOnly
                children={tourDesktopDataStore[selectedItem - 1].map(
                  tourData => (
                    <TourItem
                      key={tourData.imageName}
                      heading={tourData.heading}
                      caption={tourData.caption}
                      image={tourData.imageName}
                      explanations={tourData.explanations}
                    />
                  )
                )}
              />
              <span>
                <TourNav
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  selectedItem={selectedItem}
                  tourLastStep={tourMobileDataStore.length}
                />
              </span>
            </div>
          ) : selectedItem === 4 ? (
            <div className={styles.tourItemContainer}>
              {(window.innerWidth > 1024 && (
                <DesktopOnly
                  children={tourDesktopDataStore[selectedItem - 1].map(
                    tourData => (
                      <TourItem
                        key={tourData.imageName}
                        heading={tourData.heading}
                        caption={tourData.caption}
                        image={tourData.imageName}
                        explanations={tourData.explanations}
                      />
                    )
                  )}
                />
              )) ||
                tourMobileDataStore[selectedItem - 1].map(tourData => (
                  <TourItem
                    key={tourData.imageName}
                    heading={tourData.heading}
                    caption={tourData.caption}
                    image={tourData.imageName}
                    explanations={tourData.explanations}
                  />
                ))}
              <span>
                <TourNav
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  selectedItem={selectedItem}
                  tourLastStep={tourMobileDataStore.length}
                />
              </span>
            </div>
          ) : selectedItem === 6 ? (
            <div className={styles.tourItemContainer}>
              {(window.innerWidth > 1024 && (
                <DesktopOnly
                  children={tourDesktopDataStore[selectedItem - 1].map(
                    tourData => (
                      <TourItem
                        key={tourData.imageName}
                        heading={tourData.heading}
                        caption={tourData.caption}
                        image={tourData.imageName}
                        explanations={tourData.explanations}
                      />
                    )
                  )}
                />
              )) ||
                tourMobileDataStore[selectedItem - 1].map(tourData => (
                  <TourItem
                    key={tourData.imageName}
                    heading={tourData.heading}
                    caption={tourData.caption}
                    image={tourData.imageName}
                    explanations={tourData.explanations}
                  />
                ))}
              <span>
                <TourNav
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  selectedItem={selectedItem}
                  tourLastStep={tourMobileDataStore.length}
                />
              </span>
            </div>
          ) : (
            this.closeTour()
          )}
        </div>
      </div>
    );
  }
}

export { Tour };
