/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type EmailUserDetailsChangeRequestMutationInput = {
    readonly email: string;
    readonly accountNumber: string;
    readonly currentRegion: string;
    readonly newRegion: string;
    readonly currentCell: string;
    readonly newCell: string;
    readonly clientMutationId?: string | null;
};
export type ChangeDetailsMutationVariables = {
    readonly input: EmailUserDetailsChangeRequestMutationInput;
};
export type ChangeDetailsMutationResponse = {
    readonly emailUserDetailsChange: ({
        readonly clientMutationId: string | null;
        readonly accepted: ReadonlyArray<string> | null;
        readonly rejected: ReadonlyArray<string> | null;
    }) | null;
};
export type ChangeDetailsMutation = {
    readonly response: ChangeDetailsMutationResponse;
    readonly variables: ChangeDetailsMutationVariables;
};



/*
mutation ChangeDetailsMutation(
  $input: EmailUserDetailsChangeRequestMutationInput!
) {
  emailUserDetailsChange(input: $input) {
    clientMutationId
    accepted
    rejected
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "EmailUserDetailsChangeRequestMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "emailUserDetailsChange",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "EmailUserDetailsChangeRequestMutationInput!"
      }
    ],
    "concreteType": "EmailUserDetailsChangeRequestMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "accepted",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rejected",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ChangeDetailsMutation",
  "id": null,
  "text": "mutation ChangeDetailsMutation(\n  $input: EmailUserDetailsChangeRequestMutationInput!\n) {\n  emailUserDetailsChange(input: $input) {\n    clientMutationId\n    accepted\n    rejected\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ChangeDetailsMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ChangeDetailsMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = '7461b0d9d0b65d35df5a942449df5719';
export default node;
