/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type SelfRegistrationStartInput = {
    readonly entityReference: string;
    readonly cellphoneNumber: string;
    readonly clientMutationId?: string | null;
};
export type SelfRegistrationStartMutationVariables = {
    readonly input: SelfRegistrationStartInput;
};
export type SelfRegistrationStartMutationResponse = {
    readonly selfRegistrationStart: ({
        readonly clientMutationId: string | null;
        readonly cellphoneNumber: string;
    }) | null;
};
export type SelfRegistrationStartMutation = {
    readonly response: SelfRegistrationStartMutationResponse;
    readonly variables: SelfRegistrationStartMutationVariables;
};



/*
mutation SelfRegistrationStartMutation(
  $input: SelfRegistrationStartInput!
) {
  selfRegistrationStart(input: $input) {
    clientMutationId
    cellphoneNumber
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SelfRegistrationStartInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "selfRegistrationStart",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "SelfRegistrationStartInput!"
      }
    ],
    "concreteType": "SelfRegistrationStartPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "cellphoneNumber",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SelfRegistrationStartMutation",
  "id": null,
  "text": "mutation SelfRegistrationStartMutation(\n  $input: SelfRegistrationStartInput!\n) {\n  selfRegistrationStart(input: $input) {\n    clientMutationId\n    cellphoneNumber\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SelfRegistrationStartMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "SelfRegistrationStartMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = '2de581f2e55228526abee6a1a3d0e9f2';
export default node;
