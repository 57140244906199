import React from 'react';
import zxcvbn from 'zxcvbn';
import styles from './PasswordStrength.module.css';
interface Props {
  password: string;
}

class PasswordStrengthMeter extends React.Component<Props, {}> {
  public createPasswordLabel = result => {
    switch (result.score) {
      case 0:
        return 'Weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    }
  };
  public render() {
    const { password } = this.props;
    const testedResult = zxcvbn(password);
    return (
      <div className={styles.passwordStrengthMeter}>
        <br />
        <label className={styles.passwordStrengthMeterLabel}>
          <strong className={styles.Strength}>Password strength:</strong>{' '}
          <b className={styles[this.createPasswordLabel(testedResult)]}>
            {this.createPasswordLabel(testedResult)}
          </b>
        </label>
      </div>
    );
  }
}

export default PasswordStrengthMeter;
