/* tslint:disable no-any */
import React, { useState } from 'react';
import Spinner from 'react-spinkit';
import styles from './DashboardConfirmHubDetails.module.css';
import closeIcon from '../../../assets/images/close-blue.svg';
import ReactTable from 'react-table';
import { Environment } from 'relay-runtime';
import blukey from '../../../assets/images/blukey.svg';
import { DropdownList } from 'react-widgets';
import { ChangeDetailsRequest } from '../mutations/ChangeDetailsMutation';

interface Props {
  contacts: ReadonlyArray<{
    contactName: string;
    regionName: string | null;
    cellNumber: string;
    spoke: { accountName: string };
  }> | null;
  onClose: () => void;
  environment: Environment;
  email: string;
}

export const DashboardConfirmHubDetails = ({
  contacts,
  onClose,
  environment,
  email,
}: Props) => {
  const [editing, setEditing] = useState(false);
  const [details, setDetails] = useState({
    name: '',
    region: '',
    contact: '',
    accountName: '',
    oldRegion: '',
    oldContact: '',
  });
  const [loading, setLoading] = useState(false);

  const columnDefaults = {
    headerClassName: 'header-title',
    width: window.innerWidth < 1023 ? 250 : undefined,
    Cell: (d: any) => (
      <div className={`table-cell`}>{d.value || 'Not Specified'}</div>
    ),
  };

  const columns = [
    {
      Header: 'Contact Name',
      accessor: 'contactName',
      ...columnDefaults,
    },
    {
      Header: 'Region',
      accessor: 'regionName',
      ...columnDefaults,
    },
    {
      Header: 'Cellphone number',
      accessor: 'cellNumber',
      ...columnDefaults,
    },
    {
      Header: 'Change Details',
      accessor: 'spoke',
      Cell: ({ row }) => (
        <button
          className={styles.editButton}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            setDetails({
              name: row.contactName,
              region: row.regionName,
              oldRegion: row.regionName,
              contact: row.cellNumber,
              oldContact: row.cellNumber,
              accountName: row.spoke ? row.spoke.accountName : '-',
            });
            setEditing(true);
          }}
        >
          Edit
        </button>
      ),
      headerClassName: 'header-title',
      width: window.innerWidth < 1023 ? 250 : undefined,
      // ...columnDefaults,
    },
  ];

  const data = contacts ? contacts.slice() : [];
  const regionOptions = [
    'Cape Town',
    'Northern Cape',
    'Western Cape',
    'KwaZulu-Natal',
    'Free State',
    'Gauteng',
    'North West',
    'Mpumalanga',
    'Limpopo',
  ];

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        {editing && (
          <div>
            <div
              className={styles.editRoot}
              role="button"
              tabIndex={0}
              onClick={() => setEditing(false)}
            >
              <div
                className={styles.confirmBoxBody}
                role="button"
                tabIndex={0}
                onClick={e => e.stopPropagation()}
              >
                <img className={styles.logo} src={blukey} alt="" />
                <p className={styles.title}>{details.name}</p>
                <DropdownList
                  defaultValue={details.region || 'Select Region'}
                  data={regionOptions}
                  onChange={value => setDetails({ ...details, region: value })}
                  containerClassName={styles.dropdown}
                />
                <input
                  className={styles.editInput}
                  value={details.contact}
                  placeholder={'Contact number'}
                  onChange={e =>
                    setDetails({ ...details, contact: e.target.value })
                  }
                />
                <button
                  className={styles.submitButton}
                  disabled={loading}
                  onClick={async () => {
                    setLoading(true);
                    await ChangeDetailsRequest(environment, {
                      input: {
                        email,
                        accountNumber: details.accountName,
                        currentRegion: details.oldRegion || '-',
                        newRegion: details.region || '-',
                        currentCell: details.oldContact || '-',
                        newCell: details.contact || '-',
                      },
                    });
                    setLoading(false);
                    setEditing(false);
                  }}
                >
                  {loading ? (
                    <Spinner
                      color={'white'}
                      fadeIn="none"
                      name="circle"
                      className={styles.spinner}
                    />
                  ) : (
                    'SUBMIT UPDATE REQUEST'
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
        <div className={styles.header}>
          <h2 className={styles.title}>Spoke details</h2>
          <p>
            Are the following Spokes' details correct? If not, please edit the
            details and submit the changes to the CIC.
          </p>
          <img
            src={closeIcon}
            className={styles.closeIcon}
            role="button"
            tabIndex={0}
            onClick={onClose}
          />
        </div>
        <div className={styles.content}>
          <ReactTable
            // Only mark loading for a single table because it overlays the entire screen
            loading={!contacts}
            className="user-table -striped"
            data={data}
            columns={columns}
            pageSize={10}
            showPagination={true}
            showPageJump={false}
            showPageSizeOptions={false}
            minRows={1}
            noDataText="No Data Found"
          />
        </div>
      </div>
    </div>
  );
};
