export const numberFormat = (num: number | string) => {
  const numStr = num.toString();
  const numSplit = numStr.split('.');
  let decimal = numSplit[1] || '00';
  if (decimal.length === 1) {
    decimal = decimal + '0';
  }

  return numSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + `.${decimal}`;
};
