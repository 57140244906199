/* tslint:disable no-any */
import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory as createHistory } from 'history';
import bugsnag from 'bugsnag-js';
import createPlugin from 'bugsnag-react';
import { Environment, RecordSource, Store } from 'relay-runtime';
import { ConfigService } from '@stackworx/react';

import createNetworkLayer from './createNetworkLayer';
import { store } from './store';

import Routes from './Routes';

import { library } from '@fortawesome/fontawesome-svg-core';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStroopwafel,
  faCaretUp,
  faCaretSquareUp,
} from '@fortawesome/free-solid-svg-icons';

import AuthContext, { AuthContextType } from './contexts/AuthContext';
import RelayContext, { RelayState } from './contexts/RelayContext';
import LoadingMask from './modules/components/LoadingMask';
import Subscriptions from './modules/components/Subscriptions';
import UnreadNotificationSubscription from './modules/components/UnreadNotificationsSubscription';

library.add(faStroopwafel, faCaretUp, faCaretSquareUp);

const bugsnagClient = bugsnag({
  apiKey: '005d775d977ff9bd9b8286885825d0c0',
  notifyReleaseStages: ['production', 'staging'],
  releaseStage: ConfigService.stage,
});

interface Props {
  auth: AuthContextType;
}

interface State {
  auth: AuthContextType;
  environment: RelayState;
  loading: boolean;
}

class App extends Component<Props, State> {
  private history = createHistory();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      auth: props.auth,
      environment: this.createEnvironment(
        props.auth.authenticated ? props.auth.token : ''
      ),
    };

    bugsnagClient.user = {
      id: '-1',
      name: 'Unlogged user',
      email: 'fake@email.nope',
    };
  }

  public componentDidMount() {
    this.setState({ loading: false });
  }

  public handleLogin = async ({
    username,
    token,
  }: {
    username: string;
    token: string;
  }) => {
    bugsnagClient.user = { username };

    store.dispatch({
      payload: { username, token },
      type: 'LOGIN',
    });

    return new Promise(resolve => {
      this.setState(
        {
          auth: { authenticated: true, token, username },
        },
        () => {
          this.saveAuth();
          this.setState({ environment: this.createEnvironment(token) });
          resolve();
        }
      );
    });
  };

  public handleLogout = () => {
    delete bugsnagClient.user;

    store.dispatch({
      type: 'LOGOUT',
    });

    this.setState({ auth: { authenticated: false } }, () => {
      this.saveAuth();
      // Empty token
      this.setState({ environment: this.createEnvironment('') });
    });

    this.history.push('/login');
  };

  public render() {
    const { environment, auth, loading } = this.state;
    const ErrorBoundary = bugsnagClient.use(createPlugin(React));

    return (
      <div>
        {loading ? (
          <LoadingMask />
        ) : (
          <Provider store={store}>
            <AuthContext.Provider value={auth}>
              <RelayContext.Provider value={environment}>
                <ErrorBoundary>
                  <Router history={this.history}>
                    <Routes
                      handleLogin={this.handleLogin}
                      handleLogout={this.handleLogout}
                      isLoggedIn={this.state.auth.authenticated}
                    />
                  </Router>
                  <Subscriptions />
                  <UnreadNotificationSubscription />
                </ErrorBoundary>
              </RelayContext.Provider>
            </AuthContext.Provider>
          </Provider>
        )}
      </div>
    );
  }

  private saveAuth() {
    if (localStorage) {
      localStorage.setItem('auth', JSON.stringify(this.state.auth));
    }
  }

  private createEnvironment(token: string) {
    const handlerProvider = undefined;

    const network = createNetworkLayer({
      logout: () => {
        this.handleLogout();
      },
      token,
    });

    const source = new RecordSource();
    const relayStore = new Store(source);

    return new Environment({
      handlerProvider,
      network,
      store: relayStore,
    });
  }
}

export default App;
