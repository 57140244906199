import * as React from 'react';
import { FieldProps, getIn } from 'formik';

interface Props extends FieldProps {
  label: string;
  placeholder: string;
  type?: string;
  disabled?: boolean;
  disableAutocomplete?: boolean;
  readOnly?: boolean;
  classname?: string;
  maxLength?: number;
  preventSpace?: boolean;
  /* tslint:disable-next-line */
  handleChange?: (e: any) => void;
}

interface State {
  readOnly: boolean;
  defaultClassName: string;
}

export default class TextField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Start fields as readonly to disable autocomplete
    this.state = {
      readOnly: props.disableAutocomplete || false,
      defaultClassName: props.classname
        ? props.classname
        : 'add-user-input-container',
    };
  }

  public componentDidMount() {
    const { disableAutocomplete, readOnly = false } = this.props;

    if (disableAutocomplete) {
      setTimeout(() => {
        this.setState({ readOnly });
      }, 500);
    }
  }

  public componentWillReceiveProps() {
    const { readOnly = false } = this.props;

    this.setState({ readOnly });
  }

  public render() {
    const {
      maxLength,
      type = 'text',
      field,
      label,
      placeholder,
      disabled = false,
      form: { touched, errors },
    } = this.props;
    const { name, onChange } = field;

    const { readOnly } = this.state;

    // const error = touched[name] && errors[name];
    const error = getIn(touched, name) && getIn(errors, name);
    return (
      <div className={this.state.defaultClassName}>
        <p className="label">{label}</p>
        <input
          {...field}
          placeholder={placeholder}
          className="add-user-text-input"
          type={type}
          style={
            error
              ? { border: '1px solid red', boxSizing: 'border-box' }
              : undefined
          }
          autoComplete="off"
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          onChange={e => {
            onChange(e);
            if (this.props.handleChange) {
              this.props.handleChange(e);
            }
          }}
        />
        {error && <div>{error}</div>}
      </div>
    );
  }
}
