/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type EntityCombobox_viewer$ref = any;
export type EntityCombobox_viewer = {
    readonly linkedEntities: ReadonlyArray<{
        readonly type: string;
        readonly name: string | null;
        readonly account: string | null;
    }>;
    readonly " $refType": EntityCombobox_viewer$ref;
};



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "EntityCombobox_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "entityReference",
      "type": "String!"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "linkedEntities",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "entityReference",
          "variableName": "entityReference",
          "type": "String!"
        }
      ],
      "concreteType": "LinkedEntity",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "name",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "account",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = '38dd026ee34520f4c39864e83a6a61bf';
export default node;
