import { createRefetchContainer, RelayRefetchProp } from 'react-relay';
import ReactMoment from 'react-moment';
import graphql from 'babel-plugin-relay/macro';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Environment } from 'relay-runtime';
import moment from 'moment';
import { ConfigService } from '@stackworx/react';

import DashboardDataViewer from './components/DashboardDataViewer';
import DashboardViewDataModal from './components/DashboardViewDataModal';
import DefaultEntityContext from '../../contexts/DefaultEntityContext';
import AuthenticatedAuthContext from '../../contexts/AuthenticatedAuthContext';
import EntityController from '../EntityController/EntityController';
import RelayRenderer from '../RelayRenderer';
import { Dashboard_viewer } from './__generated__/Dashboard_viewer.graphql';
import { Tour } from './Tour/Tour';
import { TourComplete } from './mutations/TourComplete';
import { LastPromptUpdate } from './mutations/LastPromptUpdateMutation';
import { firebaseAnalyticsService } from '../../firebaseSetup';
import { DashboardConfirmDetailsModal } from './components/DashboardConfirmDetailsModal';
import { DashboardConfirmHubDetails } from './components/DashboardConfirmHubDetails';
import footerLogo from '../../assets/images/footer2.png';
import DataViewerStyles from './components/DashboardDataViewer.module.css';

interface Props extends RouteComponentProps {
  defaultEntityReference: string;
  open: boolean;
  viewer: Dashboard_viewer;
  authToken: string;
  environment: Environment;
  relay: RelayRefetchProp;
}

interface State {
  dataModalVisible: boolean;
  lastUpdate: Date;
  error?: Error;
  open: boolean;
  intervalId?: number;
  isLoading: boolean;
  entityReference: string;
  subEntityReference: string | null;
  categoryType: string | null;
  hasCompletedTour: boolean;
  confirmBoxOpen: boolean;
  hubConfirmBoxOpen: boolean;
}

class Dashboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    let hasCompletedTour = false;
    if (props.viewer.user) {
      hasCompletedTour = Boolean(props.viewer.user.userTourComplete);
    }
    let confirmBoxOpen = false;
    if (localStorage.getItem('login') && props.viewer.user) {
      confirmBoxOpen =
        !props.viewer.user.isHub &&
        props.viewer.user.roles.length <= 1 &&
        props.viewer.user.roles.findIndex(a => a.name === 'readonly_admin') <
          0 &&
        props.viewer.user.roles.findIndex(a => a.name === 'admin') < 0;
    }

    let hubConfirmBoxOpen = false;
    if (
      localStorage.getItem('login') &&
      props.viewer.report &&
      props.viewer.user
    ) {
      hubConfirmBoxOpen =
        !!props.viewer.report.contact &&
        props.viewer.user.isHub &&
        props.viewer.user.roles.findIndex(a => a.name === 'readonly_admin') <
          0 &&
        props.viewer.user.roles.findIndex(a => a.name === 'admin') < 0;
    }

    this.state = {
      dataModalVisible: false,
      isLoading: false,
      entityReference: props.defaultEntityReference,
      subEntityReference: props.defaultEntityReference,
      confirmBoxOpen,
      hubConfirmBoxOpen,
      lastUpdate: new Date(),
      open: false,
      categoryType: '',
      hasCompletedTour,
    };
  }

  public componentDidMount() {
    const { viewer } = this.props;
    // TODO: this is broken due to a @types/node dependency in the project root
    const intervalId = (setInterval(
      () => this.refetch(),
      900000
    ) as unknown) as number;
    if (!viewer.user) {
      throw Error('No user found');
    }
    this.setState({ intervalId });
  }

  public componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  public openDataModal = async (categoryType: string) => {
    this.setState({ dataModalVisible: true, categoryType });
  };

  public refetch() {
    const { entityReference } = this.state;

    this.setState({ isLoading: true, entityReference }, () => {
      this.props.relay.refetch(
        { entityReference },
        undefined,
        (error?: Error) => {
          if (error) {
            this.setState({ error, isLoading: false });
          } else {
            this.setState({
              error: undefined,
              isLoading: false,
              lastUpdate: new Date(),
            });
          }
        }
      );
    });
  }

  public handleEntityReferenceChanged = (entityReference: string | null) => {
    if (entityReference) {
      sessionStorage.setItem('hub', entityReference);
    } else {
      sessionStorage.removeItem('hub');
    }
    sessionStorage.removeItem('spoke');
    this.setState(
      {
        entityReference: entityReference || this.props.defaultEntityReference,
        subEntityReference: entityReference,
      },
      () => this.refetch()
    );
  };

  public handleCategoryChange = (categoryType: string) => {
    this.setState({ categoryType });
  };

  public handleSubEntityReferenceChanged = (
    subEntityReference: string | null
  ) => {
    if (subEntityReference) {
      sessionStorage.setItem('spoke', subEntityReference);
    }
    this.setState({ subEntityReference });
  };

  public userTourComplete = async () => {
    const { user } = this.props.viewer;
    const { environment } = this.props;
    if (user) {
      const { userTourComplete } = await TourComplete(environment, {
        input: {
          tourCompleted: true,
          clientMutationId: '',
        },
      });
      const hasCompletedTour = (userTourComplete &&
        userTourComplete.tourCompleted) as boolean;
      this.setState({
        hasCompletedTour,
      });
    }
  };

  public handleClose = async () => {
    const { environment } = this.props;

    localStorage.removeItem('login');
    await LastPromptUpdate(environment, {
      input: {
        lastPromptTime: new Date().toISOString(),
      },
    });
    this.setState({ hubConfirmBoxOpen: false, confirmBoxOpen: false });
  };

  public checkPromptTime = () => {
    const { user } = this.props.viewer;
    if (user && user.lastPrompt) {
      if (user.lastPrompt.LastPromptTime) {
        const lastTime = moment(user.lastPrompt.LastPromptTime);
        const currentTime = moment();
        return currentTime.isAfter(lastTime.add(30, 'days'));
      } else {
        return true;
      }
    } else {
      // Last prompt is null, user has never been prompted
      return true;
    }
  };

  public render() {
    const { viewer } = this.props;
    const { hasCompletedTour, confirmBoxOpen, hubConfirmBoxOpen } = this.state;
    const promptTimeShow = this.checkPromptTime();

    let cellphoneNumber;
    let regions;
    let isHub;

    if (viewer.user) {
      cellphoneNumber = viewer.user.cellphoneNumber;
      regions = viewer.user.regions;
      isHub = viewer.user.isHub;
    }

    let entityReference: string = this.state.entityReference;
    let subEntityReference: string = this.state.entityReference;

    if (sessionStorage.getItem('hub')) {
      // @ts-ignore
      entityReference = sessionStorage.getItem('hub');
    }

    if (sessionStorage.getItem('spoke')) {
      // @ts-ignore
      subEntityReference = sessionStorage.getItem('spoke');
    }

    if (sessionStorage.getItem('hub') && !sessionStorage.getItem('spoke')) {
      // @ts-ignore
      subEntityReference = sessionStorage.getItem('hub');
    }
    firebaseAnalyticsService.logEvent('dashboard_visit', {
      content_id:
        this.props.viewer && this.props.viewer.user
          ? btoa(this.props.viewer.user.username)
          : '',
      username:
        this.props.viewer && this.props.viewer.user
          ? this.props.viewer.user.username
          : '',
      entity: this.state.entityReference,
    });

    if (
      localStorage.getItem('login') &&
      !this.state.confirmBoxOpen &&
      !this.state.hubConfirmBoxOpen
    ) {
      localStorage.removeItem('login');
    }

    return (
      <>
        {!hasCompletedTour ? (
          <Tour
            userTourComplete={this.userTourComplete}
            isOpen={!hasCompletedTour}
          />
        ) : (
          <>
            {confirmBoxOpen && promptTimeShow ? (
              <DashboardConfirmDetailsModal
                handleClose={this.handleClose}
                region={regions || undefined}
                contact={cellphoneNumber || undefined}
                isHub={isHub}
                environment={this.props.environment}
                accountNumber={this.state.entityReference}
                email={
                  ConfigService.stage === 'development'
                    ? 'blue-label-notifications@stackworx.io'
                    : 'service@blusupport.co.za'
                }
              />
            ) : hubConfirmBoxOpen && promptTimeShow ? (
              <DashboardConfirmHubDetails
                contacts={viewer.report ? viewer.report.contact : null}
                onClose={this.handleClose}
                environment={this.props.environment}
                email={
                  ConfigService.stage === 'development'
                    ? 'blue-label-notifications@stackworx.io'
                    : 'service@blusupport.co.za'
                }
              />
            ) : (
              <div className="dashboard-container">
                {this.state.dataModalVisible && this.state.categoryType && (
                  <DashboardViewDataModal
                    viewer={viewer}
                    authToken={this.props.authToken}
                    selectedEntityReference={this.props.defaultEntityReference}
                    onClose={() => {
                      this.setState({ dataModalVisible: false });
                    }}
                    isLoading={this.state.isLoading}
                    categoryType={this.state.categoryType!!}
                    handleCategoryChange={this.handleCategoryChange}
                    environment={this.props.environment}
                  />
                )}
                <div>
                  <h2
                    className="last-update"
                    style={{ textTransform: 'uppercase' }}
                  >
                    Dashboard
                  </h2>
                  <p className="last-update">
                    Data updated every 15 minutes. Last update was at{' '}
                    <ReactMoment
                      format={'HH:mm'}
                      date={this.state.lastUpdate}
                    />
                    .
                  </p>
                  <DashboardDataViewer
                    handleNegativeSummaryClick={() =>
                      this.props.history.push('/reports/negatives')
                    }
                    handleProfitSummaryClick={() =>
                      this.props.history.push('/reports/profits')
                    }
                    history={this.props.history}
                    openDataModal={this.openDataModal}
                    viewer={viewer}
                    entityReference={entityReference}
                    subEntityReference={subEntityReference}
                    error={this.state.error}
                    isLoading={this.state.isLoading}
                    isModalOpen={this.state.dataModalVisible}
                    handleEntityReferenceChanged={
                      this.handleEntityReferenceChanged
                    }
                    handleSubEntityReferenceChanged={
                      this.handleSubEntityReferenceChanged
                    }
                  />
                </div>
                <img
                  src={footerLogo}
                  alt="Corner Bottom Logo"
                  className={DataViewerStyles['corner-bottom-logo']}
                />
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

const query = graphql`
  query DashboardQuery(
    $entityReference: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    viewer {
      ...Dashboard_viewer
    }
  }
`;

const DashboardRefetchContainer = createRefetchContainer(
  Dashboard,
  {
    viewer: graphql`
      fragment Dashboard_viewer on Viewer {
        ...EntityCombobox_viewer
        ...DashboardDataViewer_viewer
        ...DashboardViewDataModal_viewer
        user {
          username
          email
          userTourComplete
          cellphoneNumber
          regions
          isHub
          roles {
            name
          }
          loginCount {
            lastLogin
            count
          }
          lastPrompt {
            id
            LastPromptTime
          }
        }
        report {
          contact(entityReference: $entityReference) {
            cellNumber
            regionName
            contactName
            spoke {
              accountName
            }
          }
        }
        users {
          edges {
            node {
              username
              regions
              cellphoneNumber
            }
          }
        }
      }
    `,
  },
  query
);

const startDate = () => {
  const date = new Date();
  const pastDate = new Date(date.setDate(date.getDate() - 30));
  return pastDate.toISOString().split('T')[0];
};

const endDate = () => {
  const date = new Date();
  return date.toISOString().split('T')[0];
};

export default props => (
  <EntityController>
    <AuthenticatedAuthContext>
      {({ token }) => (
        <DefaultEntityContext.Consumer>
          {({ entityReference }) => {
            return (
              <RelayRenderer
                query={query}
                variables={{
                  entityReference,
                  subEntityReference: entityReference,
                  search: '',
                  startDate: startDate(),
                  endDate: endDate(),
                }}
                container={DashboardRefetchContainer}
                {...props}
                authToken={token}
                defaultEntityReference={entityReference}
              />
            );
          }}
        </DefaultEntityContext.Consumer>
      )}
    </AuthenticatedAuthContext>
  </EntityController>
);
