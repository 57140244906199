import graphql from 'babel-plugin-relay/macro';
import { createMutation } from '@stackworx/relay';

import { ResetUserPasswordMutation } from './__generated__/ResetUserPasswordMutation.graphql';

const mutation = graphql`
  mutation ResetUserPasswordMutation($input: UserResetPasswordInput!) {
    userResetPassword(input: $input) {
      clientMutationId
      cellNumber
    }
  }
`;

export default createMutation<ResetUserPasswordMutation>(mutation);
