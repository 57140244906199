import * as firebase from 'firebase/app';
import 'firebase/analytics';
import { ConfigService } from '@stackworx/react';

switch (ConfigService.stage) {
  case 'development': {
    firebase.initializeApp({
      apiKey: 'AIzaSyABilVjiohbFI1qluWdPxVYNjzRt_xFFNQ',
      authDomain: 'bluelabel-merchant-portal.firebaseapp.com',
      databaseURL: 'https://bluelabel-merchant-portal.firebaseio.com',
      projectId: 'bluelabel-merchant-portal',
      storageBucket: '',
      messagingSenderId: '112826759872',
      appId: '1:112826759872:web:284dbb523d6459a0ef4b25',
      measurementId: 'G-4YMT1H0WRB',
    });
    break;
  }
  case 'qa': {
    firebase.initializeApp({
      apiKey: 'AIzaSyAfm7DPQhNgB4G0aXpLTmQh_6XrkjkHRYQ',
      authDomain: 'merchant-portal-e19e5.firebaseapp.com',
      databaseURL: 'https://merchant-portal-e19e5.firebaseio.com',
      projectId: 'merchant-portal-e19e5',
      storageBucket: 'merchant-portal-e19e5.appspot.com',
      messagingSenderId: '324635671470',
      appId: '1:324635671470:web:3d5c9ed2b3438e1209263f',
      measurementId: 'G-ME0WM54S90',
    });
    break;
  }
  case 'prod':
    firebase.initializeApp({
      apiKey: 'AIzaSyAfm7DPQhNgB4G0aXpLTmQh_6XrkjkHRYQ',
      authDomain: 'merchant-portal-e19e5.firebaseapp.com',
      databaseURL: 'https://merchant-portal-e19e5.firebaseio.com',
      projectId: 'merchant-portal-e19e5',
      storageBucket: 'merchant-portal-e19e5.appspot.com',
      messagingSenderId: '324635671470',
      appId: '1:324635671470:web:68329eb0aee35c4c09263f',
      measurementId: 'G-WZH5Q7WJLD',
    });
    break;
}
// TODO: Have service for each environment
export const firebaseAnalyticsService = firebase.analytics();
