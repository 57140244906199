import * as React from 'react';
import Media from 'react-media';

interface Props {
  children: (opts: { isMobile: boolean }) => React.ReactElement<HTMLElement>;
}

export default function MediaQuery(props: Props) {
  return (
    <Media query="(max-width: 768px)">
      {(predicate: boolean) => props.children({ isMobile: predicate })}
    </Media>
  );
}
