/* tslint:disable no-any */
import React, { Component } from 'react';
import Spinner from 'react-spinkit';
import ReactTooltip from 'react-tooltip';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { History } from 'history';

import ErrorView from '../../components/ErrorView';
import AccountSelector from '../../AccountSelector';
import EntityCombobox from '../../components/EntityCombobox';
import DashboardSummary from './DashboardSummary';
import DataBlock from './DashboardDataBlock';
import DashboardSearch from './DashboardSearch';
import { DashboardSpokePerformance } from './DashboardSpokePerformance';
import { DashboardProductsReport } from './DashboardProductsReport';
import DashboardSearchButtonSet from './DashboardSearchButtonSet';
import DashboardNegativeSummary from './DashboardNegativeSummary';
import { DashboardDataViewer_viewer } from './__generated__/DashboardDataViewer_viewer.graphql';

import DataViewerStyles from './DashboardDataViewer.module.css';
import ErrorDisplay from '../../../components/errorDisplay';
import { DashboardNewProductsModal } from './DashboardNewProductsModal';

const Selectors = ({
  isLoading,
  viewer,
  subEntityReference,
  handleEntityReferenceChanged,
  handleSubEntityReferenceChanged,
}: {
  isLoading: boolean;
  subEntityReference: string | null;
  viewer: DashboardDataViewer_viewer;
  handleEntityReferenceChanged: (entityReference: string | null) => void;
  handleSubEntityReferenceChanged: (entityReference: string | null) => void;
}) => (
  <div className="combo-boxes">
    <div className="combo-stabilizer">
      <AccountSelector
        viewer={viewer}
        loading={isLoading}
        onSelectedEntityChanged={entityReference => {
          handleEntityReferenceChanged(entityReference);
        }}
      />
      <EntityCombobox
        viewer={viewer}
        loading={isLoading}
        // Only allow rollups for hub
        includeRollup={viewer.dashboard ? !!viewer.dashboard.hub : false}
        handleChange={newEntityReference => {
          handleSubEntityReferenceChanged(newEntityReference);
        }}
        handleReset={() => {
          handleSubEntityReferenceChanged(null);
        }}
        value={subEntityReference}
      />
      {(!!sessionStorage.getItem('spoke') ||
        !!sessionStorage.getItem('hub')) && (
        <button
          className={DataViewerStyles.resetButton}
          onClick={() => {
            sessionStorage.removeItem('spoke');
            sessionStorage.removeItem('hub');
            handleSubEntityReferenceChanged(null);
            handleEntityReferenceChanged(null);
          }}
        >
          Reset Dashboard (to my Hub)
        </button>
      )}
    </div>
  </div>
);

interface Props {
  error?: Error;
  openDataModal: (open: string) => any;
  isModalOpen: boolean;
  viewer: DashboardDataViewer_viewer;
  isLoading: boolean;
  entityReference: string;
  handleNegativeSummaryClick: () => void;
  handleProfitSummaryClick: () => void;
  subEntityReference: string | null;
  handleEntityReferenceChanged: (entityReference: string | null) => void;
  handleSubEntityReferenceChanged: (subEntityReference: string | null) => void;
  history: History;
}

interface State {
  mobileSearchModalVisible: boolean;
}

class DashboardDataViewer extends Component<Props, State> {
  private jumpRefs = {
    balance: React.createRef<HTMLDivElement>(),
    sales: React.createRef<HTMLDivElement>(),
    airtime: React.createRef<HTMLDivElement>(),
    data: React.createRef<HTMLDivElement>(),
    electricity: React.createRef<HTMLDivElement>(),
    hollywood: React.createRef<HTMLDivElement>(),
    unipin: React.createRef<HTMLDivElement>(),
    dstv: React.createRef<HTMLDivElement>(),
    lotto: React.createRef<HTMLDivElement>(),
    m2m: React.createRef<HTMLDivElement>(),
    other: React.createRef<HTMLDivElement>(),
    ricatotal: React.createRef<HTMLDivElement>(),
    ricasuccess: React.createRef<HTMLDivElement>(),
    ricafail: React.createRef<HTMLDivElement>(),
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      mobileSearchModalVisible: false,
    };
  }

  public openSearchModal = () => {
    this.setState({ mobileSearchModalVisible: true });
  };

  public scrollToComponent = (value: any) => {
    const target = this.jumpRefs[value];

    if (target && target.current) {
      window.scrollTo({
        left: 0,
        top: target.current.offsetTop - 100,
      });
    } else {
      // tslint:disable-next-line no-console
      console.log(`Could not find ref: ${value} to scroll to`, this.jumpRefs);
    }
  };

  public render() {
    const {
      viewer,
      openDataModal,
      isLoading,
      isModalOpen,
      handleSubEntityReferenceChanged,
      handleEntityReferenceChanged,
      history,
    } = this.props;

    const totalSalesAllSpokes = viewer.report
      ? viewer.report.totalSalesAllSpokes
      : undefined;

    document.body.style.overflow =
      this.state.mobileSearchModalVisible || isModalOpen ? 'hidden' : 'scroll';

    const isSpokeAccount = false;

    if (!viewer.dashboard) {
      return (
        <div>
          <div>
            <Selectors
              isLoading={isLoading}
              subEntityReference={this.props.subEntityReference}
              viewer={viewer}
              handleEntityReferenceChanged={handleEntityReferenceChanged}
              handleSubEntityReferenceChanged={handleSubEntityReferenceChanged}
            />
          </div>
          <div className={DataViewerStyles['dashboard-error-display']}>
            <ErrorDisplay errorText="This entity has no data associated to it." />
          </div>
        </div>
      );
    }

    if (isModalOpen) {
      // Do not render dashboard when modal is open
      // to help performance on older devices
      return null;
    }

    const { dashboard } = viewer;

    // TODO fix any
    let account: any = null;

    if (
      this.props.entityReference === this.props.subEntityReference &&
      dashboard.hub
    ) {
      account = dashboard.hub;
    } else if (this.props.subEntityReference === 'rollup') {
      account = dashboard.rollup;
    } else {
      account = dashboard.spokes.find(spoke => {
        return spoke.account === this.props.subEntityReference;
      });
    }

    function getCategory(category: string) {
      const c = account.categories.find(cat => cat.category === category);

      if (!c) {
        throw new Error(`Could not find Category: ${category}`);
      }

      return c;
    }

    // Only hubs can see negative trading summary
    const showNegativeSummary = !!viewer.dashboard.hub;
    // If we cannot find an account and the refetch is has completed display an error
    return (
      <div className={DataViewerStyles.container}>
        <DashboardNewProductsModal />
        {this.state.mobileSearchModalVisible ? (
          <DashboardSearch
            account={account}
            onClose={() => {
              this.setState({ mobileSearchModalVisible: false });
            }}
            scrollToComponent={this.scrollToComponent}
          />
        ) : (
          !isModalOpen && (
            <DashboardSearchButtonSet openSearchModal={this.openSearchModal} />
          )
        )}
        <Selectors
          isLoading={isLoading}
          subEntityReference={this.props.subEntityReference}
          viewer={viewer}
          handleEntityReferenceChanged={handleEntityReferenceChanged}
          handleSubEntityReferenceChanged={handleSubEntityReferenceChanged}
        />
        {this.props.error && <ErrorView error={this.props.error} />}
        {account && (
          <>
            {showNegativeSummary && (
              <DashboardSpokePerformance
                data={totalSalesAllSpokes}
                handleItemClick={this.props.handleSubEntityReferenceChanged}
              />
            )}
            {showNegativeSummary && (
              <DashboardProductsReport
                salesData={totalSalesAllSpokes}
                history={history}
                // handleItemClick={this.props.handleSubEntityReferenceChanged}
              />
            )}
            <DashboardSummary
              totalSalesRef={this.jumpRefs.sales}
              subEntityReference={this.props.subEntityReference}
              commissionSummary={
                // @ts-ignore
                this.props.viewer.report!!.commissionSummary as any
              }
              handleClick={this.props.handleProfitSummaryClick}
              balanceRef={this.jumpRefs.balance}
              accountSummary={account}
              openDataModal={openDataModal}
              totalSales={getCategory('TOTAL_SALES')}
            />
          </>
        )}
        {!account && !isLoading && (
          <ErrorView error={new Error('Account not found')} />
        )}
        {isLoading && (
          <Spinner
            name="circle"
            color="darkblue"
            fadeIn="none"
            style={{ margin: '0 auto', zIndex: 20, width: 30, height: 30 }}
          />
        )}
        {account && (
          <div className={DataViewerStyles.dataRow}>
            <DataBlock
              title="Airtime"
              jumpRef={this.jumpRefs.airtime}
              category={getCategory('AIRTIME')}
              isCurrency
              allowOpenDataModal={isSpokeAccount}
              openDataModal={openDataModal}
            />
            <DataBlock
              title="Data"
              jumpRef={this.jumpRefs.data}
              category={getCategory('DATA')}
              isCurrency
              allowOpenDataModal={isSpokeAccount}
              openDataModal={openDataModal}
            />
            <DataBlock
              title="Electricity"
              jumpRef={this.jumpRefs.electricity}
              category={getCategory('ELECTRICITY')}
              isCurrency
              allowOpenDataModal={isSpokeAccount}
              openDataModal={openDataModal}
            />
            <DataBlock
              title="Hollywood Bets"
              jumpRef={this.jumpRefs.hollywood}
              isCurrency
              category={getCategory('HOLLYWOOD_BETS')}
              allowOpenDataModal={isSpokeAccount}
              openDataModal={openDataModal}
            />
            <DataBlock
              title="Unipin"
              jumpRef={this.jumpRefs.unipin}
              category={getCategory('UNIPIN')}
              allowOpenDataModal={isSpokeAccount}
              openDataModal={openDataModal}
              isCurrency
            />
            <DataBlock
              title="DSTV"
              jumpRef={this.jumpRefs.dstv}
              category={getCategory('DSTV')}
              isCurrency
              allowOpenDataModal={isSpokeAccount}
              openDataModal={openDataModal}
            />
            <DataBlock
              title="Lotto"
              jumpRef={this.jumpRefs.lotto}
              category={getCategory('LOTTO')}
              isCurrency
              allowOpenDataModal={isSpokeAccount}
              openDataModal={openDataModal}
            />
            <DataBlock
              title="M2M"
              jumpRef={this.jumpRefs.m2m}
              category={getCategory('M2M')}
              isCurrency
              allowOpenDataModal={isSpokeAccount}
              openDataModal={openDataModal}
            />
            <DataBlock
              title="Other"
              jumpRef={this.jumpRefs.other}
              category={getCategory('OTHER')}
              isCurrency
              allowOpenDataModal={isSpokeAccount}
              openDataModal={openDataModal}
            />
            <DataBlock
              title="Total Rica"
              jumpRef={this.jumpRefs.ricatotal}
              category={getCategory('RICA_TOTAL')}
              allowOpenDataModal={isSpokeAccount}
              openDataModal={openDataModal}
              isCurrency
            />
            <DataBlock
              title="Rica Successful"
              jumpRef={this.jumpRefs.ricasuccess}
              category={getCategory('RICA_SUCCESS')}
              allowOpenDataModal={isSpokeAccount}
              openDataModal={openDataModal}
              isCurrency
            />
            <DataBlock
              title="Rica Failed"
              jumpRef={this.jumpRefs.ricafail}
              category={getCategory('RICA_FAILED')}
              allowOpenDataModal={isSpokeAccount}
              openDataModal={openDataModal}
              isCurrency
            />
            {showNegativeSummary && (
              <DashboardNegativeSummary
                title="Negative Trading Summary"
                handleClick={this.props.handleNegativeSummaryClick}
                viewer={viewer}
              />
            )}
          </div>
        )}
        <ReactTooltip />
      </div>
    );
  }
}

export default createFragmentContainer(DashboardDataViewer, {
  viewer: graphql`
    fragment DashboardDataViewer_viewer on Viewer {
      ...AccountSelector_viewer
      user {
        roles {
          name
        }
      }
      report {
        commissionSummary(entityReference: $entityReference) {
          ...DashboardSummary_commissionSummary
        }
        totalSalesAllSpokes(
          entityReference: $entityReference
          startDate: $startDate
          endDate: $endDate
        ) {
          Spoke
          TotalSales
          SpokeAcc
          HUB
          AirtimeSales
          Data_Sales
          Electricity_Sales
          HBets_Sales
          Unipin_Sales
          DSTV_Sales
          Lotto_Sales
          M2M_Sales
          Other_Sales
          AirtimeSalesCount
          DataSalesCount
          ElectricitySalesCount
          HBetsSalesCount
          UnipinSalesCount
          DSTVSalesCount
          LottoSalesCount
          M2MSalesCount
          OtherSalesCount
        }
      }
      ...EntityCombobox_viewer
      ...DashboardNegativeSummary_viewer
      dashboard(entityReference: $entityReference) {
        hub {
          account
          ...DashboardSearch_account
          ...DashboardSummary_accountSummary
          categories {
            category
            ...DashboardDataBlock_category
            ...DashboardSummary_totalSales
          }
        }
        rollup {
          account
          ...DashboardSearch_account
          ...DashboardSummary_accountSummary
          categories {
            category
            ...DashboardDataBlock_category
            ...DashboardSummary_totalSales
          }
        }
        spokes {
          account
          ...DashboardSearch_account
          ...DashboardSummary_accountSummary
          categories {
            category
            ...DashboardDataBlock_category
            ...DashboardSummary_totalSales
          }
        }
      }
    }
  `,
});
