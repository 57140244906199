/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type UnreadNotificationsSubscriptionSubscriptionVariables = {};
export type UnreadNotificationsSubscriptionSubscriptionResponse = {
    readonly viewerNotifications: {
        readonly unreadBroadcastNotifications: number;
    };
};
export type UnreadNotificationsSubscriptionSubscription = {
    readonly response: UnreadNotificationsSubscriptionSubscriptionResponse;
    readonly variables: UnreadNotificationsSubscriptionSubscriptionVariables;
};



/*
subscription UnreadNotificationsSubscriptionSubscription {
  viewerNotifications {
    unreadBroadcastNotifications
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "unreadBroadcastNotifications",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "subscription",
  "name": "UnreadNotificationsSubscriptionSubscription",
  "id": null,
  "text": "subscription UnreadNotificationsSubscriptionSubscription {\n  viewerNotifications {\n    unreadBroadcastNotifications\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UnreadNotificationsSubscriptionSubscription",
    "type": "Subscription",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewerNotifications",
        "storageKey": null,
        "args": null,
        "concreteType": "ViewerNotifications",
        "plural": false,
        "selections": [
          v0
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UnreadNotificationsSubscriptionSubscription",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewerNotifications",
        "storageKey": null,
        "args": null,
        "concreteType": "ViewerNotifications",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
(node as any).hash = '4bb8bf1846ede6f1c12df0e07275ce6d';
export default node;
