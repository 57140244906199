import * as React from 'react';

import AuthContext from './AuthContext';
import ErrorDisplay from '../components/errorDisplay';

interface Props {
  // tslint:disable-next-line no-any
  children: (opts: { token: string }) => React.ReactElement<any>;
}

export default function AuthenticatedAuthContext(props: Props) {
  return (
    <AuthContext.Consumer>
      {auth => {
        if (auth.authenticated) {
          return props.children({ token: auth.token });
        }

        return <ErrorDisplay errorText="Access Denied" />;
      }}
    </AuthContext.Consumer>
  );
}
