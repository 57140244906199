import React, { Component } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { createPaginationContainer, RelayPaginationProp } from 'react-relay';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classNames from 'classnames';

import { NotificationBar_viewer } from './__generated__/NotificationBar_viewer.graphql';
import styles from './NotificationBar.module.css';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps {
  viewer: NotificationBar_viewer;
  relay: RelayPaginationProp;
}

interface State {
  broadcastNotificationsShown: Set<string>;
}
const MAX_TOAST = 3;
class NotificationBar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      broadcastNotificationsShown: new Set(),
    };
  }

  public componentWillReceiveProps(nextProps: Props) {
    this.notify(nextProps.viewer);
  }

  public componentDidMount() {
    this.notify(this.props.viewer);
  }

  public render() {
    return <ToastContainer />;
  }

  private notify(viewer: NotificationBar_viewer) {
    const { broadcastNotificationsShown } = this.state;
    const newToasts = new Set();

    if (this.props.history.location.pathname === '/notifications') {
      return;
    }

    if (viewer.broadcastNotifications && viewer.broadcastNotifications.edges) {
      for (const edge of viewer.broadcastNotifications.edges.slice(
        0,
        viewer.broadcastNotifications.edges.length > MAX_TOAST
          ? MAX_TOAST
          : undefined
      )) {
        if (edge && edge.node) {
          const { node } = edge;
          if (broadcastNotificationsShown.has(node.id) || node.read != null) {
            continue;
          }

          const classType = 'info';
          toast(
            <div onClick={() => this.handleClickEvent(node.id)}>
              {node.broadcast.title}
            </div>,
            {
              toastId: node.id,
              closeOnClick: true,
              autoClose: false,
              type: classType,
              draggable: false,
              className: classNames(styles.toastify, styles[classType]),
              bodyClassName: styles.toastifyBody,
            }
          );
        }
      }

      this.setState({
        broadcastNotificationsShown: newToasts,
      });
    }
  }

  private handleClickEvent = async (_broadcastNotificationId: string) => {
    this.props.history.push('/notifications');
  };
}

const query = graphql`
  query NotificationBarQuery(
    $read: Boolean!
    $cancelled: Boolean!
    $expired: Boolean!
    $delivered: Boolean!
  ) {
    viewer {
      ...NotificationBar_viewer
    }
  }
`;

const NotificationBarPaginationContainer = createPaginationContainer(
  NotificationBar,
  {
    viewer: graphql`
      fragment NotificationBar_viewer on Viewer
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 3 }
          read: { type: "Boolean!", defaultValue: false }
          cancelled: { type: "Boolean!", defaultValue: false }
          expired: { type: "Boolean!", defaultValue: false }
          delivered: { type: "Boolean!", defaultValue: true }
          cursor: { type: "ID" }
        ) {
        broadcastNotifications(
          first: $count
          read: $read
          cancelled: $cancelled
          expired: $expired
          delivered: $delivered
        ) @connection(key: "NotificationBar_broadcastNotifications") {
          total
          pageInfo {
            hasNextPage
          }
          edges {
            node {
              id
              read {
                createdAt
              }
              broadcast {
                message
                title
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(_props, { count, cursor }, _fragmentVariables) {
      return {
        count,
        cursor,
      };
    },
    query,
  }
);

export default NotificationBarPaginationContainer;
