import React, { Component } from 'react';
import moment from 'moment';
import { Environment } from 'relay-runtime';
import Spinner from 'react-spinkit';
import { History } from 'history';
import { RelayProp } from 'react-relay';
import NetworkService from '../../../../services/NetworkService';
import styles from './ProductTile.module.css';
import ChevronDown from '../../../../assets/images/chevron-down.svg';
import ChevronUp from '../../../../assets/images/chevron-up.svg';
import ProductDeleteMutation from '../../mutations/ProductDeleteMutation';
import { ProductEditModal } from '../ProductEditModal/ProductEditModal';

interface Props {
  environment: Environment;
  id: string;
  title: string;
  dateAvailable: string;
  description: string;
  image?: string;
  externalLink?: string;
  externalLinkText?: string;
  onDelete: () => void;
  isAdmin?: boolean;
  relay: RelayProp;
  history: History;
}

interface State {
  open?: boolean;
  imageSrc?: string;
  loading: boolean;
  editing?: boolean;
}

export class ProductTile extends Component<Props, State> {
  public state: State = {
    open: false,
    loading: false,
    editing: false,
  };

  public calculateDate = () => {
    const { dateAvailable } = this.props;
    return moment(dateAvailable).isSameOrBefore(moment(), 'day')
      ? 'now'
      : moment(dateAvailable).format('DD MMMM YYYY');
  };

  public async componentDidMount() {
    if (this.props.image) {
      const productId = atob(this.props.id).split(':')[1];
      const data = await NetworkService.post(
        `products/${productId}/request-image`,
        {}
      );
      if (data) {
        let binary = '';
        const dataArray = new Uint8Array(data.data);
        const len = dataArray.length;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(dataArray[i]);
        }
        this.setState({
          imageSrc: btoa(binary),
        });
      }
    }
  }

  public deleteProduct = async () => {
    if (confirm('You are about to delete this product. Continue?')) {
      this.setState({ loading: true });
      await ProductDeleteMutation(this.props.environment, {
        input: { id: this.props.id },
      });
      this.props.onDelete();
    } else {
      return;
    }
  };

  public editProduct = async () => {
    this.setState({ editing: true });
  };

  public render() {
    const { open, loading } = this.state;
    const dateDisplay = this.calculateDate();
    const {
      id,
      title,
      image,
      description,
      externalLink,
      externalLinkText,
      dateAvailable,
      isAdmin,
      environment,
      relay,
      history,
    } = this.props;

    return (
      <>
        {this.state.editing && (
          <ProductEditModal
            environment={environment}
            relay={relay}
            history={history}
            productId={id}
            values={{
              title,
              description,
              dateAvailable,
              externalLink,
              externalLinkText,
            }}
            handleClose={() => this.setState({ editing: false })}
          />
        )}
        <div className={open ? styles.bodyOpen : styles.bodyClosed}>
          <div className={styles.header}>
            <div className={styles.title}>
              <p>{title}</p>
            </div>
            {!open && <p className={styles.mobileTitleClosed}>{title}</p>}
            <div
              className={open ? styles.dateOpen : styles.dateClosed}
              onClick={() => this.setState({ open: !this.state.open })}
              role="button"
            >
              <p>Available {dateDisplay}</p>
              <img
                src={open ? ChevronUp : ChevronDown}
                className={styles.chevron}
              />
            </div>
            {image && open && (
              <img
                className={styles.mobileImage}
                src={`data:image/png;base64,${this.state.imageSrc}`}
              />
            )}
            {open && <p className={styles.mobileTitle}>{title}</p>}
          </div>
          {open && (
            <>
              <div className={styles.contentBody}>
                {image && (
                  <img
                    className={styles.image}
                    src={`data:image/png;base64,${this.state.imageSrc}`}
                  />
                )}
                <div
                  className={
                    image
                      ? styles.descriptionBlockHalf
                      : styles.descriptionBlock
                  }
                >
                  <p className={styles.description}>{description}</p>
                  {externalLink && externalLinkText && (
                    <a
                      href={externalLink}
                      className={styles.buttonContainer}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className={styles.button}>
                        {externalLinkText.toUpperCase()}
                      </button>
                    </a>
                  )}
                </div>
              </div>
              {isAdmin && !loading && (
                <button
                  className={styles.deleteButton}
                  onClick={this.deleteProduct}
                >
                  DELETE
                </button>
              )}
              {isAdmin && !loading && (
                <button
                  className={styles.editButton}
                  onClick={() => {
                    this.setState({ editing: true });
                  }}
                >
                  EDIT
                </button>
              )}
              {isAdmin && loading && (
                <div className={styles.deleteButton}>
                  <Spinner
                    name="circle"
                    color="darkblue"
                    fadeIn="quarter"
                    style={{ width: 40, height: 40, marginRight: 25 }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}
