/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type EmailDrilldownMutationInput = {
    readonly email: ReadonlyArray<string>;
    readonly entityRef: string;
    readonly category: string;
    readonly clientMutationId?: string | null;
};
export type EmailDrilldownMutationVariables = {
    readonly input: EmailDrilldownMutationInput;
};
export type EmailDrilldownMutationResponse = {
    readonly emailDrilldown: ({
        readonly clientMutationId: string | null;
        readonly accepted: ReadonlyArray<string>;
        readonly rejected: ReadonlyArray<string>;
    }) | null;
};
export type EmailDrilldownMutation = {
    readonly response: EmailDrilldownMutationResponse;
    readonly variables: EmailDrilldownMutationVariables;
};



/*
mutation EmailDrilldownMutation(
  $input: EmailDrilldownMutationInput!
) {
  emailDrilldown(input: $input) {
    clientMutationId
    accepted
    rejected
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "EmailDrilldownMutationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "emailDrilldown",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "EmailDrilldownMutationInput!"
      }
    ],
    "concreteType": "EmailDrilldownMutationPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "accepted",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rejected",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "EmailDrilldownMutation",
  "id": null,
  "text": "mutation EmailDrilldownMutation(\n  $input: EmailDrilldownMutationInput!\n) {\n  emailDrilldown(input: $input) {\n    clientMutationId\n    accepted\n    rejected\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EmailDrilldownMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "EmailDrilldownMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
(node as any).hash = 'bf930979d042319634e719c02dd973cf';
export default node;
