import graphql from 'babel-plugin-relay/macro';
import { createMutation } from '@stackworx/relay';

import { SelfRegistrationSubmitPinMutation } from './__generated__/SelfRegistrationSubmitPinMutation.graphql';

const mutation = graphql`
  mutation SelfRegistrationSubmitPinMutation(
    $input: SelfRegistrationSubmitPinInput!
  ) {
    selfRegistrationSubmitPin(input: $input) {
      clientMutationId
      token
    }
  }
`;

export default createMutation<SelfRegistrationSubmitPinMutation>(mutation);
