import React from 'react';

import ExcelIcon from '../../assets/images/excel-o.svg';

interface Props {
  href: string;
}

export default ({ href }: Props) => (
  <a href={href} className="base-button float-left excel-download-button">
    <img
      src={ExcelIcon}
      style={{ width: 20, paddingRight: 10, marginBottom: -4 }}
    />
    DOWNLOAD XLSX
  </a>
);
