import * as React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import DataViewerStyles from './DashboardDataViewer.module.css';
import DashboardProfitView from './DashboardProfitView';
import { Currency, Date } from '../../components/Formatters';
import { DashboardSummary_accountSummary } from './__generated__/DashboardSummary_accountSummary.graphql';
import { DashboardSummary_totalSales } from './__generated__/DashboardSummary_totalSales.graphql';
import { DashboardSummary_commissionSummary } from './__generated__/DashboardSummary_commissionSummary.graphql';

interface Props {
  balanceRef: React.RefObject<HTMLDivElement>;
  totalSalesRef: React.RefObject<HTMLDivElement>;
  openDataModal: (category: string) => void;
  accountSummary: DashboardSummary_accountSummary;
  totalSales: DashboardSummary_totalSales;
  commissionSummary?: DashboardSummary_commissionSummary;
  handleClick: () => void;
  subEntityReference: string | null;
}

function DashboardSummary({
  openDataModal,
  accountSummary,
  totalSales,
  balanceRef,
  totalSalesRef,
  commissionSummary,
  subEntityReference,
  handleClick,
}: Props) {
  return (
    <div className={DataViewerStyles.dataRow}>
      <DashboardProfitView
        handleClick={handleClick}
        subEntityReference={subEntityReference}
        /* tslint:disable-next-line */
        commissionSummary={commissionSummary as any}
      />
      <div className={DataViewerStyles.separator}>
        <div
          className={DataViewerStyles['blue-block-container']}
          onClick={() => openDataModal('AVAILABLE_BALANCE')}
          ref={balanceRef}
        >
          <div className={DataViewerStyles['blue-block']}>
            <p className={DataViewerStyles['block-title']}>Available balance</p>
            <div className={DataViewerStyles['block-text']}>
              <div>
                <Currency>{accountSummary.availableBalance}</Currency>
              </div>
            </div>
          </div>
        </div>
        <div
          className={DataViewerStyles['blue-block-container']}
          onClick={() => openDataModal('LAST_PAID')}
        >
          <div className={DataViewerStyles['blue-block']}>
            <p className={DataViewerStyles['block-title']}>Last Paid</p>
            <div className={DataViewerStyles['block-text']}>
              <div>
                {accountSummary.lastPaid ? (
                  <Currency>{accountSummary.lastPaid.amount}</Currency>
                ) : (
                  <div>N/A</div>
                )}
                <div className={DataViewerStyles['date-text']}>
                  {accountSummary.lastPaid ? (
                    <Date>{accountSummary.lastPaid.date}</Date>
                  ) : (
                    <div>
                      N/A when viewing total values for all hubs and spokes
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={DataViewerStyles['blue-block-container']}
        tabIndex={0}
        role="button"
        ref={totalSalesRef}
        onClick={() => {
          openDataModal('TOTAL_SALES');
        }}
      >
        <div className={DataViewerStyles['blue-block']}>
          <p className={DataViewerStyles['block-title']}>Total Sales</p>
          <div className={DataViewerStyles['block-text']}>
            <div>
              <div>
                <Currency>{totalSales.yesterdaysSalesToDate}</Currency>
                <span
                  className={DataViewerStyles['plain-text']}
                  data-tip="Yesterday's sales"
                >
                  Yesterday’s Sales
                </span>
              </div>
              <hr className={DataViewerStyles.underline} />
              <div>
                <Currency>{totalSales.currentMonthToDate}</Currency>
                <span
                  className={DataViewerStyles['plain-text']}
                  data-tip="This Month to-date's sales"
                >
                  This month to date’s sales
                </span>
              </div>
              <hr className={DataViewerStyles.underline} />
              <div>
                <Currency>{totalSales.lastMonthMonthToDate}</Currency>
                <span
                  className={DataViewerStyles['plain-text']}
                  data-tip="Last Month to-date's sales"
                >
                  Last month to date’s sales
                </span>
              </div>
              <hr className={DataViewerStyles.underline} />
              <div>
                <Currency>{totalSales.lastMonth}</Currency>
                <span
                  className={DataViewerStyles['plain-text']}
                  data-tip="Last Month's Sales"
                >
                  Last month’s sales
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default createFragmentContainer(DashboardSummary, {
  totalSales: graphql`
    fragment DashboardSummary_totalSales on DashboardCategorySales {
      yesterdaysSalesToDate
      currentMonthToDate
      lastMonthMonthToDate
      lastMonth
    }
  `,
  commissionSummary: graphql`
    fragment DashboardSummary_commissionSummary on CommissionSummary
      @relay(plural: true) {
      ...DashboardProfitView_commissionSummary
    }
  `,
  accountSummary: graphql`
    fragment DashboardSummary_accountSummary on DashboardAccountSummary {
      name
      account
      availableBalance
      lastPaid {
        amount
        date
      }
    }
  `,
});
