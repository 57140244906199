/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type DashboardNegativeSummary_viewer$ref = any;
export type DashboardNegativeSummary_viewer = {
    readonly report: ({
        readonly reportNegativeTradingSummary: ReadonlyArray<{
            readonly notTradedInLastDay: number;
            readonly notTradeInLast7Days: number;
            readonly notTradeInLast4Weeks: number;
            readonly notTradedSinceInception: number;
            readonly tradingWorseThanLastMonth: number;
            readonly tradingBalanceLess100: number;
            readonly totalSpokes: number;
        }> | null;
    }) | null;
    readonly " $refType": DashboardNegativeSummary_viewer$ref;
};



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "DashboardNegativeSummary_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "entityReference",
      "type": "String!"
    }
  ],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "report",
      "storageKey": null,
      "args": null,
      "concreteType": "Report",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "reportNegativeTradingSummary",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "entityReference",
              "variableName": "entityReference",
              "type": "String!"
            }
          ],
          "concreteType": "ReportNegativeTradingSummary",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "notTradedInLastDay",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "notTradeInLast7Days",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "notTradeInLast4Weeks",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "notTradedSinceInception",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "tradingWorseThanLastMonth",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "tradingBalanceLess100",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "totalSpokes",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = 'cd0f9fb50b614188c4bb6f9be79b04aa';
export default node;
