/* tslint:disable no-any */
import * as React from 'react';
import { Component } from 'react';
import { History } from 'history';
import SideBarStyle from './sideBarMenu.module.css';

interface Props {
  icon: any;
  path: string;
  history: History;
  title: string;
  viewType?: boolean;
  onClick?: () => void;
}

export default class SideMenuItem extends Component<Props> {
  public render() {
    const { icon, path, history, title, viewType, onClick } = this.props;

    return (
      <div
        className={
          viewType === true
            ? SideBarStyle['menu-item']
            : SideBarStyle['menu-item-closed']
        }
        onClick={e => {
          onClick ? onClick() : history.push(path);
          e.stopPropagation();
        }}
        role="button"
        tabIndex={0}
        style={
          history.location.pathname === path
            ? { backgroundColor: '#021640' }
            : { backgroundColor: '#001B54' }
        }
      >
        <img
          className={
            viewType === true
              ? SideBarStyle['menu-open-icon']
              : SideBarStyle['submenu-img']
          }
          alt={title}
          src={icon}
        />
        <p
          className={
            viewType === true
              ? SideBarStyle['menu-header']
              : SideBarStyle['submenu-span']
          }
        >
          {title}
        </p>
        {this.props.children}
      </div>
    );
  }
}
