import graphql from 'babel-plugin-relay/macro';
import { createMutation } from '@stackworx/relay';

import { TourCompleteMutation } from './__generated__/TourCompleteMutation.graphql';

const mutation = graphql`
  mutation TourCompleteMutation($input: TourCompleteInput!) {
    userTourComplete(input: $input) {
      clientMutationId
      tourCompleted
    }
  }
`;

export const TourComplete = createMutation<TourCompleteMutation>(mutation);
