export default {
  AVAILABLE_BALANCE: 'Available Balance',
  TOTAL_SALES: 'Total Sales',
  LAST_PAID: 'Last Paid',
  AIRTIME: 'Airtime',
  DATA: 'Data',
  ELECTRICITY: 'Electricity',
  HOLLYWOOD_BETS: 'Hollywood Bets',
  UNIPIN: 'Unipin',
  DSTV: 'DSTV',
  LOTTO: 'Lotto',
  M2M: 'M2M',
  // EVENT_TICKETS: 'Event Tickets',
  // LongHaulBus: 'Long Haul Bus Tickets',
  // PutcoTicket: 'Putco Tickets' },
  RICA_TOTAL: 'Rica - total',
  RICA_SUCCESS: 'Rica - success',
  RICA_FAILED: 'Rica - fail',
  OTHER: 'Other',
};
