import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import Yup from 'yup';
import ResetUserPasswordMutation from '../mutations/ResetUserPasswordMutation';
import TextField from '../../../components/textField';
import RelayContext from '../../../contexts/RelayContext';
import styles from './ResetUserPassword.module.css';
import bluLogo from '../../../assets/images/bluLogo.svg';
import bluKey from '../../../assets/images/blukey.svg';
import Button from '../../components/Button';
import Spinner from 'react-spinkit';
import { Environment } from 'relay-runtime';
import { firebaseAnalyticsService } from '../../../firebaseSetup';

interface State {
  isLoading: boolean;
}

interface Values {
  username: string;
  cellNumber: string;
}

interface Props {
  environment: Environment;
  nextStep: () => void;
  handleSubmit: (values: Values) => void;
  values: {
    username: string;
    cellNumber: string;
  };
}

const Schema = Yup.object().shape({
  username: Yup.string().required('Username is a required field'),
});

class ResetUserPasswordStart extends React.Component<Props, State> {
  public static contextType = RelayContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  public render() {
    return (
      <div className={styles.loginBody}>
        <img src={bluLogo} alt="" className="body-corner-logo-top" />
        <div className={styles.registerContainer}>
          <div className={styles.image}>
            <img src={bluKey} alt="" className={styles.logo} />
          </div>
          <h3 className={styles.title}>Change Password</h3>

          <p className={styles.text}>
            In order to reset your password please provide your Username below
            and a ONE TIME PIN will be sent to your cellphone number on record.
            <br />
          </p>
          <Formik<Values>
            initialValues={{
              username: '',
              cellNumber: '',
            }}
            validationSchema={Schema}
            onSubmit={async (
              values,
              { setStatus, setSubmitting, setFieldError }
            ) => {
              this.setState({ isLoading: true });

              try {
                setStatus('');
                firebaseAnalyticsService.logEvent('reset_password_start', {
                  content_id: btoa(values.username),
                  username: values.username,
                });
                const data = await ResetUserPasswordMutation(
                  this.props.environment,
                  {
                    input: {
                      username: values.username,
                    },
                  }
                );

                // @ts-ignore
                values.cellNumber = data.userResetPassword.cellNumber;
                this.setState({ isLoading: false });

                this.props.nextStep();
                this.props.handleSubmit(values);
              } catch (e) {
                setSubmitting(false);
                this.setState({
                  isLoading: false,
                });
                setFieldError('username', e.message);
                setStatus(e.message || 'Invalid username');
              }
            }}
            render={({
              handleSubmit,
              isSubmitting,
              error,
              values,
              touched,
            }) => {
              return (
                <Form
                  autoComplete="nope"
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      handleSubmit(e);
                    }
                  }}
                >
                  <div className="input-container">
                    <Field
                      name="username"
                      label="Username *"
                      component={TextField}
                      disabled={isSubmitting}
                    />
                  </div>

                  <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    text={
                      this.state.isLoading ? (
                        <Spinner
                          name="circle"
                          color="white"
                          fadeIn="none"
                          // @ts-ignore
                          style={{
                            margin: '0 auto',
                            left: '0 !important',
                            position: 'relative !Important',
                            zIndex: '1 !important',
                          }}
                        />
                      ) : (
                        'REQUEST OTP'
                      )
                    }
                  />
                </Form>
              );
            }}
          />
          <div className="forgot-link">
            <a href="/login">&#60; Go to Login</a>
          </div>
          <p className={styles.helpMessage}>
            Need help? Call centre: 0800 014 942
          </p>
        </div>
      </div>
    );
  }
}

export default ResetUserPasswordStart;
