import React from 'react';

import ErrorDisplay from '../../components/errorDisplay';

export default ({ entityReference }: { entityReference?: string | null }) => (
  <ErrorDisplay
    errorText={`The selected account ${
      entityReference ? `(${entityReference}) ` : ''
    }has no data associated to it.`}
  />
);
