import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import Yup from 'yup';
import TextField from '../../../components/textField';
import Button from '../../components/Button';
import styles from './SelfRegistration.module.css';
import SelfRegistrationStartMutation from '../mutations/SelfRegistrationStartMutation';
import Spinner from 'react-spinkit';

import { mapErrors } from '../../../GraphQLErrorExtensionMapping';
import bluLogo from '../../../assets/images/blukey.svg';
import { Environment } from 'relay-runtime';
import { firebaseAnalyticsService } from '../../../firebaseSetup';

const Schema = Yup.object().shape({
  entityNumber: Yup.string()
    .matches(
      /^(INF|EEE|inf|eee)/,
      'Account number not valid, please ensure to use your correct INF or EEE account number.'
    )
    .min(3, 'Account Number should be a minimum of 8 characters long')
    .required('Account Number is a required field'),
  cellNumber: Yup.string()
    .matches(/^\+?[\(\)-\d ]*$/, {
      message:
        'Cellphone Number must only contain numbers, spaces, brackets or dashes',
      excludeEmptyString: false,
    })
    .matches(/^(\+27|0).*$/, {
      message: 'Cellphone Number must either start with 0 or +27',
      excludeEmptyString: false,
    })
    .max(20, 'Cellphone Number too long')
    .required('Cellphone Number is a required field'),
});

interface State {
  isLoading: boolean;
}
interface Entity {
  entityNumber: string;
  cellNumber: string;
}
interface Props {
  environment: Environment;
  nextStep: () => void;
  handleSubmit: (value: Entity) => void;
  values: {
    entityNumber: string;
    cellNumber: string;
    firstName: string;
    lastName: string;
    email: string;
  };
}

class SelfRegistrationStart extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }
  public render() {
    return (
      <div className={styles.loginBody}>
        <div className={styles.registerContainer}>
          <div className={styles.image}>
            <img src={bluLogo} alt="" className={styles.logo} />
          </div>
          <h3 className={styles.title}>Self Registration</h3>
          <p className={styles.text}>
            In order to register we require your account number as well as the
            cellphone number linked to your account with Blue Label. We will use
            this cellphone number to verify you are the owner of the account as
            well as to verify by sending you a <b>ONE TIME PIN*</b>
            <br />
            <br />
            If you do not have a cellphone number registered against your
            account or your number has changed please update your details via
            the call centre on <b>0800 014 942</b>.
          </p>

          <Formik<Entity>
            initialValues={{
              entityNumber: this.props.values.entityNumber,
              cellNumber: this.props.values.cellNumber,
            }}
            validateOnChange
            validationSchema={Schema}
            onSubmit={async (values, formikActions) => {
              try {
                formikActions.setSubmitting(true);
                formikActions.setStatus(null);
                this.setState({ isLoading: true });
                firebaseAnalyticsService.logEvent('self_registration_start', {
                  content_id: btoa(values.entityNumber),
                  values: {
                    entity_number: values.entityNumber,
                    cell_number: values.cellNumber,
                  },
                });
                const {
                  selfRegistrationStart,
                } = await SelfRegistrationStartMutation(
                  this.props.environment,
                  {
                    input: {
                      entityReference: values.entityNumber,
                      cellphoneNumber: values.cellNumber,
                    },
                  }
                );

                formikActions.setSubmitting(false);
                this.setState({
                  isLoading: false,
                });

                this.props.nextStep();
                this.props.handleSubmit({
                  entityNumber: values.entityNumber,
                  // Formatted cellphone number
                  cellNumber: selfRegistrationStart!.cellphoneNumber,
                });
              } catch (e) {
                formikActions.setSubmitting(false);
                this.setState({
                  isLoading: false,
                });

                mapErrors(e, formikActions, {
                  entityReference: 'entityNumber',
                  cellphoneNumber: 'cellNumber',
                });
              }
            }}
            render={({ status, handleSubmit, isSubmitting }) => (
              <Form
                autoComplete="nope"
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    handleSubmit(e);
                  }
                }}
              >
                <div className="input-container">
                  <Field
                    name="entityNumber"
                    label="Your account number *"
                    placeholder="e.g. INF1231234"
                    component={TextField}
                    disabled={isSubmitting}
                    preventSpace={true}
                  />
                </div>
                <div className="input-container">
                  <Field
                    name="cellNumber"
                    label="Cellphone number linked to your account *"
                    placeholder="e.g. 0821231234"
                    component={TextField}
                    disabled={isSubmitting}
                  />
                </div>

                {status && <div className={styles.status}>{status}</div>}

                <Button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  text={
                    this.state.isLoading ? (
                      <Spinner
                        name="circle"
                        color="white"
                        fadeIn="none"
                        style={{
                          position: 'fixed',
                          zIndex: 1,
                          overflow: 'visible',
                          margin: 'auto',
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                        }}
                      />
                    ) : (
                      'REQUEST OTP'
                    )
                  }
                />
              </Form>
            )}
          />

          <div className="forgot-link">
            <a href="/login">&#60; Go to Login</a>
          </div>
          <p className={styles.OTPInfo}>
            * A unique once-off 5 digit number that we send to your cellphone to
            ensure that you are in possession of the same cellphone on our
            records.
          </p>
        </div>
      </div>
    );
  }
}

export default SelfRegistrationStart;
