/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type sidebarMenu_viewer$ref = any;
export type sidebarMenu_viewer = {
    readonly notifications: ({
        readonly unreadBroadcastNotifications: number;
    }) | null;
    readonly user: ({
        readonly username: string;
        readonly entities: ReadonlyArray<{
            readonly accountName: string | null;
        }>;
        readonly roles: ReadonlyArray<{
            readonly name: string;
        }>;
        readonly isHub: boolean;
    }) | null;
    readonly " $refType": sidebarMenu_viewer$ref;
};



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "sidebarMenu_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "notifications",
      "storageKey": null,
      "args": null,
      "concreteType": "ViewerNotifications",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "unreadBroadcastNotifications",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "username",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "entities",
          "storageKey": null,
          "args": null,
          "concreteType": "Entity",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "accountName",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "roles",
          "storageKey": null,
          "args": null,
          "concreteType": "Role",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "isHub",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = '0daba5dfc2c3f9fa46e64d0b41c10973';
export default node;
