import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { requestSubscription } from 'react-relay';
import {
  Disposable,
  RecordSourceSelectorProxy,
  ConnectionHandler,
  RecordProxy,
} from 'relay-runtime';
import RelayContext from '../../contexts/RelayContext';

import { SubscriptionsSubscription } from './__generated__/SubscriptionsSubscription.graphql';

const subscription = graphql`
  subscription SubscriptionsSubscription {
    broadcastNotification {
      id
      ...BroadcastNotificationDropdown_notification
    }
  }
`;

export default class Subscriptions extends React.Component {
  public static contextType = RelayContext;

  private disposable?: Disposable;

  public componentDidMount() {
    this.disposable = requestSubscription(this.context, {
      subscription,
      variables: {},
      onNext: (_resp: SubscriptionsSubscription) => {
        // TODO: do we need this function?
      },
      updater: (store: RecordSourceSelectorProxy) => {
        const notification = store.getRootField('broadcastNotification');

        if (!notification) {
          return;
        }

        // Add it to a connection
        const viewer = store.get('client:root:viewer');

        if (viewer) {
          this.addToBroadcastConnection(viewer, store, notification);
          this.addToNotificationBarConnection(viewer, store, notification);
        }
      },
    });
  }

  public componentWillUnmount() {
    if (this.disposable) {
      this.disposable.dispose();
    }
  }

  public render() {
    return null;
  }

  private addToBroadcastConnection(
    viewer: RecordProxy,
    store: RecordSourceSelectorProxy,
    notification: RecordProxy
  ) {
    const notifications = ConnectionHandler.getConnection(
      viewer,
      'Broadcasts_broadcastNotifications',
      { cancelled: false, delivered: true, expired: false }
    );

    if (!notifications) {
      return;
    }

    const edge = ConnectionHandler.createEdge(
      store,
      notifications,
      notification,
      '<BroadcastNotificationEdge>'
    );

    ConnectionHandler.insertEdgeBefore(notifications, edge);
  }

  private addToNotificationBarConnection(
    viewer: RecordProxy,
    store: RecordSourceSelectorProxy,
    notification: RecordProxy
  ) {
    const notifications = ConnectionHandler.getConnection(
      viewer,
      'NotificationBar_broadcastNotifications',
      { cancelled: false, delivered: true, expired: false, read: false }
    );

    if (!notifications) {
      return;
    }

    const edge = ConnectionHandler.createEdge(
      store,
      notifications,
      notification,
      '<BroadcastNotificationEdge>'
    );

    ConnectionHandler.insertEdgeBefore(notifications, edge);
  }
}
