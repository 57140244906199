import * as React from 'react';
import SearchIcon from '../../../assets/images/search.svg';
import ArrowUp from '../../../assets/images/arrow-up.svg';

interface Props {
  openSearchModal: () => void;
}

export default (props: Props) => (
  <div className="mobile-search-buttons">
    <button className="mobile-search-button" onClick={props.openSearchModal}>
      <img className={'search-icon'} alt="Search" src={SearchIcon} />
    </button>
    <button
      className="mobile-search-button"
      onClick={() => {
        // TODO: https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        // @ts-ignore
        setTimeout(() => window.scrollTo(0, 0, 'smooth'), 50);
      }}
    >
      <img className={'arrow-icon'} alt="angle-up" src={ArrowUp} />
    </button>
  </div>
);
