import graphql from 'babel-plugin-relay/macro';
import { createMutation } from '@stackworx/relay';

import { ChangeDetailsMutation } from './__generated__/ChangeDetailsMutation.graphql';

const mutation = graphql`
  mutation ChangeDetailsMutation(
    $input: EmailUserDetailsChangeRequestMutationInput!
  ) {
    emailUserDetailsChange(input: $input) {
      clientMutationId
      accepted
      rejected
    }
  }
`;

export const ChangeDetailsRequest = createMutation<ChangeDetailsMutation>(
  mutation
);
