import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import Yup from 'yup';
import Spinner from 'react-spinkit';
import { RouteComponentProps } from 'react-router-dom';

import NetworkService from '../services/NetworkService';
import bluLogo from '../assets/images/bluLogo.svg';
import blukey from '../assets/images/blukey.svg';
import getMoreImage from '../assets/images/get-more-done.png';
import './login.css';
import styles from './login.module.css';
import TextField from '../components/textField';
import Button from '../components/button';
import ErrorDisplay from '../components/errorDisplay';
import { firebaseAnalyticsService } from '../firebaseSetup';

const Schema = Yup.object().shape({
  username: Yup.string()
    .required('Username is a required field')
    .matches(/^\S+$/, 'No Spaces allowed'),
  password: Yup.string().required('Password is a required field'),
});

interface Props extends RouteComponentProps {
  handleLogin: (opts: { username: string; token: string }) => null;
}

interface Values {
  username: string;
  password: string;
}

class UserLogin extends React.Component<Props> {
  public doLogin = async ({ username, password }: Values) => {
    const { access_token: token } = await NetworkService.getToken(
      username,
      password
    );

    if (token) {
      await this.props.handleLogin({
        username,
        token,
      });
      localStorage.setItem('login', 'true');

      this.props.history.push('/dashboard');
    }
  };

  public render() {
    return (
      <div className="login-body">
        <img src={bluLogo} alt="" className="body-corner-logo-top" />
        <div className={styles.loginContainer}>
          <div className="login-box-image-container">
            <img src={blukey} alt="" className="login-box-logo" />
          </div>

          <Formik<Values>
            initialValues={{
              username: '',
              password: '',
            }}
            validationSchema={Schema}
            onSubmit={async (values, { setSubmitting, setStatus }) => {
              try {
                setSubmitting(false);
                firebaseAnalyticsService.logEvent('login', {
                  content_id: btoa(values.username),
                  username: values.username,
                });
                await this.doLogin(values);
              } catch (e) {
                setSubmitting(false);
                setStatus(e.message || 'Login Failed');
              }
            }}
            render={({ status, handleSubmit, isSubmitting }) => (
              <Form
                autoComplete="nope"
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    handleSubmit(e);
                  }
                }}
              >
                <div className="input-container">
                  <Field
                    name="username"
                    label="Username *"
                    type="text"
                    component={TextField}
                    maxLength={50}
                    disabled={isSubmitting}
                  />
                </div>
                <div className="input-container">
                  <Field
                    name="password"
                    label="Password *"
                    type="password"
                    component={TextField}
                    maxLength={50}
                    disabled={isSubmitting}
                  />
                </div>
                <div style={{ paddingTop: 10 }}>
                  <ErrorDisplay errorText={status} />
                </div>
                {isSubmitting && (
                  <Spinner
                    name="circle"
                    color="darkblue"
                    fadeIn="none"
                    style={{ margin: '0 auto' }}
                  />
                )}
                <Button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  text="LOG IN"
                />
              </Form>
            )}
          />

          <div className={styles.links}>
            <div
              className={styles.link}
              onClick={() => {
                this.props.history.push('/register');
              }}
            >
              Register &nbsp;
            </div>

            <div
              className={styles.link}
              onClick={() => {
                this.props.history.push('/reset');
              }}
            >
              &nbsp; Forgot Password?
            </div>
            <p className={styles['need-help']}>
              Need help? Call centre: 0800 014 942
            </p>
          </div>
        </div>

        <img src={getMoreImage} alt="" className="get-more-image-bottom" />
      </div>
    );
  }
}

export default UserLogin;
