/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type DashboardProfitView_commissionSummary$ref = any;
export type DashboardProfitView_commissionSummary = ReadonlyArray<{
    readonly spoke: {
        readonly account: string;
    };
    readonly current: {
        readonly realTime: number;
        readonly monthEnd: number;
        readonly cash: number;
        readonly total: number;
    };
    readonly " $refType": DashboardProfitView_commissionSummary$ref;
}>;



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "DashboardProfitView_commissionSummary",
  "type": "CommissionSummary",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "spoke",
      "storageKey": null,
      "args": null,
      "concreteType": "Account",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "account",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "current",
      "storageKey": null,
      "args": null,
      "concreteType": "ProfitCommission",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "realTime",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "monthEnd",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "cash",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "total",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
(node as any).hash = '1d74a5da7f2992c9fd41393f32abbc4f';
export default node;
