import graphql from 'babel-plugin-relay/macro';
import { createMutation } from '@stackworx/relay';

import { PleaseCallMeMutation } from './__generated__/PleaseCallMeMutation.graphql';

const mutation = graphql`
  mutation PleaseCallMeMutation($input: PleaseCallMeMutationInput!) {
    pleaseCallMe(input: $input) {
      clientMutationId
      accepted
      rejected
    }
  }
`;

export const PleaseCallMeRequest = createMutation<PleaseCallMeMutation>(
  mutation
);
