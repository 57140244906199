/* tslint:disable no-any */
import * as React from 'react';
import Spinner from 'react-spinkit';

import Button from './Button';
import * as EmailValidator from 'email-validator';

interface Props {
  handleClick: (email: string) => Promise<any>;
}

interface State {
  email: string;
  isLoading: boolean;
  errorStatus: string;
}

export default class EmailButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      errorStatus: '',
      email: '',
    };
  }

  public showErrorMessage = () => {
    const { errorStatus, email } = this.state;
    if (errorStatus === 'Success') {
      return `Success! Email sent to ${email}`;
    }
    if (errorStatus === 'Invalid') {
      return 'Invalid email address';
    }
    return 'Email did not send successfully.';
  };

  public render() {
    const { email, isLoading, errorStatus } = this.state;
    // TODO: expanding container for input
    return (
      <div className="email-sender">
        <input
          type="text"
          onChange={e => {
            this.setState({
              email: e.target.value,
              errorStatus: '',
            });
          }}
          placeholder="email@example.com"
        />
        {errorStatus !== '' && (
          <div
            className="mail-notice"
            style={
              errorStatus === 'Success'
                ? { color: '#247D4D' }
                : { color: '#f00' }
            }
          >
            {this.showErrorMessage()}
          </div>
        )}
        <Button
          onClick={async () => {
            if (!EmailValidator.validate(email)) {
              this.setState({
                email,
                errorStatus: 'Invalid',
              });
            } else {
              try {
                this.setState({ isLoading: true, errorStatus: '' });
                await this.props.handleClick(this.state.email);
                this.setState({ errorStatus: 'Success' });
              } catch (e) {
                this.setState({ errorStatus: e.message });
              } finally {
                this.setState({ isLoading: false });
              }
            }
          }}
          text={
            isLoading ? (
              <Spinner
                name="circle"
                color="white"
                fadeIn="none"
                // @ts-ignore
                style={{
                  margin: '0 auto',
                  left: '0 !important',
                  position: 'relative !Important',
                  zIndex: '1 !important',
                }}
              />
            ) : (
              'EMAIL XLSX'
            )
          }
          type="Primary"
        />
      </div>
    );
  }
}
