import * as React from 'react';
import Button from '../../components/Button';
import styles from './ResetUserPassword.module.css';

import bluLogo from '../../../assets/images/bluLogo.svg';
import bluKey from '../../../assets/images/blukey.svg';
import getMoreImage from '../../../assets/images/get-more-done.png';

interface Props {
  username: string;
  handleSubmit: () => void;
}

class ResetPasswordDetails extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  public render() {
    return (
      <div className={styles.loginBody}>
        <img src={bluLogo} alt="" className="body-corner-logo-top" />
        <div className={styles.registerContainer}>
          <div className={styles.image}>
            <img src={bluKey} alt="" className={styles.logo} />
          </div>
          <h3 className={styles.title}>Password Reset - Success!</h3>
          <p className={styles.text}>
            You have successfuly created a new password for:
          </p>
          <h1 className={styles.heading}>{this.props.username}</h1>

          <Button onClick={this.props.handleSubmit} text="AUTO LOGIN" />
        </div>
        <img src={getMoreImage} alt="" className="get-more-image-bottom" />
        <p className={styles.helpMessage}>
          Need help? Call centre: 0800 014 942
        </p>
      </div>
    );
  }
}

export default ResetPasswordDetails;
