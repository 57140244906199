/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type AccountSelector_viewer$ref = any;
export type AccountSelector_viewer = {
    readonly user: ({
        readonly id: string;
        readonly username: string;
        readonly roles: ReadonlyArray<{
            readonly name: string;
        }>;
    }) | null;
    readonly " $refType": AccountSelector_viewer$ref;
};



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "AccountSelector_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "username",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "roles",
          "storageKey": null,
          "args": null,
          "concreteType": "Role",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = '10de32e55bc53c93bac3736ed044f556';
export default node;
