/* tslint:disable no-any */
import React from 'react';

import { getErrorMessageFromException } from '../errors';

import styles from './errorDisplay.module.css';

interface Props {
  errorText?: Error | string | null;
  retry?: () => any;
  className?: string[];
}

const ErrorDisplay = ({
  errorText,
  className,
  retry,
}: Props): React.ReactElement | null => {
  if (!errorText) {
    return null;
  }

  if (typeof errorText === 'string') {
    if (errorText === 'Failed to fetch') {
      errorText = 'Unable to connect to the server.';
    }
    return errorText !== '' ? (
      <div className={className ? className[0] : ''}>
        <span
          className={
            className ? `${className[1]} ${styles.error}` : styles.error
          }
        >
          {errorText}
        </span>
        {retry && (
          <>
            <span> </span>
            <a style={{ color: 'blue' }} onClick={() => retry()}>
              (Retry?)
            </a>
          </>
        )}
      </div>
    ) : null;
  } else if (typeof errorText === 'object') {
    return (
      <div>
        <span className={styles.error}>
          {getErrorMessageFromException(errorText)}
        </span>
        {retry && (
          <>
            <span> </span>
            <a onClick={() => retry()}>Retry?</a>
          </>
        )}
      </div>
    );
  }

  return null;
};

export default ErrorDisplay;
