import * as React from 'react';
import { createFragmentContainer } from 'react-relay';
import DataViewerStyles from './DashboardDataViewer.module.css';
import { Currency } from '../../components/Formatters';
import { DashboardProfitView_commissionSummary } from './__generated__/DashboardProfitView_commissionSummary.graphql';

import graphql from 'babel-plugin-relay/macro';

interface Props {
  commissionSummary: DashboardProfitView_commissionSummary;
  subEntityReference: string | null;
  handleClick: () => void;
}

function DashboardProfitView({
  commissionSummary,
  subEntityReference,
  handleClick,
}: Props) {
  if (!subEntityReference) {
    return null;
  }

  const getCommissionSummary = () => {
    /* tslint:disable-next-line */
    let summary: any = null;

    if (commissionSummary) {
      if (subEntityReference === 'rollup') {
        summary = commissionSummary.reduce(
          (acc, current) => {
            return {
              current: {
                realTime: acc.current.realTime + current.current.realTime,
                monthEnd: acc.current.monthEnd + current.current.monthEnd,
                cash: acc.current.cash + current.current.cash,
                total: acc.current.realTime + current.current.realTime,
              },
            };
          },
          {
            current: {
              realTime: 0,
              monthEnd: 0,
              cash: 0,
              total: 0,
            },
          }
        );
      } else {
        summary = commissionSummary.find(
          /* tslint:disable-next-line */
          summary => summary.spoke.account === subEntityReference
          /* tslint:disable-next-line */
        ) as any;
      }
    }

    return summary;
  };

  const summary = getCommissionSummary();
  const isNotNull =
    summary &&
    summary.current !== null &&
    summary.current.monthEnd !== null &&
    summary.current.cash !== null;
  const isNotUndefined =
    summary &&
    summary.current !== undefined &&
    summary.current.monthEnd !== undefined &&
    summary.current.cash !== undefined;
  return (
    <div
      onClick={() => handleClick()}
      className={DataViewerStyles['blue-block-container']}
      tabIndex={0}
      role="button"
    >
      <div className={DataViewerStyles['blue-block']}>
        <p className={DataViewerStyles['block-title']}>
          Profit Summary{' '}
          <span className={DataViewerStyles['sub-header-title']}>
            (Month to Date Profit)
          </span>
        </p>
        <div
          className={DataViewerStyles['block-text']}
          style={{ marginBottom: '20px' }}
        >
          <div>
            <div>
              <Currency>
                {summary && summary.current && summary.current.realTime}
              </Currency>
              <span className={DataViewerStyles['plain-text']}>
                Real-Time Profit
              </span>
            </div>
            <hr className={DataViewerStyles.underline} />
            <div>
              <Currency>
                {isNotNull &&
                  isNotUndefined &&
                  summary.current.monthEnd + summary.current.cash}
              </Currency>
              <span className={DataViewerStyles['plain-text']}>
                Month-End Profit
              </span>
            </div>
            <hr className={DataViewerStyles.underline} />
            <div>
              <Currency>
                {summary && summary.current && summary.current.total}
              </Currency>
              <span className={DataViewerStyles['plain-text']}>
                Total Profit
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default createFragmentContainer(DashboardProfitView, {
  commissionSummary: graphql`
    fragment DashboardProfitView_commissionSummary on CommissionSummary
      @relay(plural: true) {
      spoke {
        account
      }
      current {
        realTime
        monthEnd
        cash
        total
      }
    }
  `,
});
