/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type DashboardSummary_totalSales$ref = any;
export type DashboardSummary_totalSales = {
    readonly yesterdaysSalesToDate: number;
    readonly currentMonthToDate: number;
    readonly lastMonthMonthToDate: number;
    readonly lastMonth: number;
    readonly " $refType": DashboardSummary_totalSales$ref;
};



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "DashboardSummary_totalSales",
  "type": "DashboardCategorySales",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "yesterdaysSalesToDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "currentMonthToDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastMonthMonthToDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastMonth",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = 'f0eab3a2e338f449d28e5ad72196a6e3';
export default node;
