import { Component, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  windowSize: number;
}

class DesktopOnly extends Component<Props, State> {
  public state = {
    windowSize: window.innerWidth,
  };

  public componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  public updateDimensions = () => {
    this.setState({ windowSize: window.innerWidth });
  };

  public render() {
    if (this.state.windowSize >= 1024) {
      return this.props.children;
    }
    return null;
  }
}

export default DesktopOnly;
