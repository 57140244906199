import graphql from 'babel-plugin-relay/macro';
import { createMutation } from '@stackworx/relay';

import { ResetUserPasswordUpdatePasswordMutation } from './__generated__/ResetUserPasswordUpdatePasswordMutation.graphql';

const mutation = graphql`
  mutation ResetUserPasswordUpdatePasswordMutation(
    $input: UserResetPasswordUpdatePasswordInput!
  ) {
    userResetPasswordUpdatePassword(input: $input) {
      clientMutationId
      authToken
    }
  }
`;

export default createMutation<ResetUserPasswordUpdatePasswordMutation>(
  mutation
);
