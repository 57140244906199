/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type EntityController_viewer$ref = any;
export type EntityController_viewer = {
    readonly entities: {
        readonly total: number | null;
        readonly edges: ReadonlyArray<({
            readonly node: ({
                readonly id: string;
                readonly name: string;
                readonly accountName: string | null;
                readonly accountType: string | null;
            }) | null;
        }) | null> | null;
        readonly pageInfo: {
            readonly hasNextPage: boolean;
        };
    };
    readonly " $refType": EntityController_viewer$ref;
};



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "EntityController_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "entities",
      "storageKey": null,
      "args": null,
      "concreteType": "EntityConnection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "total",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "EntityEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "Entity",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "accountName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "accountType",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "pageInfo",
          "storageKey": null,
          "args": null,
          "concreteType": "PageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasNextPage",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
(node as any).hash = 'bd38f66a67e4e70c391dcb962db144d9';
export default node;
