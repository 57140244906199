import * as React from 'react';
import { Formik, Form, Field } from 'formik';
import Yup from 'yup';
import TextField from '../../../components/textField';
import Button from '../../../components/button';
import SelfRegistrationCompleteRegistrationMutation from '../mutations/SelfRegistrationCompleteRegistrationMutation';
import { Environment } from 'relay-runtime';
import Spinner from 'react-spinkit';
import parse from 'html-react-parser';
// TODO: add back in next sprint
// import PasswordStrengthMeter from '../../components/PasswordStrengthMeter';
import { mapErrors } from '../../../GraphQLErrorExtensionMapping';
import styles from './SelfRegistration.module.css';
import bluLogo from '../../../assets/images/blukey.svg';
import { __values } from 'tslib';
import PasswordStrengthMeter from '../../components/PasswordStrengthMeter';
import { DropdownList } from 'react-widgets';

const Schema = Yup.object().shape({
  firstName: Yup.string().required('First Name is a required field'),
  lastName: Yup.string().required('Last Name is a required field'),
  selectedRegion: Yup.string().required('Region is a required field'),
  email: Yup.string()
    .email('Not a valid email address')
    .notRequired(),
  password: Yup.string()
    .required('Password is a required field')
    .min(8, 'Password needs to be at least 8 characters long'),
  repeatPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Password do not match'
  ),
});

interface Props {
  environment: Environment;
  nextStep: () => void;
  prevStep: () => void;
  handleSubmit: (entity: Values) => void;
  values: {
    entityNumber: string;
    cellNumber: string;
    firstName: string;
    userName: string;
    lastName: string;
    email: string;
    token: string;
    authToken: string;
  };
}
interface Values {
  firstName: string;
  lastName: string;
  userName: string;
  selectedRegion: string;
  email: string;
  password: string;
  repeatPassword: string;
  token: string;
  authToken: string;
  cellNumber: string;
}
interface State {
  isLoading: boolean;
  password: string;
}
class UserDetails extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      password: '',
    };
  }
  public back = e => {
    e.preventDefault();
    this.props.prevStep();
    this.props.prevStep();
  };

  public render() {
    const { environment } = this.props;
    const regions = [
      { id: 'CAPE_TOWN', region: 'Cape Town' },
      { id: 'NORTHERN_CAPE', region: 'Northern Cape' },
      { id: 'WESTERN_CAPE', region: 'Western Cape' },
      { id: 'KWAZULU_NATAL', region: 'KwaZulu-Natal' },
      { id: 'FREE_STATE', region: 'Free State' },
      { id: 'GAUTENG', region: 'Gauteng' },
      { id: 'NORTH_WEST', region: 'North West' },
      { id: 'MPUMALANGA', region: 'Mpumalanga' },
      { id: 'LIMPOPO', region: 'Limpopo' },
    ];

    return (
      <div className={styles.loginBody}>
        <div className={styles.registerContainer}>
          <div className={styles.image}>
            <img src={bluLogo} alt="" className={styles.logo} />
          </div>
          <h3 className={styles.title}>Self Registration - User Details</h3>
          <p className={styles.text}>
            Please provide the below personal details in order to complete your
            account registration.
          </p>

          <Formik<Values>
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              userName: '',
              selectedRegion: '',
              password: '',
              repeatPassword: '',
              token: '',
              authToken: '',
              entityNumber: this.props.values.entityNumber,
              cellNumber: this.props.values.cellNumber,
            }}
            validationSchema={Schema}
            onSubmit={async (values, formikActions) => {
              try {
                formikActions.setSubmitting(true);
                formikActions.setStatus(null);
                this.setState({
                  isLoading: true,
                });
                const data = await SelfRegistrationCompleteRegistrationMutation(
                  environment,
                  {
                    input: {
                      firstname: values.firstName.replace(/ /g, ''),
                      lastname: values.lastName.replace(/ /g, ''),
                      password: values.password,
                      userDefinedRegion: values.selectedRegion,
                      email: values.email,
                      cellNumber: this.props.values.cellNumber,
                      token: this.props.values.token,
                      entitynumber: this.props.values.entityNumber,
                    },
                  }
                );

                values.userName = data!.selfRegistrationCompleteRegistration!.username.replace(
                  / /g,
                  ''
                );
                values.authToken = data!.selfRegistrationCompleteRegistration!.token;

                this.setState({
                  isLoading: false,
                });

                this.props.handleSubmit(values);
                this.props.nextStep();
              } catch (e) {
                formikActions.setSubmitting(false);
                this.setState({
                  isLoading: false,
                });
                if (e.extensions.code === 'INVALID_MUTATION_INPUT') {
                  mapErrors(e, formikActions, {
                    email: 'email',
                  });
                } else {
                  formikActions.setStatus(
                    e.message +
                      ' please consider to <br /> <a href="/login">login</a> if you have an account or <a href="/reset">reset password</a> if you have lost your password' ||
                      'user Details are incorrect'
                  );
                }
              }
            }}
            render={({
              status,
              handleSubmit,
              isSubmitting,
              errors,
              values,
              setFieldValue,
              touched,
            }) => (
              <Form
                autoComplete="nope"
                onKeyPress={e => {
                  if (e.key === 'Enter') {
                    handleSubmit(e);
                  }
                }}
              >
                <div className="input-container">
                  <Field
                    name="firstName"
                    label="First Name *"
                    placeholder="e.g. Peter"
                    component={TextField}
                    disabled={isSubmitting}
                  />
                </div>
                <div className="input-container">
                  <Field
                    type="text"
                    name="lastName"
                    label="Last Name *"
                    placeholder="e.g. Peterson"
                    component={TextField}
                    disabled={isSubmitting}
                  />
                </div>
                <div className="input-container combo-parent">
                  <Field
                    name="selectedRegion"
                    label="Region *"
                    placeholder="Select your region"
                    data={regions.map(item => item.region)}
                    component={DropdownList}
                    containerClassName={`dropdown-list${
                      errors.selectedRegion ? ' dropdown-list-error' : ''
                    }`}
                    onChange={value => {
                      setFieldValue('selectedRegion', value);
                    }}
                  />
                  {errors.selectedRegion && <p>{errors.selectedRegion}</p>}
                </div>

                <div className="input-container negative-margin">
                  <Field
                    name="email"
                    label="Email"
                    disableAutocomplete={true}
                    placeholder="e.g. peter@example.com"
                    component={TextField}
                    disabled={isSubmitting}
                  />
                </div>
                <PasswordStrengthMeter password={values.password} />
                <div className="input-container">
                  <Field
                    name="password"
                    label="Password *"
                    type="password"
                    disableAutocomplete={true}
                    placeholder="Must have at least 8 characters"
                    component={TextField}
                    disabled={isSubmitting}
                  />
                  {/* <PasswordStrengthMeter password={values.password} /> */}
                </div>

                <div className="input-container">
                  <Field
                    name="repeatPassword"
                    label="Repeat Password *"
                    type="password"
                    disableAutocomplete={true}
                    component={TextField}
                    disabled={isSubmitting}
                  />
                </div>

                {status && <div className={styles.status}>{parse(status)}</div>}

                <Button
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  text={
                    this.state.isLoading ? (
                      <Spinner
                        name="circle"
                        color="white"
                        fadeIn="none"
                        // @ts-ignore
                        style={{
                          margin: '0 auto',
                          left: '0 !important',
                          position: 'relative !Important',
                          zIndex: '1 !important',
                        }}
                      />
                    ) : (
                      'REGISTER'
                    )
                  }
                />
              </Form>
            )}
          />

          <div className="forgot-link">
            <a href="#" onClick={this.back}>
              &#60; Cancel
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default UserDetails;
