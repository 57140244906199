import graphql from 'babel-plugin-relay/macro';
import { createMutation } from '@stackworx/relay';

import { ProductDeleteMutation } from './__generated__/ProductDeleteMutation.graphql';

const mutation = graphql`
  mutation ProductDeleteMutation($input: ProductDeleteInput!) {
    productDelete(input: $input) {
      clientMutationId
    }
  }
`;

export default createMutation<ProductDeleteMutation>(mutation);
