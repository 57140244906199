// Must be first to load polyfills
import './bootstrap';
import './firebaseSetup';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import App from './App';
import AuthPersistGate from './modules/components/AuthPersistGate';
import { AuthContextType } from './contexts/AuthContext';

// Removed Service worker to fix caching problem
// import registerServiceWorker from './registerServiceWorker';
// const App = () => !!Object.values ? 'Yes' : 'no';
ReactDOM.render(
  <AuthPersistGate>
    {(auth: AuthContextType) => <App auth={auth} />}
  </AuthPersistGate>,
  document.getElementById('root')
);
// registerServiceWorker();
