/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
type AccountSelector_viewer$ref = any;
type DashboardDataBlock_category$ref = any;
type DashboardNegativeSummary_viewer$ref = any;
type DashboardSearch_account$ref = any;
type DashboardSummary_accountSummary$ref = any;
type DashboardSummary_commissionSummary$ref = any;
type DashboardSummary_totalSales$ref = any;
type EntityCombobox_viewer$ref = any;
export type SalesCategory = "AIRTIME" | "DATA" | "DSTV" | "ELECTRICITY" | "HOLLYWOOD_BETS" | "LOTTO" | "M2M" | "OTHER" | "RICA_FAILED" | "RICA_SUCCESS" | "RICA_TOTAL" | "TOTAL_SALES" | "UNIPIN" | "%future added value";
export type DashboardDataViewer_viewer$ref = any;
export type DashboardDataViewer_viewer = {
    readonly user: ({
        readonly roles: ReadonlyArray<{
            readonly name: string;
        }>;
    }) | null;
    readonly report: ({
        readonly commissionSummary: ReadonlyArray<{
            readonly " $fragmentRefs": DashboardSummary_commissionSummary$ref;
        }> | null;
        readonly totalSalesAllSpokes: ReadonlyArray<({
            readonly Spoke: string;
            readonly TotalSales: number;
            readonly SpokeAcc: string;
            readonly HUB: string;
            readonly AirtimeSales: number;
            readonly Data_Sales: number;
            readonly Electricity_Sales: number;
            readonly HBets_Sales: number;
            readonly Unipin_Sales: number;
            readonly DSTV_Sales: number;
            readonly Lotto_Sales: number;
            readonly M2M_Sales: number;
            readonly Other_Sales: number;
            readonly AirtimeSalesCount: number | null;
            readonly DataSalesCount: number | null;
            readonly ElectricitySalesCount: number | null;
            readonly HBetsSalesCount: number | null;
            readonly UnipinSalesCount: number | null;
            readonly DSTVSalesCount: number | null;
            readonly LottoSalesCount: number | null;
            readonly M2MSalesCount: number | null;
            readonly OtherSalesCount: number | null;
        }) | null> | null;
    }) | null;
    readonly dashboard: ({
        readonly hub: ({
            readonly account: string;
            readonly categories: ReadonlyArray<{
                readonly category: SalesCategory;
                readonly " $fragmentRefs": DashboardDataBlock_category$ref & DashboardSummary_totalSales$ref;
            }>;
            readonly " $fragmentRefs": DashboardSearch_account$ref & DashboardSummary_accountSummary$ref;
        }) | null;
        readonly rollup: ({
            readonly account: string;
            readonly categories: ReadonlyArray<{
                readonly category: SalesCategory;
                readonly " $fragmentRefs": DashboardDataBlock_category$ref & DashboardSummary_totalSales$ref;
            }>;
            readonly " $fragmentRefs": DashboardSearch_account$ref & DashboardSummary_accountSummary$ref;
        }) | null;
        readonly spokes: ReadonlyArray<{
            readonly account: string;
            readonly categories: ReadonlyArray<{
                readonly category: SalesCategory;
                readonly " $fragmentRefs": DashboardDataBlock_category$ref & DashboardSummary_totalSales$ref;
            }>;
            readonly " $fragmentRefs": DashboardSearch_account$ref & DashboardSummary_accountSummary$ref;
        }>;
    }) | null;
    readonly " $fragmentRefs": AccountSelector_viewer$ref & EntityCombobox_viewer$ref & DashboardNegativeSummary_viewer$ref;
    readonly " $refType": DashboardDataViewer_viewer$ref;
};



const node: ConcreteFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "entityReference",
  "variableName": "entityReference",
  "type": "String!"
},
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "account",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "FragmentSpread",
    "name": "DashboardSearch_account",
    "args": null
  },
  {
    "kind": "FragmentSpread",
    "name": "DashboardSummary_accountSummary",
    "args": null
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "categories",
    "storageKey": null,
    "args": null,
    "concreteType": "DashboardCategorySales",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "category",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "FragmentSpread",
        "name": "DashboardDataBlock_category",
        "args": null
      },
      {
        "kind": "FragmentSpread",
        "name": "DashboardSummary_totalSales",
        "args": null
      }
    ]
  }
];
return {
  "kind": "Fragment",
  "name": "DashboardDataViewer_viewer",
  "type": "Viewer",
  "metadata": null,
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "entityReference",
      "type": "String!"
    },
    {
      "kind": "RootArgument",
      "name": "startDate",
      "type": "Date!"
    },
    {
      "kind": "RootArgument",
      "name": "endDate",
      "type": "Date!"
    }
  ],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "AccountSelector_viewer",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "user",
      "storageKey": null,
      "args": null,
      "concreteType": "User",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "roles",
          "storageKey": null,
          "args": null,
          "concreteType": "Role",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "report",
      "storageKey": null,
      "args": null,
      "concreteType": "Report",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "commissionSummary",
          "storageKey": null,
          "args": [
            v0
          ],
          "concreteType": "CommissionSummary",
          "plural": true,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "DashboardSummary_commissionSummary",
              "args": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "totalSalesAllSpokes",
          "storageKey": null,
          "args": [
            {
              "kind": "Variable",
              "name": "endDate",
              "variableName": "endDate",
              "type": "Date!"
            },
            v0,
            {
              "kind": "Variable",
              "name": "startDate",
              "variableName": "startDate",
              "type": "Date!"
            }
          ],
          "concreteType": "TotalSalesReportAllSpokes",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "Spoke",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "TotalSales",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "SpokeAcc",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "HUB",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "AirtimeSales",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "Data_Sales",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "Electricity_Sales",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "HBets_Sales",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "Unipin_Sales",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "DSTV_Sales",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "Lotto_Sales",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "M2M_Sales",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "Other_Sales",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "AirtimeSalesCount",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "DataSalesCount",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "ElectricitySalesCount",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "HBetsSalesCount",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "UnipinSalesCount",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "DSTVSalesCount",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "LottoSalesCount",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "M2MSalesCount",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "OtherSalesCount",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "EntityCombobox_viewer",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "DashboardNegativeSummary_viewer",
      "args": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "dashboard",
      "storageKey": null,
      "args": [
        {
          "kind": "Variable",
          "name": "entityReference",
          "variableName": "entityReference",
          "type": "String"
        }
      ],
      "concreteType": "Dashboard",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "hub",
          "storageKey": null,
          "args": null,
          "concreteType": "DashboardHubAccount",
          "plural": false,
          "selections": v1
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "rollup",
          "storageKey": null,
          "args": null,
          "concreteType": "DashboardHubAccount",
          "plural": false,
          "selections": v1
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "spokes",
          "storageKey": null,
          "args": null,
          "concreteType": "DashboardSpokeAccount",
          "plural": true,
          "selections": v1
        }
      ]
    }
  ]
};
})();
(node as any).hash = '6f0483ea143a76c49b2ba572a9b976e3';
export default node;
