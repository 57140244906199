/* tslint:disable no-any */
import * as React from 'react';

export interface EntitySelectorState {
  selectedEntityReference: any;
  userEntities: any;
  entityToggler: (selectedEntity: string) => void;
}

export default React.createContext<EntitySelectorState>({
  selectedEntityReference: null,
  userEntities: null,
  entityToggler: (selectedEntity: string) => selectedEntity,
});
