import * as React from 'react';
import { History } from 'history';

import SelfRegistrationStart from './components/SelfRegistrationStart';
import SelfRegistrationOtp from './components/SelfRegistrationOneTimePin';
import SelfRegistrationUserDetails from './components/SelfRegistrationUserDetails';

import UserNameDetails from './components/SelfRegistrationUserNameDetails';
import RelayContext from '../../contexts/RelayContext';

interface State extends UserDetailValues, OTPvalues {
  step: number;
  entityNumber: string;
  cellNumber: string;
}

interface Props {
  history: History;
  handleLogin: (opts: { username: string; token: string }) => void;
}

interface OTPvalues {
  OTP: string;
  token: string;
}

interface UserDetailValues {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
  authToken: string;
}
interface RegisterValues {
  entityNumber: string;
  cellNumber: string;
}

class SelfRegistration extends React.Component<Props, State> {
  public static contextType = RelayContext;

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      entityNumber: '',
      cellNumber: '',
      OTP: '',
      token: '',
      authToken: '',
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      password: '',
    };
  }

  public nextStep = () => {
    this.setState(prevState => ({
      step: prevState.step + 1,
    }));
  };

  public prevStep = () => {
    this.setState(prevState => ({
      step: prevState.step - 1,
    }));
  };
  public handleSubmit = async () => {
    await this.props.handleLogin({
      username: this.state.userName,
      token: this.state.authToken,
    });

    this.props.history.push('/dashboard');
  };
  public render() {
    const { step } = this.state;
    const {
      entityNumber,
      cellNumber,
      firstName,
      lastName,
      userName,
      email,
      password,
      token,
      OTP,
      authToken,
    } = this.state;
    const formValues = {
      entityNumber,
      cellNumber,
      firstName,
      lastName,
      userName,
      email,
      password,
      OTP,
      token,
      authToken,
    };

    switch (step) {
      case 1:
        return (
          <SelfRegistrationStart
            environment={this.context}
            nextStep={this.nextStep}
            handleSubmit={(value: RegisterValues) => {
              this.setState({
                entityNumber: value.entityNumber,
                cellNumber: value.cellNumber,
              });
            }}
            values={formValues}
          />
        );
      case 2:
        return (
          <SelfRegistrationOtp
            environment={this.context}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleSubmit={(OTP2: string, Token: string) => {
              this.setState({
                OTP: OTP2,
                token: Token,
              });
            }}
            values={formValues}
          />
        );
      case 3:
        return (
          <SelfRegistrationUserDetails
            environment={this.context}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleSubmit={(values: UserDetailValues) => {
              this.setState({
                firstName: values.firstName.replace(/ /g, ''),
                lastName: values.lastName.replace(/ /g, ''),
                userName: values.userName.replace(/ /g, ''),
                email: values.email,
                password: values.password,
                authToken: values.authToken,
              });
            }}
            values={formValues}
          />
        );
      case 4:
        return (
          <UserNameDetails
            username={this.state.userName}
            handleSubmit={this.handleSubmit}
          />
        );
    }
  }
}

export default SelfRegistration;
