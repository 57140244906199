/* tslint:disable no-any */
import React, { Component } from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { DropdownList } from 'react-widgets';

import { EntityCombobox_viewer } from './__generated__/EntityCombobox_viewer.graphql';
import MediaQuery from './MediaQuery';
import EntityComboboxModal from './EntityComboboxModal';

interface Props {
  loading: boolean;
  value: string | null;
  includeRollup?: boolean;
  viewer: EntityCombobox_viewer;
  handleReset: () => void;
  handleChange: (value: string | null) => void;
  isAccountSelector?: boolean;
}

interface State {
  isModalOpen: boolean;
}

export interface EntityData {
  text: string;
  type: string;
  name: string;
  account: string;
  accountName?: string;
}

const GroupComponent = ({ item }: any) => <strong>{item}</strong>;

class EntityCombobox extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  public openCloseModal(predicate: boolean) {
    this.setState({
      isModalOpen: predicate,
    });
  }

  public render() {
    const {
      loading,
      viewer,
      handleChange,
      value,
      includeRollup = false,
      isAccountSelector,
    } = this.props;
    const { isModalOpen } = this.state;

    const data = [
      ...viewer.linkedEntities.map(entity => ({
        ...entity,
        // We need to remove this and build the text ourselves during
        // rendering so that we can be able to style this accordingly
        text: `${entity.account} - ${entity.name}`,
      })),
    ];

    if (includeRollup) {
      data.push({
        type: 'Total',
        text: 'Totals (Hub + Spoke)',
        name: '',
        account: 'rollup',
      });
    }

    return (
      <div className="entity-combobox">
        <EntityComboboxModal
          open={isModalOpen}
          closeModal={() => this.openCloseModal(!isModalOpen)}
          // Casting this to any as not sure what it is at this point
          entityData={data as any}
          handleChange={(account: string) => handleChange(account)}
          includeRollup={includeRollup}
          isAccountSelector={isAccountSelector}
        />
        <MediaQuery>
          {({ isMobile }: { isMobile: boolean }) => (
            <div
              onClick={() => (!isMobile ? null : this.openCloseModal(isMobile))}
              className="combo-container"
            >
              <div className="droplistContainer">
                <DropdownList
                  containerClassName="entity-selector"
                  readOnly={isMobile}
                  disabled={loading}
                  busy={loading}
                  data={data}
                  groupBy={entity => entity.type}
                  groupComponent={GroupComponent}
                  value={value}
                  onChange={(entity: EntityData) => {
                    if (value) {
                      handleChange(entity.account);
                    } else {
                      handleChange(null);
                    }
                  }}
                  caseSensitive={false}
                  filter="contains"
                  valueField="account"
                  textField="text"
                />
              </div>
            </div>
          )}
        </MediaQuery>
      </div>
    );
  }
}

export default createFragmentContainer(EntityCombobox, {
  viewer: graphql`
    fragment EntityCombobox_viewer on Viewer {
      linkedEntities(entityReference: $entityReference) {
        type
        name
        account
      }
    }
  `,
});
