import graphql from 'babel-plugin-relay/macro';
import { createMutation } from '@stackworx/relay';

import { SelfRegistrationCompleteRegistrationMutation } from './__generated__/SelfRegistrationCompleteRegistrationMutation.graphql';

const mutation = graphql`
  mutation SelfRegistrationCompleteRegistrationMutation(
    $input: SelfRegistrationCompleteRegistrationInput!
  ) {
    selfRegistrationCompleteRegistration(input: $input) {
      clientMutationId
      token
      username
    }
  }
`;
export default createMutation<SelfRegistrationCompleteRegistrationMutation>(
  mutation
);
