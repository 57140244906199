export const tourDesktopDataStore = [
  [
    {
      heading: 'Dashboard',
      caption: `Once you're logged in, the home page or dashboard shows the following items:`,
      imageName: 'tour-desktop/Tutorial-Step-1.jpg',
      explanations: [
        '1. The Profit report provides a breakdown between what profit is received on a real time basis and what profit will be paid at month end. The profit summary on the dashboard provides you with the MTD profit earned in terms of real time and month end profit',
        '2. Your available trading balance.',
        `3. Your last deposited amount into your trading balance and date of the deposit.`,
        `4. A summary of the sales which you have conducted through your Blu Approved trading device is split into 4 sales categories
         
           • Sales previous day
           • Sales month to date
           • Sales comparison to the previous month to date
           • Sales full prior month`,
        `5. Each product which you have sold through your Blu Approved trading device is split into the same 4 sales categories
        
           • Sales previous day 
           • Sales month to date 
           • Sales comparison to the previous month to date 
           • Sales full prior month`,
      ],
    },
  ],
  [
    {
      heading: 'Reports',
      caption: `By accessing the Reports button, the following information is available:`,
      imageName: 'tour-desktop/Tutorial-Step-2.1.jpg',
      explanations: [
        '1. Contact details – as they appear in the Blu Approved Sales system',
        '2. Total Sales for a given period',
        '3. Profit reports',
      ],
    },
    {
      imageName: 'tour-desktop/Tutorial-Step-2.2.jpg',
      explanations: [
        '4. Please verify if your contact details have been captured correctly. Should a change be required, please contact the Blu Approved Customer Interaction Centre on 0800 014 942',
      ],
    },
    {
      imageName: 'tour-desktop/Tutorial-Step-2.3.jpg',
      explanations: [
        '5. For Total Sales, you can select a data range to view a detailed breakdown of product sales within a specific period',
      ],
    },
    {
      imageName: 'tour-desktop/Tutorial-Step-2.4.jpg',
      explanations: [
        '6. You are able to email or download the Total Sales report using the provided functionality.',
      ],
    },
    {
      imageName: 'tour-desktop/Tutorial-Step-2.5.jpg',
      explanations: [
        `7. The Profit report provides a breakdown between what profit is received on a real time basis and what profit will be paid at month end. Note that some merchants have opted for all their profit to be paid at month end, and in such instances, they should consult the ‘Total Profit’ column that will contain the sum total of what will be paid by the 15th of the following month.

What products are relevant for real time profit and what does it mean?
          
Real time profit refers to when merchants are invoiced at a discounted value for their sales as and when vouchers are sold. For example, a merchant might sell a R100 voucher on which he earns 2% commission, and his balance will only reduce by R98, meaning he earned R2 real time.

Profit categories:

1. Profit earned as a Live discount for the sale of: Hollywood Bets vouchers, Pinned airtime and data, and Unipin. Profit for customers on the ‘Face Value’ commercial plan is included under the Month-End column.

2. Profit earned at Month-End for the sale of: Online electricity, Prepaid water, Direct airtime and data topups, Chat for Change, Lotto, Bill and Fine payments, DSTV payments, Merchant 2 Merchant convenience fees, Money transfers.`,
      ],
    },
  ],
  [
    {
      heading: 'Change Password',
      caption: `Changing your password on the login screen:`,
      imageName: 'tour-desktop/Tutorial-Step-3.1.jpg',
      explanations: [
        `1. If you have forgotten your password or if you have to change your password, select 'Forgot Password' on the login screen which will prompt you to capture your Username.`,
      ],
    },
    {
      imageName: 'tour-desktop/Tutorial-Step-3.2.jpg',
      explanations: [
        `2. Select 'Request OTP' - a one time pin that will be sent to your cell phone number to authorise the changing of the password`,
      ],
    },
  ],
  [
    {
      heading: 'Notifications',
      caption: `By accessing the notification button, the following information is available`,
      imageName: 'tour-desktop/Tutorial-Step-4.jpg',
      explanations: [
        `1. Blu Approved will use the Notifications tool to communicate directly with you around exciting product launches, promotions and information which relates to your Blu Approved account.`,
      ],
    },
  ],
  [
    {
      heading: 'Products',
      caption: `By accessing the product button, the following information is available:`,
      imageName: 'tour-desktop/Tutorial-Step-5.jpg',
      explanations: [`All current and new products`],
    },
  ],
  [
    {
      heading: 'Help Function',
      caption: `By accessing the help button, you can view useful help and "frequently asked questions" related content:`,
      imageName: 'tour-desktop/Tutorial-Step-6.jpg',
      explanations: [''],
    },
  ],
];
