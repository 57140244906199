import graphql from 'babel-plugin-relay/macro';
import { createMutation } from '@stackworx/relay';

import { EmailDrilldownMutation } from './__generated__/EmailDrilldownMutation.graphql';

const mutation = graphql`
  mutation EmailDrilldownMutation($input: EmailDrilldownMutationInput!) {
    emailDrilldown(input: $input) {
      clientMutationId
      accepted
      rejected
    }
  }
`;

export default createMutation<EmailDrilldownMutation>(mutation);
