import React, { useState } from 'react';
import styles from './DashboardNewProductsModal.module.css';
import ViewProducts from '../../Products/ViewProducts/ViewProducts';
import closeImage from '../../../assets/images/close-blue.svg';
import chevronDown from '../../../assets/images/chevron-down.svg';

export const DashboardNewProductsModal = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className={open ? styles.root : styles.rootClosed}>
      {open ? (
        <div>
          <img
            src={closeImage}
            className={styles.openImage}
            onClick={() => setOpen(false)}
            tabIndex={0}
            role="button"
            style={{ fill: 'red' }}
          />
          <ViewProducts />
        </div>
      ) : (
        <div
          className={styles.header}
          onClick={() => setOpen(true)}
          role="button"
          tabIndex={0}
        >
          <p>New Products</p>
          <img src={chevronDown} />
        </div>
      )}
    </div>
  );
};
