import * as React from 'react';
import ReactMoment from 'react-moment';

const roundToTwo = (value: number) => {
  const rounded = Math.round(value * 100) / 100;
  const numStr = rounded.toString();
  const numSplit = numStr.split('.');
  let decimal = numSplit[1] || '00';
  if (decimal.length === 1) {
    decimal = decimal + '0';
  }
  return numSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + `.${decimal}`;
};

export function Currency({ children }: { children: number }) {
  if (children != null) {
    return <span>R {roundToTwo(children)}</span>;
  }

  return null;
}

export function Number({ children }: { children: number }) {
  if (children != null) {
    return <span>{roundToTwo(children)}</span>;
  }

  return null;
}

export function Date({ children }: { children: string }) {
  if (children != null) {
    return <ReactMoment format="DD/MM/YYYY">{children}</ReactMoment>;
  }
  return null;
}

export function DateTime({
  children,
  format = 'DD/MM/YYYY HH:mm:ss',
}: {
  children: string;
  format?: string;
}) {
  if (children != null) {
    return <ReactMoment format={format}>{children}</ReactMoment>;
  }
  return null;
}
