/* tslint:disable no-any */
import React from 'react';
import Moment from 'react-moment';
import { ConfigService } from '@stackworx/react';
import ReactTable from 'react-table';
import Spinner from 'react-spinkit';
import { DropdownList } from 'react-widgets';

import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import EmailButton from '../../components/EmailButton';

import './DashboardViewDataModal.css';
import EmailDrilldownMutation from '../mutations/EmailDrilldownMutation';
import ViewDataModalStyle from './DashboardViewDataModal.module.css';
import DashboardCategories from './DashboardCategories';
import ExcelDownloadButton from '../../components/ExcelDownloadButton';

import { DashboardViewDataModal_viewer } from './__generated__/DashboardViewDataModal_viewer.graphql';
import { Environment } from 'relay-runtime';

interface Props {
  onClose: () => void;
  environment: Environment;
  isLoading: boolean;
  selectedEntityReference: string;
  authToken: string;
  viewer: DashboardViewDataModal_viewer;
  categoryType: string;
  handleCategoryChange: (category: string) => void;
}

interface ContentProps {
  buildDrillDownUri: (category: string, entityReference: string) => string;
  onClose: () => void;
  isLoading: boolean;
  category: string;
  handleCategoryChange: (category: string) => void;
  // TOOD: remove
  data: any;
  columns: any;
  entityReference: string;
  environment: Environment;
}

interface Category {
  category: string;
  cellphoneNumber: string;
  currentMonthToDate: number;
  email: string;
  lastMonth: number;
  lastMonthMonthToDate: number;
  yesterdaysSalesToDate: number;
}

const ComboItem = (data: any) => <span>{data.item.value}</span>;

const comboBoxCategories = Object.entries(DashboardCategories).map(
  ([key, value]) => ({
    key,
    value,
  })
);

function Content({
  onClose,
  isLoading,
  category,
  handleCategoryChange,
  data,
  columns,
  environment,
  entityReference,
  buildDrillDownUri,
}: ContentProps) {
  const heading = DashboardCategories[category];

  if (!heading) {
    throw new Error(`Invalid Category: ${category}`);
  }

  const subheading = `Drilldown of ${heading}`;

  return (
    <div className="outer-container">
      <div
        id="modal-overlay"
        role="button"
        className="modal-overlay"
        onClick={onClose}
        tabIndex={0}
      />
      <div id="inner-body" className="modal-body drilldown">
        <div
          className="modal-close"
          onClick={onClose}
          role="button"
          tabIndex={0}
        >
          x
        </div>
        {isLoading ? (
          <Spinner
            className="data-viewer"
            name="circle"
            color="darkblue"
            fadeIn="none"
            style={{ margin: '0 auto' }}
          />
        ) : (
          <div id="data-body" className="data-viewer">
            <h4>{heading}</h4>
            <p className="last-update">{subheading}</p>
            <DropdownList
              data={comboBoxCategories}
              itemComponent={ComboItem}
              defaultValue={category}
              value={heading}
              // inputProps={{ readOnly: true }}
              onChange={textInput => {
                if (typeof textInput === 'object') {
                  handleCategoryChange(textInput.key);
                } // else user is typing
              }}
              textField="value"
              valueField="key"
              minLength={2}
            />
            <div className={ViewDataModalStyle['horizontal-scrolling']}>
              {'<- Scroll horizontally to see all data ->'}
            </div>
            <ReactTable
              className="data-table -striped"
              data={data}
              columns={columns}
              minRows={5}
              noDataText="Loading error"
              showPagination
              showPageSizeOptions={false}
              defaultPageSize={20}
              defaultSorting={[
                {
                  id: 'SpokeName',
                  desc: undefined, // not necessary unless set to true, but it's good to know its there.
                },
              ]}
            />
            <div className="button-container push-down-20">
              <EmailButton
                handleClick={async (email: string) => {
                  await EmailDrilldownMutation(environment, {
                    input: {
                      email: [email],
                      category,
                      entityRef: entityReference,
                    },
                  });
                }}
              />
              <ExcelDownloadButton
                href={buildDrillDownUri(category, entityReference)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

class DashboardViewDataModal extends React.Component<Props> {
  public buildDrillDownUri(category: string, entityReference: string): string {
    return encodeURI(
      `${
        ConfigService.serverUri
      }/report/drillDown?category=${category}&entityRef=${entityReference}&token=${
        this.props.authToken
      }`
    );
  }

  public CreateDate = () => {
    const now = new Date();
    const y = now.getFullYear();
    const m = now.getMonth() + 1;
    const d = now.getDate();
    const h = now.getHours();
    const min = now.getMinutes();
    return `${d < 10 ? '0' : ''}${d}${m < 10 ? '0' : ''}${m}${y}-${h}-${min}`;
  };

  public getCategory(categories: any[], category: string): Category {
    const c = categories.find(cat => cat.category === category) as Category;

    if (!c) {
      throw new Error(`Could not find Category: ${category}`);
    }
    return c;
  }

  public render() {
    const {
      categoryType,
      viewer,
      environment,
      isLoading,
      onClose,
      selectedEntityReference,
      handleCategoryChange,
    } = this.props;

    const columns = [
      {
        Header: 'Spoke',
        accessor: 'account',
        Cell: ({ value }: any) => {
          return <div className="table-cell">{value}</div>;
        },
        width:
          window.innerWidth <= 425
            ? 120
            : window.innerWidth > 425 && window.innerWidth < 1023
            ? 200
            : undefined,
      },
      {
        Header: 'Spoke Name',
        accessor: 'name',
        Cell: ({ value }) => <div className="table-cell">{value}</div>,
        width: window.innerWidth < 1023 ? 200 : undefined,
      },
      {
        Header: 'Email',
        accessor: 'categories',
        Cell: ({ value }: any) => {
          return (
            <div className="table-cell">
              {this.getCategory(value, 'TOTAL_SALES').email}
            </div>
          );
        },
        width: window.innerWidth < 1023 ? 200 : undefined,
      },
      {
        Header: 'Cell Number',
        accessor: 'categories',
        Cell: ({ value }: any) => {
          return (
            <div className="table-cell">
              {this.getCategory(value, 'TOTAL_SALES').cellphoneNumber}
            </div>
          );
        },
        width: window.innerWidth < 1023 ? 200 : undefined,
      },
    ];

    if (this.props.categoryType === 'AVAILABLE_BALANCE') {
      columns.splice(2, 0, {
        Header: 'Available Balance',
        accessor: 'availableBalance',
        Cell: ({ value }: any) => (
          <div className="table-cell amount">R {value}</div>
        ),
        width: window.innerWidth < 1023 ? 200 : undefined,
      });
    } else if (this.props.categoryType === 'LAST_PAID') {
      columns.splice(2, 0, {
        Header: 'Last Paid Date',
        accessor: 'lastPaid.date',
        Cell: ({ value }: any) => (
          <div className="table-cell">
            <Moment format="DD/MM/YYYY">{value}</Moment>
          </div>
        ),
        width: window.innerWidth < 1023 ? 200 : undefined,
      });
      columns.splice(2, 0, {
        Header: 'Last Paid Amount',
        accessor: 'lastPaid.amount',
        Cell: ({ value }: any) => (
          <div className="table-cell amount">R {value}</div>
        ),
        width: window.innerWidth < 1023 ? 200 : undefined,
      });
    } else {
      columns.splice(2, 0, {
        Header: 'LMth',
        accessor: 'categories',
        Cell: ({ value }: any) => (
          <div className="table-cell amount">
            R {this.getCategory(value, categoryType).lastMonth}
          </div>
        ),
        width: window.innerWidth < 1023 ? 200 : undefined,
      });
      columns.splice(2, 0, {
        Header: 'LstMTD',
        accessor: 'categories',
        Cell: ({ value }: any) => (
          <div className="table-cell amount">
            R {this.getCategory(value, categoryType).lastMonthMonthToDate}
          </div>
        ),
        width: window.innerWidth < 1023 ? 200 : undefined,
      });
      columns.splice(2, 0, {
        Header: 'MTD',
        accessor: 'categories',
        Cell: ({ value }: any) => (
          <div className="table-cell amount">
            R {this.getCategory(value, categoryType).currentMonthToDate}
          </div>
        ),
        width: window.innerWidth < 1023 ? 200 : undefined,
      });
      columns.splice(2, 0, {
        Header: 'YsTD',
        accessor: 'categories',
        Cell: ({ value }: any) => (
          <div className="table-cell amount">
            R {this.getCategory(value, categoryType).yesterdaysSalesToDate}
          </div>
        ),
        width: window.innerWidth < 1023 ? 200 : undefined,
      });
    }

    let data: any[] = [];

    if (viewer.dashboard!.hub) {
      data.push(viewer.dashboard!.hub);
    }

    data = [...data, ...viewer.dashboard!.spokes];

    return (
      <Content
        columns={columns}
        onClose={onClose}
        environment={environment}
        isLoading={isLoading}
        buildDrillDownUri={(category, entityReference) => {
          return this.buildDrillDownUri(category, entityReference);
        }}
        data={data}
        category={categoryType}
        entityReference={selectedEntityReference}
        handleCategoryChange={handleCategoryChange}
      />
    );
  }
}

export default createFragmentContainer(DashboardViewDataModal, {
  viewer: graphql`
    fragment DashboardViewDataModal_viewer on Viewer {
      ...EntityCombobox_viewer
      dashboard(entityReference: $entityReference) {
        hub {
          account

          name
          lastPaid {
            amount
            date
          }
          availableBalance
          categories {
            category
            lastMonth
            currentMonthToDate
            lastMonthMonthToDate
            email
            cellphoneNumber
          }
        }
        spokes {
          account
          name
          lastPaid {
            amount
            date
          }
          availableBalance
          categories {
            category
            yesterdaysSalesToDate
            currentMonthToDate
            lastMonth
            lastMonthMonthToDate
            email
            cellphoneNumber
          }
        }
      }
    }
  `,
});
