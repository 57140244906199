import { FormikActions } from 'formik';

import { getErrorMessageFromException } from './errors';

/**
 * Maps BAD_USER_INPUT apollo server extension into a list of field keys and errors
 */

interface ErrorMapping {
  error: string; // Top level error
  // Field level errors
  fields: { [key: string]: string };
}

interface GraphQLError extends Error {
  extensions?: {
    code: string;
    exception: {
      message: string;
      stacktrace: string[];
      // [key: string]: string;
    };
  };
}

export function mapErrors<T>(
  error: GraphQLError,
  formikActions?: FormikActions<T>,
  formikMapping: { [key: string]: string } = {}
): ErrorMapping {
  const mapping: ErrorMapping = {
    error: getErrorMessageFromException(error),
    fields: {},
  };

  if (error.extensions && error.extensions.code === 'INVALID_MUTATION_INPUT') {
    Object.entries(error.extensions.exception).forEach(([key, value]) => {
      // Ignore stacktrace for now
      if (key !== 'stacktrace') {
        mapping.fields[key] = value as string;
      }
    });
  }

  if (formikActions) {
    formikActions.setStatus(mapping.error);

    Object.entries(mapping.fields).forEach(([key, value]) => {
      const mappedKey = formikMapping[key];

      if (process.env.NODE_ENV === 'development') {
        // tslint:disable-next-line no-console
        console.warn(
          `GraphQL Error Mapped, Field: ${mappedKey || key}, Error: ${value}`
        );
      }

      formikActions.setFieldError(mappedKey || key, value);
    });
  }

  return mapping;
}
