import React, { useState } from 'react';
import { Environment } from 'relay-runtime';
import { DropdownList } from 'react-widgets';
import Spinner from 'react-spinkit';
import styles from './DashboardConfirmDetailsModal.module.css';
import blukey from '../../../assets/images/blukey.svg';
import { ChangeDetailsRequest } from '../mutations/ChangeDetailsMutation';

interface Props {
  accountNumber: string;
  region?: string;
  contact?: string;
  handleClose: () => void;
  email: string;
  isHub?: boolean;
  environment: Environment;
}

export const DashboardConfirmDetailsModal = ({
  region,
  contact,
  handleClose,
  environment,
  accountNumber,
  email,
}: Props) => {
  const [editing, setEditing] = useState(false);
  const [cellNumber, setNumber] = useState(contact);
  const [newRegion, setNewRegion] = useState('-');
  const [loading, setLoading] = useState(false);

  const regionOptions = [
    'Cape Town',
    'Northern Cape',
    'Western Cape',
    'KwaZulu-Natal',
    'Free State',
    'Gauteng',
    'North West',
    'Mpumalanga',
    'Limpopo',
  ];

  return editing ? (
    <div className={styles.root}>
      <div className={styles.confirmBoxBody}>
        <img className={styles.logo} src={blukey} alt="" />
        <p className={styles.title}>Hello. Is your information correct?</p>
        <DropdownList
          defaultValue={region || 'Select Region'}
          data={regionOptions}
          onChange={value => setNewRegion(value)}
        />
        <input
          className={styles.editInput}
          value={cellNumber}
          placeholder={'Contact number'}
          onChange={e => setNumber(e.target.value)}
        />
        <button
          className={styles.submitButton}
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            await ChangeDetailsRequest(environment, {
              input: {
                email,
                accountNumber,
                currentRegion: region || '-',
                newRegion: newRegion || '-',
                currentCell: contact || '-',
                newCell: cellNumber || '-',
              },
            });
            localStorage.removeItem('login');
            handleClose();
          }}
        >
          {loading ? (
            <Spinner
              color={'white'}
              fadeIn="none"
              name="circle"
              className={styles.spinner}
            />
          ) : (
            'SUBMIT UPDATE REQUEST'
          )}
        </button>
      </div>
    </div>
  ) : (
    <div className={styles.root}>
      <div className={styles.confirmBoxBody}>
        <img className={styles.logo} src={blukey} alt="" />
        <p className={styles.title}>Hello. Is your information correct?</p>
        <p className={styles.infoText}>{region || 'No region specified'}</p>
        <p className={styles.infoTextBorder}>
          {contact || 'No number specified'}
        </p>
        <button
          className={styles.yesNoButton}
          onClick={() => {
            localStorage.removeItem('login');
            handleClose();
          }}
        >
          YES
        </button>
        <button
          className={styles.yesNoButton}
          onClick={() => {
            setEditing(true);
          }}
        >
          NO
        </button>
      </div>
    </div>
  );
};
