/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
type NotificationBar_viewer$ref = any;
export type NotificationBarQueryVariables = {
    readonly read: boolean;
    readonly cancelled: boolean;
    readonly expired: boolean;
    readonly delivered: boolean;
};
export type NotificationBarQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": NotificationBar_viewer$ref;
    };
};
export type NotificationBarQuery = {
    readonly response: NotificationBarQueryResponse;
    readonly variables: NotificationBarQueryVariables;
};



/*
query NotificationBarQuery {
  viewer {
    ...NotificationBar_viewer
  }
}

fragment NotificationBar_viewer on Viewer {
  broadcastNotifications(first: 3, read: false, cancelled: false, expired: false, delivered: true) {
    total
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        read {
          createdAt
        }
        broadcast {
          message
          title
          id
        }
        __typename
      }
      cursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "read",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "cancelled",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "expired",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "delivered",
    "type": "Boolean!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "cancelled",
    "value": false,
    "type": "Boolean"
  },
  {
    "kind": "Literal",
    "name": "delivered",
    "value": true,
    "type": "Boolean"
  },
  {
    "kind": "Literal",
    "name": "expired",
    "value": false,
    "type": "Boolean"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 3,
    "type": "Int"
  },
  {
    "kind": "Literal",
    "name": "read",
    "value": false,
    "type": "Boolean"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "NotificationBarQuery",
  "id": null,
  "text": "query NotificationBarQuery {\n  viewer {\n    ...NotificationBar_viewer\n  }\n}\n\nfragment NotificationBar_viewer on Viewer {\n  broadcastNotifications(first: 3, read: false, cancelled: false, expired: false, delivered: true) {\n    total\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      node {\n        id\n        read {\n          createdAt\n        }\n        broadcast {\n          message\n          title\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NotificationBarQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "viewer",
        "name": "__viewer_viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "NotificationBar_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NotificationBarQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "broadcastNotifications",
            "storageKey": "broadcastNotifications(cancelled:false,delivered:true,expired:false,first:3,read:false)",
            "args": v1,
            "concreteType": "BroadcastNotificationConnection",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "total",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "pageInfo",
                "storageKey": null,
                "args": null,
                "concreteType": "PageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "hasNextPage",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "endCursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "BroadcastNotificationEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BroadcastNotification",
                    "plural": false,
                    "selections": [
                      v2,
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "read",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BroadcastNotificationRead",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "createdAt",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "broadcast",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Broadcast",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "message",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "title",
                            "args": null,
                            "storageKey": null
                          },
                          v2
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "__typename",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "cursor",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedHandle",
            "alias": null,
            "name": "broadcastNotifications",
            "args": v1,
            "handle": "connection",
            "key": "NotificationBar_broadcastNotifications",
            "filters": [
              "read",
              "cancelled",
              "expired",
              "delivered"
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "viewer",
        "args": null,
        "handle": "viewer",
        "key": "",
        "filters": null
      }
    ]
  }
};
})();
(node as any).hash = '158f75636fd1ff116370bd66c86c11c5';
export default node;
