import graphql from 'babel-plugin-relay/macro';
import { createMutation } from '@stackworx/relay';

import { SelfRegistrationStartMutation } from './__generated__/SelfRegistrationStartMutation.graphql';

const mutation = graphql`
  mutation SelfRegistrationStartMutation($input: SelfRegistrationStartInput!) {
    selfRegistrationStart(input: $input) {
      clientMutationId
      cellphoneNumber
    }
  }
`;

export default createMutation<SelfRegistrationStartMutation>(mutation);
