/* tslint:disable no-any */
import * as React from 'react';
import { Component } from 'react';
import DataBlockStyles from './DashboardDataBlock.module.css';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import { Currency, Number } from '../../components/Formatters';
import { DashboardDataBlock_category } from './__generated__/DashboardDataBlock_category.graphql';

interface Props {
  title: string;
  jumpRef: React.RefObject<HTMLDivElement>;
  isCurrency?: boolean;
  allowOpenDataModal: boolean;
  category?: DashboardDataBlock_category;
  openDataModal: (open: string) => null;
}

class DashboardDataBlock extends Component<Props> {
  public render() {
    if (!this.props.category) {
      // Return null whlie reloading
      return null;
    }

    const {
      title,
      jumpRef,
      isCurrency = false,
      allowOpenDataModal,
      openDataModal,
      category: {
        category,
        yesterdaysSalesToDate,
        currentMonthToDate,
        lastMonthMonthToDate,
        lastMonth,
        allZero,
      },
    } = this.props;
    if (allZero) {
      return null;
    }

    const Display = isCurrency ? Currency : Number;

    return (
      <div
        className={DataBlockStyles['grey-block-container']}
        tabIndex={0}
        ref={jumpRef}
        role="button"
      >
        <div
          className={DataBlockStyles['grey-block']}
          onClick={
            allowOpenDataModal ? undefined : () => openDataModal(category)
            // this.setState({open: !this.state.open})
            // remove modal
          }
        >
          <p
            className={`${DataBlockStyles['block-title']} ${
              title.length > 20 ? DataBlockStyles.small : ''
            }`}
            data-tip={title.length > 20 ? title : ''}
          >
            {title}
          </p>
          <div className={DataBlockStyles['block-text-small']}>
            <div>
              <div className="bottomLine">
                <span>
                  <Display>{yesterdaysSalesToDate}</Display>
                </span>
                <span
                  className={DataBlockStyles['plain-text']}
                  data-tip="Yesterday's sales"
                >
                  YsTD
                </span>
              </div>
              <div>
                <span>
                  <Display>{currentMonthToDate}</Display>
                </span>
                <span
                  className={DataBlockStyles['plain-text']}
                  data-tip="This Month to-date's sales"
                >
                  MTD
                </span>
              </div>
              <div>
                <span>
                  <Display>{lastMonthMonthToDate}</Display>
                </span>
                <span
                  className={DataBlockStyles['plain-text']}
                  data-tip="Last Month to-date's sales"
                >
                  LstMTD
                </span>
              </div>
              <div>
                <span>
                  <Display>{lastMonth}</Display>
                </span>
                <span
                  className={DataBlockStyles['plain-text']}
                  data-tip="Last Month's Sales"
                >
                  LMth
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(DashboardDataBlock, {
  category: graphql`
    fragment DashboardDataBlock_category on DashboardCategorySales {
      category
      yesterdaysSalesToDate
      currentMonthToDate
      lastMonthMonthToDate
      lastMonth
      allZero
    }
  `,
});
