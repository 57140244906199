import * as React from 'react';
import { createFragmentContainer } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import styles from './mobileUl.module.css';

import { DashboardSearch_account } from './__generated__/DashboardSearch_account.graphql';

interface Props {
  account: DashboardSearch_account;
  onClose: () => void;
  scrollToComponent: (type: string) => void;
}

class DashboardSearch extends React.Component<Props> {
  public render() {
    const { scrollToComponent, onClose, account } = this.props;

    function showCategory(category: string) {
      const c = account.categories.find(cat => cat.category === category);

      if (!c) {
        return false;
      }

      return !c.allZero;
    }

    return (
      <div className={styles.mobileSearch}>
        <h1 className={styles.mobileSearchHeading}>Jump To Section</h1>
        <div className="mobile-search-close" role="button" tabIndex={0}>
          <img
            onClick={onClose}
            className={styles.closeIconSize}
            src={require('../../../assets/images/close.svg')}
          />
        </div>
        <ul className={styles.listStyle}>
          <div
            className={styles.listStyleFirstChild}
            onClick={() => {
              scrollToComponent('balance');
              onClose();
            }}
          >
            Balance
          </div>
          <div
            className={styles.listStyleChildren}
            onClick={() => {
              scrollToComponent('sales');
              onClose();
            }}
          >
            Sales
          </div>
          {showCategory('AIRTIME') && (
            <div
              className={styles.listStyleChildren}
              onClick={() => {
                scrollToComponent('airtime');
                onClose();
              }}
            >
              Airtime
            </div>
          )}
          {showCategory('DATA') && (
            <div
              className={styles.listStyleChildren}
              onClick={() => {
                scrollToComponent('data');
                onClose();
              }}
            >
              Data
            </div>
          )}
          {showCategory('ELECTRICITY') && (
            <div
              className={styles.listStyleChildren}
              onClick={() => {
                scrollToComponent('electricity');
                onClose();
              }}
            >
              Electricity
            </div>
          )}
          {showCategory('HOLLYWOOD_BETS') && (
            <div
              className={styles.listStyleChildren}
              onClick={() => {
                scrollToComponent('hollywood');
                onClose();
              }}
            >
              Hollywood Bets
            </div>
          )}
          {showCategory('UNIPIN') && (
            <div
              className={styles.listStyleChildren}
              onClick={() => {
                scrollToComponent('unipin');
                onClose();
              }}
            >
              UniPin
            </div>
          )}
          {showCategory('DSTV') && (
            <div
              className={styles.listStyleChildren}
              onClick={() => {
                scrollToComponent('dstv');
                onClose();
              }}
            >
              DSTV
            </div>
          )}
          {showCategory('LOTTO') && (
            <div
              className={styles.listStyleChildren}
              onClick={() => {
                scrollToComponent('lotto');
                onClose();
              }}
            >
              Lotto
            </div>
          )}
          {showCategory('M2M') && (
            <div
              className={styles.listStyleChildren}
              onClick={() => {
                scrollToComponent('m2m');
                onClose();
              }}
            >
              M2M
            </div>
          )}
          {showCategory('OTHER') && (
            <div
              className={styles.listStyleChildren}
              onClick={() => {
                scrollToComponent('other');
                onClose();
              }}
            >
              Other
            </div>
          )}
          {showCategory('RICA_TOTAL') && (
            <div
              className={styles.listStyleChildren}
              onClick={() => {
                scrollToComponent('ricatotal');
                onClose();
              }}
            >
              Total Rica
            </div>
          )}
          {showCategory('RICA_SUCCESS') && (
            <div
              className={styles.listStyleChildren}
              onClick={() => {
                scrollToComponent('ricasuccess');
                onClose();
              }}
            >
              Rica Successful
            </div>
          )}
          {showCategory('RICA_FAILED') && (
            <div
              className={styles.listStyleChildren}
              onClick={() => {
                scrollToComponent('ricafail');
                onClose();
              }}
            >
              Rica Failed
            </div>
          )}
        </ul>
      </div>
    );
  }
}

export default createFragmentContainer(DashboardSearch, {
  account: graphql`
    fragment DashboardSearch_account on DashboardAccount {
      categories {
        category
        allZero
      }
    }
  `,
});
