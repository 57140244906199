/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
type ViewProducts_viewer$ref = any;
export type ViewProductsQueryVariables = {};
export type ViewProductsQueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": ViewProducts_viewer$ref;
    };
};
export type ViewProductsQuery = {
    readonly response: ViewProductsQueryResponse;
    readonly variables: ViewProductsQueryVariables;
};



/*
query ViewProductsQuery {
  viewer {
    ...ViewProducts_viewer
  }
}

fragment ViewProducts_viewer on Viewer {
  user {
    roles {
      name
      id
    }
    id
  }
  products {
    id
    title
    description
    dateAvailable
    imageUri
    image
    externalLink
    buttonText
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ViewProductsQuery",
  "id": null,
  "text": "query ViewProductsQuery {\n  viewer {\n    ...ViewProducts_viewer\n  }\n}\n\nfragment ViewProducts_viewer on Viewer {\n  user {\n    roles {\n      name\n      id\n    }\n    id\n  }\n  products {\n    id\n    title\n    description\n    dateAvailable\n    imageUri\n    image\n    externalLink\n    buttonText\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ViewProductsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "viewer",
        "name": "__viewer_viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "ViewProducts_viewer",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ViewProductsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Viewer",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "user",
            "storageKey": null,
            "args": null,
            "concreteType": "User",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "roles",
                "storageKey": null,
                "args": null,
                "concreteType": "Role",
                "plural": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  v0
                ]
              },
              v0
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "products",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": true,
            "selections": [
              v0,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "description",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "dateAvailable",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "imageUri",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "image",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "externalLink",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "buttonText",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedHandle",
        "alias": null,
        "name": "viewer",
        "args": null,
        "handle": "viewer",
        "key": "",
        "filters": null
      }
    ]
  }
};
})();
(node as any).hash = '12cd8b33d1c2d19e2e3c635cce9f73a8';
export default node;
