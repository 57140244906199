import React from 'react';
import { Formik, Form, Field } from 'formik';
import Yup from 'yup';
import { Environment } from 'relay-runtime';
import { DropdownList } from 'react-widgets';
import { ConfigService } from '@stackworx/react';

import { PleaseCallMeRequest } from '../../mutations/PleaseCallMeMutation';
import TextField from '../../components/textField';
import Button from '../../components/button';
import styles from './PleaseCallMe.module.css';
import callMeIcon from '../../assets/images/call-me-icon.svg';
import callMeConfigure from '../../assets/images/callMeConfigure.svg';
import callMeLogs from '../../assets/images/callMeLogs.svg';
import callMeLogo from '../../assets/images/call-me-logo.svg';
import SideMenuItem from '../../components/sideMenuItem';
import SideBarStyle from '../../components/sideBarMenu.module.css';
import graphql from 'babel-plugin-relay/macro';
import { createFragmentContainer } from 'react-relay';
import RelayRenderer from '../RelayRenderer';
import { PleaseCallMe_viewer } from './__generated__/PleaseCallMe_viewer.graphql';
import { History } from 'history';

const Schema = (isAdmin: boolean) =>
  Yup.object().shape({
    issueType: Yup.string().required('Issue type is a required field'),
    other: Yup.string().when('issueType', {
      is: 'Other issue',
      then: Yup.string()
        .max(140, 'Description must have maximum of 140 characters')
        .required('Please add a description'),
    }),
    email: isAdmin
      ? Yup.string()
          .matches(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))|(admin)$/,
            'Your email does not appear to be valid'
          )
          .required(
            'Please enter the email address to which this test email must be sent'
          )
      : Yup.string(),
    name: Yup.string()
      .required('Name is a required field')
      .matches(
        /^[a-zA-Z]/,
        'Name and Surname cannot have a single space as an entry.'
      ),
    companyName: Yup.string()
      .required('Name of store is a required field')
      .matches(
        /^[a-zA-Z0-9]/,
        'Name of the store must have atleast one letter and must not have single space as an entry'
      ),
    accountNumber: Yup.string()
      .required('Account number is a required field')
      .matches(
        /^[a-zA-Z]{3}[0-9]{6}|^(admin)$/,
        'Account number should contain 3 letters and 6 numbers'
      ),
    contactNumber: Yup.string()
      .required('Contact number is a required field')
      .matches(
        /^0(6|7|8){1}[0-9]{1}[0-9]{7}$/,
        'Please enter a valid mobile number that has 10 numbers eg: 0821234567'
      ),
  });

interface Props {
  isMobile?: boolean;
  environment: Environment;
  accountNumber: string;
  /* tslint:disable-next-line */
  companyName: any;
  history: History;
  viewer: PleaseCallMe_viewer;
  isAdmin: boolean;
}

const PleaseCallMe = ({
  isMobile,
  environment,
  accountNumber,
  companyName,
  history,
  viewer,
  isAdmin,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [isValid, setValid] = React.useState(false);
  const [isFileToBig, setIsFileToBig] = React.useState(false);
  const [isFileUploaded, setIsFileUploaded] = React.useState(true);

  const validBodyClass = () => {
    let standardClass = styles.modalBody;
    if (!isValid) {
      if (isMobile) {
        standardClass = styles.modalBodyMobile;
      }
    } else {
      if (isMobile) {
        standardClass = styles.validBodyMobile;
      } else {
        standardClass = styles.validBody;
      }
    }

    return standardClass;
  };

  const email =
    ConfigService.stage === 'development'
      ? 'blue-label-notifications@stackworx.io'
      : 'service@blusupport.co.za';

  function getMenuItemClass() {
    const width = window.innerWidth;
    return width >= 1024
      ? SideBarStyle['menu-item-closed'] + ` ${SideBarStyle.notifications}`
      : SideBarStyle['menu-item'] + ` ${SideBarStyle.notifications}`;
  }

  function getMenuImageClass() {
    const width = window.innerWidth;
    return width >= 1024
      ? SideBarStyle['menu-item-closed-img']
      : SideBarStyle['menu-open-icon'];
  }

  const configsItems = viewer.pleaseCallMeConfig
    ? viewer.pleaseCallMeConfig.map(config => config.name)
    : [];

  return (
    <div className={styles.root}>
      {isMobile ? (
        <div
          className={getMenuItemClass()}
          onClick={() => setOpen(true)}
          role="button"
          tabIndex={0}
          style={{
            backgroundColor: open ? '#021640' : '',
          }}
        >
          <img
            alt="dashboard"
            src={callMeIcon}
            className={getMenuImageClass()}
          />
          <p className={SideBarStyle['menu-header']}>Call me</p>
        </div>
      ) : (
        <div
          className={SideBarStyle['menu-item-closed']}
          onClick={() => setOpen(true)}
          role="button"
          tabIndex={0}
        >
          <img
            className={SideBarStyle['menu-item-closed-img']}
            alt="call me"
            src={callMeIcon}
          />
          <p> Call me</p>
          <div
            className={SideBarStyle.submenu}
            onClick={() => setOpen(true)}
            role="button"
            tabIndex={0}
          >
            {isAdmin && !isMobile && (
              <>
                <SideMenuItem
                  icon={callMeIcon}
                  onClick={() => setOpen(true)}
                  path={''}
                  history={history}
                  title={'Call me'}
                />
                <SideMenuItem
                  icon={callMeConfigure}
                  path={'/please-call-configure'}
                  history={history}
                  title={'Call me configure'}
                />
                <SideMenuItem
                  icon={callMeLogs}
                  path={'/please-call-logs'}
                  history={history}
                  title={'Call me logs'}
                />
              </>
            )}
          </div>
        </div>
      )}

      {open && (
        <>
          <div
            className={
              isMobile ? styles.modalContainerMobile : styles.modalContainer
            }
            role="button"
            tabIndex={0}
            onClick={() => setOpen(false)}
          >
            <div
              className={validBodyClass()}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {isValid ? (
                <div
                  className={
                    isMobile ? styles.helperTextMobile : styles.helperText
                  }
                >
                  <img
                    src={callMeLogo}
                    className={styles.topLogo}
                    alt="call me"
                  />
                  <h1>Please call me back</h1>
                  <p className={styles.boldText}>
                    Thank you for contacting us!{' '}
                    <>
                      {isFileUploaded ? (
                        <>The Call Center should call you within an hour</>
                      ) : (
                        <>
                          We notice that you haven’t attached anything. Please
                          send a proof of payment via Whatsapp: 066 170 2692, or
                          email: service@blusupport.co.za, and one of our
                          dedicated agents will give you a call shortly
                        </>
                      )}
                    </>
                  </p>
                  <Button
                    onClick={() => {
                      setOpen(false);
                      setValid(false);
                    }}
                    type="Primary"
                    text="OKAY"
                    className={styles.okay}
                  />
                </div>
              ) : (
                <>
                  <div
                    className={
                      isMobile ? styles.helperTextMobile : styles.helperText
                    }
                  >
                    <img
                      src={callMeLogo}
                      className={styles.topLogo}
                      alt="call me"
                    />
                    <h1>Please call me back</h1>
                    <p>
                      Helping you get more, do more! We value your loyalty and
                      would like to support you with any problem you may be
                      experiencing.
                    </p>
                    <p className={styles.boldText}>
                      Help us identify your problem, we will call you back and
                      find the best solution to solve it
                    </p>
                  </div>
                  <Formik
                    initialValues={{
                      issueType: '',
                      name: '',
                      email: '',
                      /* tslint:disable-next-line */
                      companyName: companyName,
                      /* tslint:disable-next-line */
                      accountNumber: accountNumber,
                      contactNumber: '',
                      other: '',
                      depositIssueFile: '',
                    }}
                    validationSchema={Schema(isAdmin)}
                    onSubmit={async values => {
                      setValid(true);
                      const {
                        issueType,
                        contactNumber,
                        depositIssueFile,
                      } = values;
                      const subjectLine =
                        issueType !== 'Other issue' ? issueType : values.other;

                      if (
                        issueType === 'Issue with deposit' &&
                        !depositIssueFile
                      ) {
                        setIsFileUploaded(false);
                      }

                      await PleaseCallMeRequest(environment, {
                        input: {
                          email: values.email ? values.email : email,
                          accountNumber: values.accountNumber,
                          name: values.name,
                          companyName: values.companyName,
                          subjectLine,
                          contactNumber,
                          depositIssueFile,
                        },
                      });
                    }}
                    render={({
                      handleSubmit,
                      setFieldValue,
                      handleChange,
                      errors,
                      values,
                    }) => {
                      return (
                        <Form
                          className={styles.form}
                          onKeyPress={e =>
                            e.key === 'Enter' && e.preventDefault()
                          }
                        >
                          <DropdownList
                            name="issueType"
                            containerClassName={styles.dropdown}
                            data={configsItems}
                            onChange={value =>
                              setFieldValue('issueType', value)
                            }
                            placeholder={'Issue type'}
                          />
                          {errors.issueType && (
                            <div className={styles.dropdownlistError}>
                              {errors.issueType}
                            </div>
                          )}
                          {values.issueType === 'Other issue' && (
                            <Field
                              name="other"
                              component={TextField}
                              placeholder="Add a description"
                            />
                          )}
                          {values.issueType === 'Issue with deposit' && (
                            <>
                              <p className={styles.label}>Image</p>
                              <input
                                type="file"
                                name="depositIssueFile"
                                accept="image/jpeg,image/jpg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                className={styles.pleaseCallInput}
                                onClick={e => {
                                  setIsFileToBig(false);
                                  e.stopPropagation();
                                }}
                                onChange={event => {
                                  if (event.currentTarget.files) {
                                    const bytes =
                                      event.currentTarget.files[0].size;
                                    const mb = parseFloat(
                                      (bytes / (1024 * 1024)).toFixed(2)
                                    );
                                    if (mb <= 5) {
                                      setIsFileUploaded(true);
                                      setFieldValue(
                                        'depositIssueFile',
                                        event.currentTarget.files[0]
                                      );
                                    } else {
                                      setIsFileToBig(true);
                                    }
                                  }
                                }}
                              />
                            </>
                          )}
                          {isFileToBig && (
                            <p className={styles.dropdownlistError}>
                              Maximum file size must be 5mb
                            </p>
                          )}
                          {isAdmin && (
                            <Field
                              name="email"
                              label="Test email address"
                              component={TextField}
                            />
                          )}
                          <Field
                            name="name"
                            label="Name and Surname"
                            component={TextField}
                          />
                          <Field
                            name="accountNumber"
                            label="Account Number"
                            component={TextField}
                          />
                          <Field
                            name="companyName"
                            label="Name of Store"
                            component={TextField}
                          />
                          <Field
                            name="contactNumber"
                            label="Mobile Number"
                            component={TextField}
                          />
                          <div className={styles.buttonContainer}>
                            <Button
                              type="Secondary"
                              onClick={() => setOpen(false)}
                              text="CANCEL"
                              className={styles.cancel}
                            />
                            <Button
                              type="Primary"
                              onClick={handleSubmit}
                              text="SUBMIT"
                              className={styles.submit}
                              disabled={isFileToBig}
                            />
                          </div>
                        </Form>
                      );
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const pleaseCallMeFragment = createFragmentContainer(PleaseCallMe, {
  viewer: graphql`
    fragment PleaseCallMe_viewer on Viewer {
      pleaseCallMeConfig {
        name
      }
    }
  `,
});

const query = graphql`
  query PleaseCallMeQuery {
    viewer {
      ...PleaseCallMe_viewer
    }
  }
`;

export default props => (
  <RelayRenderer {...props} container={pleaseCallMeFragment} query={query} />
);
