import * as React from 'react';
import OtpField from '../../../components/otpField';
import { Formik, Form, Field } from 'formik';
import Yup from 'yup';
import { Environment } from 'relay-runtime';
import SelfRegistrationSubmitPinMutation from '../mutations/SelfRegistrationSubmitPinMutation';
import SelfRegistrationStartMutation from '../mutations/SelfRegistrationStartMutation';
import Spinner from 'react-spinkit';
import moment, { Moment } from 'moment';

import styles from './SelfRegistration.module.css';
import bluLogo from '../../../assets/images/blukey.svg';
import Button from '../../components/Button';
import Timer from '../../components/Timer';

// 5 minutes from now
const otpTimeout = { seconds: 5 * 60 };

interface Props {
  environment: Environment;
  nextStep: () => void;
  prevStep: () => void;
  handleSubmit: (valueOne: string, valueTwo: string) => void;

  values: {
    entityNumber: string;
    cellNumber: string;
    firstName: string;
    lastName: string;
    email: string;
    OTP: string;
    token: string;
  };
}
interface State {
  isLoading: boolean;
  resend: boolean;
  expiry: Moment;
  expired: boolean;
}

interface Values {
  OTP: string;
  token: string;
  entityNumber: string;
  cellNumber: string;
}

const Schema = Yup.object().shape({
  OTP: Yup.string()
    .min(5, 'OTP should be 5 characters long')
    .max(5)
    .matches(/^\d+$/, {
      message: 'OTP should not contain alphabetic characters',
      excludeEmptyString: false,
    })
    .required('OTP is a required field'),
});

class OTP extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      expired: false,
      expiry: moment().add(otpTimeout),
      resend: false,
    };
  }
  public back = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.prevStep();
  };

  public resendOtp = async (
    setStatus: (arg1: string) => void,
    setFieldError: (arg1: string, arg2: string) => void
  ) => {
    try {
      this.setState({
        isLoading: true,
        resend: false,
      });
      await SelfRegistrationStartMutation(this.props.environment, {
        input: {
          entityReference: this.props.values.entityNumber,
          cellphoneNumber: this.props.values.cellNumber,
        },
      });

      this.setState({
        isLoading: false,
        resend: true,
        expired: false,
        expiry: moment().add(otpTimeout),
      });
    } catch (e) {
      setFieldError('OTP', 'Invalid OTP');
      setStatus(e.message || 'OTP failed');
    }
  };

  public render() {
    const { environment } = this.props;
    const { expired } = this.state;

    return (
      <div className={styles.loginBody}>
        <div className={styles.registerContainer}>
          <div className={styles.image}>
            <img src={bluLogo} alt="" className={styles.logo} />
          </div>
          <h3 className={styles.title}>Self Registration - OTP</h3>

          <p className={styles.text}>
            Please enter the ONE TIME PIN that was sent to{' '}
            <strong>{this.props.values.cellNumber}</strong> to verify your
            account below.
            <br />
            <span>
              Please note that the OTP will only be valid for 5 minutes.
            </span>
            <br />
            <br />
            If you do not have a cellphone number registered against your
            account or your number has changed please update your details via
            the call centre on 0800 014 942.
          </p>
          <Formik<Values>
            initialValues={{
              OTP: '',
              token: '',
              entityNumber: this.props.values.entityNumber,
              cellNumber: this.props.values.cellNumber,
            }}
            validationSchema={Schema}
            onSubmit={async (
              values,
              { setStatus, setSubmitting, setFieldError }
            ) => {
              try {
                setStatus('');

                const OTPresponse = await SelfRegistrationSubmitPinMutation(
                  environment,
                  {
                    input: {
                      entityReference: this.props.values.entityNumber,
                      cellphoneNumber: this.props.values.cellNumber,
                      pin: values.OTP,
                    },
                  }
                );

                // @ts-ignore
                values.token = OTPresponse.selfRegistrationSubmitPin.token;
                this.props.nextStep();
                this.props.handleSubmit(values.OTP, values.token);
              } catch (e) {
                setSubmitting(false);
                setFieldError('OTP', 'Invalid OTP');
                setStatus(e.message || 'OTP failed');
              }
            }}
            render={({
              handleSubmit,
              isSubmitting,
              setStatus,
              setFieldError,
            }) => {
              return (
                <Form
                  autoComplete="nope"
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      handleSubmit(e);
                    }
                  }}
                >
                  <div className="input-container">
                    <Field
                      name="OTP"
                      label="One-Time Pin *"
                      placeholder="12345"
                      component={OtpField}
                      disabled={isSubmitting}
                    />
                    <Timer
                      expiry={this.state.expiry}
                      handleExpired={() => this.setState({ expired: true })}
                    />
                  </div>
                  <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting || expired}
                    text="SUBMIT OTP"
                  />
                  <div
                    className={styles.title}
                    onClick={() => this.resendOtp(setStatus, setFieldError)}
                  >
                    {this.state.isLoading ? (
                      <Spinner
                        name="circle"
                        color="blue"
                        fadeIn="none"
                        className={styles.spinner}
                      />
                    ) : (
                      'Resend One-Time Pin'
                    )}
                  </div>
                </Form>
              );
            }}
          />

          <div className={styles.otpResend}>
            {this.state.resend ? 'OTP has been resent*' : ''}
          </div>
          <div className={styles.goBack}>
            <a href="#" onClick={this.back}>
              {'< Back'}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default OTP;
