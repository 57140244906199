import * as React from 'react';
import Button from '../../components/Button';
import styles from './SelfRegistration.module.css';

import bluLogo from '../../../assets/images/blukey.svg';

interface Props {
  username: string;

  handleSubmit: () => void;
}

class UserNameDetails extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  public render() {
    return (
      <div className={styles.loginBody}>
        <div className={styles.registerContainer}>
          <div className={styles.image}>
            <img src={bluLogo} alt="" className={styles.logo} />
          </div>
          <h3 className={styles.title}>Self Registration - Success!</h3>
          <p className={styles.text}>
            Your new account has been successfully registered and you are now
            able to access the BluKey Portal.
          </p>
          <h1 className={styles.heading}>Username : {this.props.username}</h1>

          <Button onClick={this.props.handleSubmit} text="AUTO LOGIN" />
        </div>
      </div>
    );
  }
}

export default UserNameDetails;
