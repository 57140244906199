/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
type BroadcastNotificationDropdown_notification$ref = any;
export type SubscriptionsSubscriptionVariables = {};
export type SubscriptionsSubscriptionResponse = {
    readonly broadcastNotification: {
        readonly id: string;
        readonly " $fragmentRefs": BroadcastNotificationDropdown_notification$ref;
    };
};
export type SubscriptionsSubscription = {
    readonly response: SubscriptionsSubscriptionResponse;
    readonly variables: SubscriptionsSubscriptionVariables;
};



/*
subscription SubscriptionsSubscription {
  broadcastNotification {
    id
    ...BroadcastNotificationDropdown_notification
  }
}

fragment BroadcastNotificationDropdown_notification on BroadcastNotification {
  broadcast {
    title
    message
    deliveryDateTime
    id
  }
  id
  read {
    createdAt
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "subscription",
  "name": "SubscriptionsSubscription",
  "id": null,
  "text": "subscription SubscriptionsSubscription {\n  broadcastNotification {\n    id\n    ...BroadcastNotificationDropdown_notification\n  }\n}\n\nfragment BroadcastNotificationDropdown_notification on BroadcastNotification {\n  broadcast {\n    title\n    message\n    deliveryDateTime\n    id\n  }\n  id\n  read {\n    createdAt\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SubscriptionsSubscription",
    "type": "Subscription",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "broadcastNotification",
        "storageKey": null,
        "args": null,
        "concreteType": "BroadcastNotification",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "FragmentSpread",
            "name": "BroadcastNotificationDropdown_notification",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SubscriptionsSubscription",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "broadcastNotification",
        "storageKey": null,
        "args": null,
        "concreteType": "BroadcastNotification",
        "plural": false,
        "selections": [
          v0,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "broadcast",
            "storageKey": null,
            "args": null,
            "concreteType": "Broadcast",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "title",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "message",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "deliveryDateTime",
                "args": null,
                "storageKey": null
              },
              v0
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "read",
            "storageKey": null,
            "args": null,
            "concreteType": "BroadcastNotificationRead",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
(node as any).hash = '1e618b1097375a5bb390be19bad946fa';
export default node;
