import React from 'react';
import styles from './TourItem.module.css';
interface Props {
  heading?: string;
  caption?: string;
  image: string;
  explanations: string[];
  index?: number;
}

class TourItem extends React.Component<Props> {
  public IncreaseFontWeight(
    explanationText: string,
    textToReplace: string,
    targetId: string
  ) {
    const startingPoint = explanationText.indexOf(textToReplace);
    const explanationElement = document.getElementById(targetId) as HTMLElement;
    if (startingPoint < 0 && explanationElement) {
      explanationElement.innerHTML = explanationText;
    } else {
      const result = explanationText.substr(
        startingPoint,
        textToReplace.length
      );
      const textToReplaceWith = result.bold();
      const transformedExplanationText = explanationText.replace(
        textToReplace,
        textToReplaceWith
      );
      if (explanationElement) {
        explanationElement.innerHTML = transformedExplanationText;
      }
    }
  }
  public render() {
    const { heading, caption, image, explanations } = this.props;
    return (
      <div className={styles.tourItemContainer}>
        {heading && (
          <div className={styles.heading}>
            <h2>{heading}</h2>
          </div>
        )}
        {caption && (
          <div className={styles.caption}>
            <h4>{caption}</h4>
          </div>
        )}
        <div className={styles.content}>
          <div className={styles.imageContainer}>
            <img src={require(`../../../../../assets/${image}`)} />
          </div>
          <div className={styles.explanations}>
            {explanations.map((explanation, idx) => (
              <React.Fragment key={`${image}-${idx}`}>
                <span id={`${image}-${idx}`}>{explanation}</span>
                <br />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export { TourItem };
