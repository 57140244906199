/* tslint:disable */

import { ConcreteFragment } from "relay-runtime";
export type SalesCategory = "AIRTIME" | "DATA" | "DSTV" | "ELECTRICITY" | "HOLLYWOOD_BETS" | "LOTTO" | "M2M" | "OTHER" | "RICA_FAILED" | "RICA_SUCCESS" | "RICA_TOTAL" | "TOTAL_SALES" | "UNIPIN" | "%future added value";
export type DashboardDataBlock_category$ref = any;
export type DashboardDataBlock_category = {
    readonly category: SalesCategory;
    readonly yesterdaysSalesToDate: number;
    readonly currentMonthToDate: number;
    readonly lastMonthMonthToDate: number;
    readonly lastMonth: number;
    readonly allZero: boolean;
    readonly " $refType": DashboardDataBlock_category$ref;
};



const node: ConcreteFragment = {
  "kind": "Fragment",
  "name": "DashboardDataBlock_category",
  "type": "DashboardCategorySales",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "category",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "yesterdaysSalesToDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "currentMonthToDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastMonthMonthToDate",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastMonth",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "allZero",
      "args": null,
      "storageKey": null
    }
  ]
};
(node as any).hash = '73d3a7aa5eabe56ccfa781c18885efe3';
export default node;
