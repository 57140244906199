import React from 'react';
import { Environment } from 'relay-runtime';
import { RelayProp } from 'react-relay';
import { History } from 'history';
import CreateProduct from '../../CreateProduct/CreateProduct';
import styles from './ProductEditModal.module.css';

interface Props {
  environment: Environment;
  relay: RelayProp;
  history: History;
  productId: string;
  values?: {
    title: string;
    description: string;
    dateAvailable: string;
    externalLink?: string;
    externalLinkText?: string;
  };
  handleClose: () => void;
}

export const ProductEditModal = ({
  environment,
  relay,
  history,
  values,
  productId,
  handleClose,
}: Props) => {
  let initialValues;

  if (values) {
    initialValues = values;
  }

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <p className={styles.title}>
          <b>Products</b> - Edit Product
        </p>
        <div className={styles.oldForm}>
          <CreateProduct
            environment={environment}
            relay={relay}
            history={history}
            productId={productId}
            alternateForm={true}
            handleClose={handleClose}
            values={
              initialValues
                ? {
                    title: initialValues.title,
                    description: initialValues.description,
                    dateAvailable: initialValues.dateAvailable,
                    externalLink: initialValues.externalLink,
                    externalLinkText: initialValues.externalLinkText,
                  }
                : undefined
            }
          />
        </div>
        {/*<button className={styles.button} onClick={handleClose}>*/}
        {/*Cancel*/}
        {/*</button>*/}
      </div>
    </div>
  );
};
