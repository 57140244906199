/* tslint:disable no-any */
const initialState = {
  authenticated: false,
  token: null,
  username: null,
  displayName: null,
  entityReferences: [],
  dailyData: {
    lastUpdate: new Date(),
  },
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case 'LOGIN':
      return {
        ...state,
        authenticated: true,
        ...payload,
      };

    case 'LOGOUT':
      return {
        ...state,
        authenticated: false,
        username: null,
        token: null,
      };
    default:
      return state;
  }
};
