import React from 'react';
import Modal from 'react-responsive-modal';
import styles from './EntityComboboxModal.module.css';
import classnames from 'classnames';
import { EntityData } from './EntityCombobox';
interface Props {
  open: boolean;
  loading?: boolean;
  closeModal: () => void;
  includeRollup?: boolean;
  entityData: EntityData[];
  handleChange: (value: string) => void;
  isAccountSelector?: boolean;
  handleSearch?: (searchTerm: string) => void;
}

interface State {
  searchValue: string;
}

export default class EntityComboboxModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchValue: '',
    };
  }

  public handleAccountSelect(
    selectedAccount: string,
    handler: (accountName: string) => void
  ): void {
    if (selectedAccount && handler) {
      this.props.closeModal();
      handler(selectedAccount);
      this.setState({
        searchValue: selectedAccount,
      });
    }
  }

  public render() {
    const { entityData, handleSearch } = this.props;
    const { searchValue } = this.state;

    let filteredEntityData: EntityData[];
    if (!searchValue.length) {
      filteredEntityData = entityData;
    } else {
      filteredEntityData = entityData.filter(this.filterEntities(searchValue));
    }

    return (
      <div className={styles.modalContainer}>
        <Modal
          classNames={{
            overlay: styles.customOverlay,
            modal: styles.customModal,
            closeIcon: styles.closeButton,
          }}
          open={this.props.open}
          onClose={() => this.props.closeModal()}
          center
        >
          <h2 className={styles.heading}>Select Account</h2>
          <div className={styles.inputContainer}>
            <input
              className={styles.input}
              type="text"
              placeholder="Search account number/name"
              onChange={event => {
                if (handleSearch) {
                  handleSearch(event.target.value);
                }
                this.setState({ searchValue: event.target.value });
              }}
              value={searchValue}
            />
            <span className={styles.searchClear}>
              <img
                onClick={() => this.setState({ searchValue: '' })}
                className={styles.searchClearIcon}
                src={require('../../assets/images/close.svg')}
              />
            </span>
          </div>
          {filteredEntityData.length > 0 ? (
            <div className={styles.entityDataContainer}>
              {this.renderEntityData(filteredEntityData)}
            </div>
          ) : (
            <div className={styles.noAccountMatch}>
              <p>
                <strong>No matches found,</strong> make sure you have entered
                the correct account number or name and that you do have access
                to the account in question.
              </p>
              <span
                onClick={() => this.setState({ searchValue: '' })}
                className={styles.clearSearchText}
              >
                <strong>Clear search</strong>
              </span>
            </div>
          )}
        </Modal>
      </div>
    );
  }

  public renderEntityData(entityData: EntityData[]) {
    const { isAccountSelector, includeRollup } = this.props;
    const hubs = isAccountSelector
      ? entityData
      : entityData.filter(({ type }: EntityData) => type === 'Hub');
    const spokes = entityData.filter(
      ({ type }: EntityData) => type === 'Spoke'
    );
    return (
      <div>
        <div className={styles.entityData}>
          <p className={styles.title}>{hubs.length ? 'Hub' : null}</p>
          <div className={styles.entityRow}>{this.entityMapper(hubs)}</div>
          {spokes.length > 0 && (
            <div>
              <p className={styles.title}>Spokes</p>
              <div className={styles.entityRow}>
                {this.entityMapper(spokes)}
              </div>
            </div>
          )}
        </div>
        {!isAccountSelector && includeRollup && (
          <React.Fragment>
            <p className={styles.title}>Totals (Roll-ups)</p>
            <div className={styles.entityRow}>
              {this.entityMapper('Hub + Spoke')}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  public renderAccountData({ name, accountName, account }: EntityData) {
    const { isAccountSelector } = this.props;

    const entityReference = isAccountSelector ? name : account;
    const entityAccountName = isAccountSelector ? accountName : name;

    return (
      <div>
        <span>
          <strong className={styles.account}>{entityReference}</strong>
        </span>
        {entityAccountName && (
          <span className={styles.name}>{`- ${entityAccountName}`}</span>
        )}
      </div>
    );
  }

  public entityMapper(entities: EntityData[] | string) {
    const { handleChange } = this.props;
    if (typeof entities !== 'string') {
      return entities.map((entity: EntityData) => {
        return (
          <div
            className={classnames(
              styles.entity
              // I will come back to this. Removing it for now
              // this.state.selectedEntity.name === entity.name
              //   ? styles.isActive
              //   : ''
            )}
            key={entity.name || entity.account}
            onClick={() => {
              this.props.closeModal();
              return handleChange(entity.account || entity.text);
            }}
          >
            {this.renderAccountData(entity)}
          </div>
        );
      });
    } else {
      return (
        <div
          className={styles.entity}
          onClick={() => {
            this.props.closeModal();
            handleChange('rollup');
          }}
        >
          <span>
            <strong className={styles.account}>Total</strong>
          </span>
          - <span className={styles.name}>{entities}</span>
        </div>
      );
    }
  }

  private filterEntities = (searchValue: string) => (entity: EntityData) => {
    const search = searchValue.toLowerCase();
    return (
      (entity.account && entity.account.toLowerCase().includes(search)) ||
      (entity.name && entity.name.toLowerCase().includes(search)) ||
      (entity.type && entity.type.toLowerCase().includes(search)) ||
      (entity.text && entity.text.toLowerCase().includes(search))
    );
  };
}
